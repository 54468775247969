import React, { useContext, useState, useEffect } from "react";

import "../components/logind.css";
import { keyContext } from "../components/KeyContext";

import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";

import logo from '../images/ebillet_logo 1.png'
import logo2 from '../images/Vector.png'

function Login(props) {
  const { setUserName, setPassword, errorMessage } = useContext(keyContext);
  //  console.log(errorMessage)
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [kode, setKode] = useState("");
  
let theme = localStorage.getItem('theme')
  
// console.log(theme, 'theme is')
  function submitData(e) {
    e.preventDefault();

    // console.log(kode);
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_login?User=${user}&PW=${encodeURIComponent(kode)}`;
    fetch(endpoint, {
      method: "GET",

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.result === 0) {
          setErrors(result.Message);
        }
        else {
          setUserName(e.target.text.value);
          setPassword(e.target.password.value);

          navigate("/admin");
        sessionStorage.setItem('orgID',( result && result.data[0].No))
        sessionStorage.setItem("key", result.key);
        // sessionStorage.setItem('orgID',( result && result.data[0].No))
                
        // sessionStorage.setItem("key", result.key);
        sessionStorage.setItem("user", user)
        sessionStorage.setItem("password", kode)

        }
                
                // sessionStorage.setItem("user", userName)
      });

   
  }

  useEffect(() => {
    document.title = "Log ind - ebillet_admin";
  }, []);

  return (
    <main className="containers">
      {/* <Header /> */}
      <header className="header-menu">
      <figure>
        <img src={ theme === 'dark' ? logo2 :logo} alt="ebillet-logo" style={{padding: theme === 'dark' ? '22px' : ''}}/>
        </figure>
      </header>
    <section className="login-page">
      <aside className="aside" style={{backgroundColor: theme === 'dark' ? '#1a1b1e' : ' '}}></aside>

    
    <div className="login">
      <p className="login-title" style={{color:theme === 'dark' ? '#fff' : '#000'}}>Log ind</p>
      
      <p className="login-desc" style={{color:theme === 'dark' ? '#fff' : '#101010'}}>Indtast brugernavn og adgangskode</p>
      
      <Form onSubmit={(e) => submitData(e)} style={{marginTop:'30px'}}>
        <Form.Group className="mb-3" controlId="user">
          <Form.Label style={{fontWeight:'bold', letterSpacing:'0.32px',lineHeight:1.1,color:theme === 'dark' ? '#fff':'#101010'}}>Brugernavn</Form.Label>
          <Form.Control
            type="text"
            name="text" style={{color:theme === 'dark' ? '#fff':'#101010',border:'1px solid #8d8d8d',backgroundColor: theme === 'dark' ? '#65696c' : ''}}
            //value={form.user}
            onChange={(e) => {
              setUser(e.target.value);
            }}
            // isInvalid={!!errors.user }
          />
        </Form.Group>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: "block", fontSize: "1em" }}
        >
          {/* {errors.user}  */}
        </Form.Control.Feedback>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label  style={{fontWeight:'bold', letterSpacing:'0.32px',marginTop:5,lineHeight:1.1,color:theme === 'dark' ? '#fff':'#101010'}}>Adgangskode</Form.Label>
          <Form.Control
            type="password"
            name="password" style={{color:theme === 'dark' ? '#fff':'',border:'1px solid #8d8d8d',backgroundColor: theme === 'dark' ? '#65696c' : ''}}
            onChange={(e) => {
              setKode(e.target.value);
            }}
            // isInvalid={!!errors.password }
          />

          <Form.Control.Feedback
            type="invalid"
            style={{ display: "block", fontSize: "1em" }}
          >
            {/* {errors.password}  */}
          </Form.Control.Feedback>
        </Form.Group>

        <p className="error" style={{ }}>
          {errors ? " Forkert brugernavn eller adgangskode." : ""}
        </p>
      
        <button className=" text-light button" type="submit" style={{marginTop:errors? '0px' : '20px',backgroundColor: theme === 'dark' ? '#4e5052' : ''}}>
          LOG IND
        </button>
      </Form>
    </div>
    </section>
    </main>
  );
}

export default Login;
