import React, { useEffect, useContext, useState } from "react"
import "./header.css";

import logo from "../../images/ebillet_logo 1.png";
import logo2 from '../../images/Vector.png';
import smallLogo from '../../images/small_logo.png'
import salllogocolor from '../../images/small_logo_color.png'
import home from "../../images/-home.png";
import docs from "../../images/icon-rapporter.png";
import sms from "../../images/-sms.png";
import settings from "../../images/-settings.png";
import whiteArrow from "../../images/smallWhite.svg";
import downArrow from "../../images/small_arrow.svg";
import program from '../../images/Icon_programlæg.png'
import { Link } from "react-router-dom";
import { keyContext } from "../../components/KeyContext";
import { useNavigate } from "react-router-dom";

import BilletsalgPerFors from "../../views/TicketSell";
import UgesRapport from "../../views/WeekReport";
import Dagens from "../../views/Dagens";
import Dagperiode from "../../views/DayPeriode";
// import AdminIncidents from "../../views/AdminIncidents";
// import Test from "../../views/PeriodeSam";
import PeriodeSam from "../../views/PeriodeSam";
import WorkPlan from "../../views/WorkPlan";
// import Navbar from "../Navbar";

export default function Header({
  isActive,
  smsActive,
  setsActive,
  homeActive,
  programActive,
  pageView,
}) {
  const navigate = useNavigate();

  const { userName, setData, setDagsData, setDagensData, setOpen, open, isChecked } =
    useContext(keyContext);
    
  localStorage.setItem('burgerMenuChecked', isChecked)

  const user = sessionStorage.getItem("user");
  const [arrowActive, setArrowActive] = useState(false);
  const [show, setShow] = useState(false);
  const [rights, setRights] = useState([{}]);
  const [isShown, setIsShown] = useState(false);
  const reportChecked = localStorage.getItem('reportChecked')

  const [ugesRapportIsActive, setUgesRapportIsActive] = useState(false);
  const [showUgesRapport, setShowUgesRapport] = useState(false);
  const [dagsperiodeIsActive, setDagsperiodeIsActive] = useState(false);
  const [showDagsperiode, setShowDagsperiode] = useState(false);

  let key = sessionStorage.getItem("key");
  let orgID = sessionStorage.getItem("orgID");
  let theme = localStorage.getItem('theme')

  const burgerMenuDisplay = localStorage.getItem('burgerMenuChecked');
  // console.log(burgerMenuDisplay)

  function openReports() {
    setIsShown( current => !current)
  }

  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getaccessrights?Key=${key}&LoginNo=${orgID}`;
    if (key && orgID) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          if (result.result === 0) {
            navigate("/");
          }
          setRights(result);
        });
    }
  }, [key, orgID]);

  function logOut() {
    sessionStorage.removeItem("key");
   // sessionStorage.removeItem("user");
    sessionStorage.removeItem("period");
    sessionStorage.removeItem("film");
    sessionStorage.removeItem("default-filmID");
    sessionStorage.removeItem("orgID");
    setData("");
    setDagsData("");
    setDagensData("");
    navigate("/");
    window.location.reload();
  }

  function handleArrowClick() {
    setArrowActive((current) => !current);
    setShow((current) => !current);
  }

  function handleClick() {
    setOpen(!open);
  }

  
  const [pageKey, setPageKey] = React.useState("m");


const Menu = (props) => {
    return (
      <div id="meu" className="has-scrollbar">
        <button
          style={{
            border: "none",
            background: "transparent",
            color: pageKey === "z" ? "#982f2f" : "black",
            marginBottom: 6 + "px",
            marginLeft:'8px'
          }}
          onClick={() => {
            setOpen(!open);
          }}
        > 
        <Link
                            to="/ugesrapport"
                            style={{ textDecoration: "none"
                            ,color:  window.location.pathname === '/ugesrapport' && theme !== 'dark' ? '#982f2f' : window.location.pathname === '/ugesrapport' && theme === 'dark' ? '#ecc590' :  theme === 'dark' ? '#fff' :'#101010',
                            //  (pageKey === "z" && theme === 'dark') ? '#ecc590'  : (pageKey === 'z' && theme !== 'dark') ?  "#982f2f"  : (pageKey !== 'z' && theme === 'dark') ? 'white' : '#101010'
                          }}
                          >
                            {/* <p style={{ margin:0, }} onClick={handleClick}> */}
                            Billetsalg pr. uge {" "}
                            {/* </p>{" "} */}
                          </Link>
        
        </button> <br/>
{ reportChecked === 'true' ? '' :
<>
       <button   style={{border:'none', marginBottom: 6 + 'px', background:'transparent', marginLeft:'8px'}} 
       onClick={() => { setOpen(!open); }}>
       <Link
                            to="/ugesrapport2"
                            style={{ textDecoration: "none", color:
                           window.location.pathname === '/ugesrapport2' && theme !== 'dark' ? '#982f2f' :window.location.pathname === '/ugesrapport2' && theme === 'dark' ? '#ecc590' : theme === 'dark' ? '#fff':  '#101010'   
                             }}
                          >         
                            Billetsalg pr. forestilling           
                          </Link>
       
        </button>   
      
        </>
         }
         <br/>
           <button  style={{border:'none', marginBottom: 6 + 'px', background:'transparent', marginLeft:'8px'}} 
       onClick={() => { setOpen(!open); }}>
                              <Link to="/fourweeksreport" style={{ textDecoration: "none", 
                          color:  theme !== 'dark' ? '#982f2f' :window.location.pathname === '/fourweeksreport' && theme === 'dark' ? '#ecc590' : theme === 'dark' ? '#fff':  '#101010'   
                             }}>
                              Fireugersrapport
                              </Link>
                             </button>
      </div>
    );
  };

 
  const DagsMenu = (props) => {
    return (
      <div>
        <button
          style={{
            border: "none",
            marginBottom: 6 + "px",
            background: "transparent",
            color: pageKey === "c" ? "#982f2f" : "black",
            marginLeft: "8px",
          }}
          onClick={() => {
            props.onClick("c");
          }}
        >
          {" "}
          Dagsperioder
        </button>

        {/* <button style={{border:'none',marginBottom: 6 + 'px',  background:'transparent' , color: pageKey === 'd'  ? "#982f2f" : "black", marginLeft:'8px'}} onClick={() => {props.onClick("d") } }> Dagsperioder pr. dag</button>  */}
      </div>
    );
  };
  let page;
  switch (pageKey) {
    case "z":
      page = <UgesRapport/>;
      break;

      case "b":
        page = <BilletsalgPerFors />;
        break;

    case "c":
      page = <Dagperiode />;
      break;

    case "e":
      page = <Dagens />;
      break;

    case "f":
      page = <PeriodeSam />;
      break;

    case "x":
      page = <WorkPlan />
      break;

    default:
      page = "";
      break;
  }

  return (
    <>
      <header className="header-menu" 
      style={{height: isChecked === true  ? '50px' : '69px',
      position: isChecked === true ? 'relative' : '',
       }}>
        <fugure style={{width:'260px'}} className ='mobil_figure'>
             <img  src={theme === 'dark' ? smallLogo : salllogocolor} style={{padding:'15px'}} alt='ebillet-logo'/>
        </fugure>
        <figure style={{width:'260px'}} className="desktop_figure">
      <img className={ theme === 'dark' ? 'ebillet_logo' : ''} 
      src={  theme === 'dark' ? logo2 :logo} 
      alt="ebillet-logo" style={{padding:theme === 'dark' ? isChecked === true  ? '14px':'': ''}}
        />
        </figure>
       
        <div className="menus" style={{ display: isChecked === true ? 'none' : ''}}>
          <a className={homeActive} href>
            <Link className="menu-item" to="/admin">
              <img src={home} alt="" />
              <p className="menus-text">Hjem</p>
            </Link>
          </a>

          <a className={isActive} href>
            <Link className="menu-item" to="/reports">
              <img src={docs} alt="" />
              <p className="menus-text">Rapporter</p>
            </Link>
          </a>

          <a className={smsActive} href>
            <Link className="menu-item" to="/messages">
              <img src={sms} alt="" />
              <p className="menus-text">Drift SMS</p>
            </Link>
          </a>

           {/* <a className={programActive} href>
            <Link className="menu-item" to="/program">
              <img src={program} alt="" />
              <p className="menus-text">Programlæg</p>
            </Link>
          </a>  */}

          <a className={setsActive} href>
            <Link className="menu-item" to="/configuration">
              <img src={settings} alt="" />
              <p className="menus-text">Opsætning</p>
            </Link>
          </a>
        </div>

        <div className="logInfo" style={{ display: isChecked === true  ? 'none' : ''}}>
          <p className="user">{userName ? userName : user}</p>
          <span className="Rectangle-13"></span>
          <button className="logout" onClick={logOut}>
            Log ud
          </button>
        </div>

        <div className="mobil-menu" style={{ display: isChecked === true ? 'flex' : ''}}>
          <button className="logud" onClick={logOut} 
          style={{color: isChecked === true ? 'white' : '', borderLeft : isChecked === true ? '0px' : ''}}
          >
            {" "}
            Log ud
          </button>

          <div className="Rectangle-2" onClick={handleClick} 
          style={{position: isChecked === true ? 'relative' : '' ,
           width: isChecked === true? '50px' : '', 
           backgroundColor: isChecked === true ? '#982f2f' : '',
          //  border: burgerMenuDisplay ? '1px solid white' : '',
           margin : isChecked === true ? '3px' : '',
           padding :isChecked === true ? '12px' : ''
           }}>
            <div class="Rectangle" 
            style={{backgroundColor: isChecked === true ? 'white' : '',
            height : isChecked === true ? '3px' : '', 
            // marginTop : burgerMenuDisplay ? '4px' :''
          }}
            ></div>
            <div class="Rectangle" style={{backgroundColor: isChecked === true ? 'white' : '',
            height : isChecked === true ? '3px' : '',
            marginTop : isChecked === true ? '4px' :''

          }}></div>
            <div class="Rectangle" style={{backgroundColor: isChecked === true ? 'white' : '',
            height : isChecked === true ? '3px' : '',
            marginTop : isChecked === true ? '4px' :''

          }}></div>
          </div>
        </div>
        {open && (
          <aside className="burgerMenu" style={{
             position: isChecked === true ? 'absolute' : '',
             top: isChecked === true ? '52px' : '',
             display: isChecked === true ? 'flex' : '',
             right: isChecked === true ? 0 : '',
            //  width: isChecked === true ? '80%' : '',
             minHeight: isChecked === true ? '952px' : '',
             backgroundColor : theme === 'dark' ? '#1a1b1e':  '#ececec' ,
             flexDirection : isChecked === true ? 'column' : '',
             zIndex : isChecked === true ? 99 : '',
             //border: theme === 'dark' ? '1px solid #8d8d8d':''
             }}>
            <div
              className="burgerMenuItems"
              onClick={handleClick}
              style={{ marginTop: 2 , 
                display: isChecked === true ? 'flex' : '',
                height: isChecked === true ? '42px' : '',
                margin: isChecked === true ? '3px' : '',
                padding: isChecked === true ? '7px' : '',
                backgroundColor :   theme === 'dark' ? '#520000' : '#810101' ,
                borderRadius: '4px'
              }}
            >
              <figure>
                <img src={home} alt="" />
              </figure>
              <Link
                to="/admin"
                style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}
              >
                <p className="menus-text">Hjem</p>
              </Link>
            </div>

            <div className="burgerMenuItems" onClick={handleArrowClick} 
             style={{ marginTop: 2 , 
              display: isChecked === true ? 'flex' : '',
              height: isChecked === true ? '42px' : '',
              margin: isChecked === true ? '3px' : '',
              padding: isChecked === true ? '7px' : '',
              backgroundColor :  arrowActive && theme === 'dark' ? '#982626' : theme === 'dark' ? '#520000' : '#810101' ,
              color: isChecked === true ? 'white' : '',
              borderRadius: '4px'
            }}
            >
              <figure>
                <img src={docs} alt="" />
              </figure>
              <p style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}>
                {" "}
                Rapporter
              </p>

              <button className="arrow" style={{
                border: isChecked === true ? 'none' : '',
                background : isChecked === true ? 'transparent' : '',
                position : isChecked === true ? 'absolute' : '',
                right : isChecked === true ? '10px' : ''
                }}>
                <p className={arrowActive ? "rotated" : ""}>
                  {" "}
                  <img src={whiteArrow} alt="" />{" "}
                </p>
              </button>
            </div>

            {arrowActive && (
              <>
              {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_COMPAREPERIODS")
                      return (
                     <div className="meu" style={{margin:'0px 5px',marginTop:'5px',paddingTop:'8px', height:'40px',backgroundColor:theme === 'dark' ? '' : 'white',marginBottom:'1px',color:'#101010',border: theme === 'dark' ? '1px solid #6c6c6c' :'',borderRadius:'4px'}} key={index} onClick={handleClick}>
                          <Link
                            to="/perioder"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 ,color: theme === 'dark' ? '#fff' : '#101010'}}>Periodesammenligninger</p>{" "}
                          </Link>
                        </div>
);
})}

   {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_WEEKSTAT")
                      return (
                    <>
                        <div className="reportsTitles"  style={{ textDecoration: "none", 
                        color: isShown && theme === 'dark' ? '#ecc590': theme === 'dark' ? '#fff' : isShown && theme !== 'dark' ? '#982f2f':  '#101010' ,fontWeight:isShown && 'bold',
                        backgroundColor:theme === 'dark' ? '' :'white',border: theme === 'dark' ? '1px solid #6c6c6c' :'' }}>Ugestatistikrapporter
                        <img src={theme === 'dark' ? whiteArrow :downArrow} onClick={openReports} style={{rotate :isShown ?  '180deg' : '', marginRight:'5px'}} alt="arrow"  />
                        </div>
                       { isShown && 
                       <div className="side-submenu">
                         <Menu onClick={setPageKey} />
                       </div>
                  }
                       </>
                        )
                      
                  })} 

              

                {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_DAYPERIODS")
                      return (
                        <div className="meu" style={{margin:'0px 5px',marginTop:'px',paddingTop:'8px', height:'40px',backgroundColor:theme === 'dark' ? '' :'white',maginBottom:'1px',border: theme === 'dark' ? '1px solid #6c6c6c' :'',borderRadius:'4px'}} key={index} onClick={handleClick}>
                          <Link
                            to="/dagsrapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            {" "}
                            <p style={{ paddingLeft: 10,color: theme === 'dark' ? '#fff' : '#101010' }}>Dagsperioder</p>
                          </Link>

                        
                        </div>
                      );
                  })}

               

                {rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_DAYREVENUE")
                      return (
                        <div className="meu" style={{margin:'5px',paddingTop:'8px', height:'40px',backgroundColor:theme === 'dark' ? '' :'white',border: theme === 'dark' ? '1px solid #6c6c6c' :'',borderRadius:'4px'}} key={index} onClick={handleClick}>
                          <Link
                            to="/dagenrapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 ,color: theme === 'dark' ? '#fff' : '#101010'}}>Dagens omsætning </p>{" "}
                          </Link>
                        </div>
                      );
                  })}
{/* 
{rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_WEEKSTAT")
                      return (
                        <div className="meu" key={index} onClick={handleClick}>
                          <Link
                            to="/ugesrapport"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 }}>
                              Ugestatestik
                            </p>{" "}
                          </Link>

                         
                        </div>
                      );
                  })} */}

{rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_WORKPLAN")
                      return (
                        <div className="meu" style={{margin:'5px',paddingTop:'8px', height:'40px',backgroundColor:theme === 'dark' ? '' :'white',borderRadius:'4px',border: theme === 'dark' ? '1px solid #6c6c6c' :''}} key={index} onClick={handleClick}>
                          <Link
                            to="/workplans"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <p style={{ paddingLeft: 10 ,color: theme === 'dark' ? '#fff' : '#101010'}}>Arbejdsplan</p>{" "}
                          </Link>
                        </div>
                        );
                  })}
              </>
            )} 

            <div className="burgerMenuItems" onClick={handleClick}  style={{ marginTop: 2 , 
                display: isChecked === true ? 'flex' : '',
                height: isChecked === true ? '42px' : '',
                margin: isChecked === true? '3px' : '',
                padding: isChecked === true ? '7px' : '',
                backgroundColor :   theme === 'dark' ? '#520000' : '#810101' ,
                borderRadius: '4px'
              }}>
              <figure>
                <img src={sms} alt="" />
              </figure>

              <Link
                to="/messages"
                style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}
              >
                <p className="menus-text">Drift SMS</p>
              </Link>
            </div>

            <div className="burgerMenuItems" onClick={handleClick}  style={{ marginTop: 2 , 
                display: isChecked === true ? 'flex' : '',
                height: isChecked === true ? '42px' : '',
                margin: isChecked === true ? '3px' : '',
                padding: isChecked === true ? '7px' : '',
                backgroundColor :  theme === 'dark' ? '#520000' : '#810101',
                borderRadius: '4px'
              }}>
              <figure>
                <img src={settings} alt="" />
              </figure>
              <Link
                style={{ marginLeft: 10 + "px", marginTop: 2 + "px" }}
                to="/configuration"
              >
                <p className="menus-text">Opsætning</p>
              </Link>
            </div>
          </aside>
        )}

      </header>
      {/* {page} */}
    </>
  );
}
