import React, { useEffect, useState, useRef, useContext, CSSProperties} from "react";

// import NavMenu from "../components/NavMenu";
import DayTable from "../components/DayTable";
import moment from "moment";

import "../App.css";
 import "../components/css/rapport.css";
 import "../components/css/dagsperiode.css";
 import "../components/css/calendar.css";

import calendar from "../images/Icon-Calendar.png";
import close from "../images/close.png";
import downArrow from '../images/arrow-down.svg'
import whiteArrow from '../images/white-arrow.svg'
import whiteCalendar from '../images/whiteCalendar.svg'
import PulseLoader from "react-spinners/PulseLoader";


import { DateRange } from "react-date-range";
// import format from "date-fns/format";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate } from "react-router-dom";

import { format, isSameMonth } from "date-fns";
import { DayPicker } from "react-day-picker";
import { addMonths, addYears, subYears } from "date-fns";
// import CaptionProps, { useNavigation, navi } from "react-day-picker";

import "react-day-picker/dist/style.css";
import da from "date-fns/locale/da";

import "../components/dateRange.css";
import { keyContext } from "../components/KeyContext";

// import Header from "../components/header/Header";
// import { DayPicker } from "react-day-picker";

function Dagperiode() {
  const { setStartDate, setEndDate } = useContext(keyContext);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [err, setErr] = useState(null);
  const key = sessionStorage.getItem("key");
  const defaultFilmID = sessionStorage.getItem("default-filmID");
  const navigate = useNavigate();
  // console.log(defaultFilmID)
  let theme = localStorage.getItem("theme")

  const [showFirstCal, setshowFirstCal] = useState(false);
  const [showSecondCal, setShowSecondCal] = useState(false);
  const [firstSelectedDay, setFirstSelectedDay] = useState(new Date());
  const [secondSelectedDay, setSecondSelectedDay] = useState(addDays(new Date(), 6));

  const today = new Date();
  const nextYear = addYears(new Date(), 1);

  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(nextYear);

  let firstDate;
  if (firstSelectedDay) {
    firstDate = (
      <span>
        {" "}
        {format(firstSelectedDay, "d. ")}{" "}
        {moment(firstSelectedDay).locale("da").format("MMM")}{" "}
        {format(firstSelectedDay, "yyyy")}{" "}
      </span>
    );
  }
  let secondDate;
  if (secondSelectedDay) {
    secondDate = (
      <span>
        {" "}
        {format(secondSelectedDay, "d. ")}{" "}
        {moment(secondSelectedDay).locale("da").format("MMM")}{" "}
        {format(secondSelectedDay, "yyyy")}{" "}
      </span>
    );
  }

  let fraDato = moment(firstSelectedDay).format("yyyy-MM-DD")
  let tilDato = moment(secondSelectedDay).format("yyyy-MM-DD")
  // console.log(moment(firstSelectedDay).format("DD-MM-yyyy"), 'first date');
  // console.log(moment(secondSelectedDay).format("DD-MM-yyyy"), 'second date');
// console.log(fraDato, tilDato)
  //-------------Biograf functionality--------------
  const [film, setFilm] = useState([{}]);
  const [dagsFilm, setDagsFilm] = useState("");
  const [selected, setSelected] = useState(film);

  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getorganizers?Key=${key}`;
    if (key) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result === 0) {
            navigate("/");
          }
          setFilm(result);

          if (result.length === 1) {
            sessionStorage.setItem("default-filmID", result[0].No);
          }
        });
    }
  }, [key]);

  const handleSelect = (e) => {
    let g = e.target.value;
    setDagsFilm(g.split(" ")[g.split(" ").length - 1]);
    setSelected(e.target.value);

    sessionStorage.setItem("dagsFilm", e.target.value);
  };
  let movies = sessionStorage.getItem("dagsFilm");

  //-----------periode functionality------------
  const period = sessionStorage.getItem("dagsperiod");

  useEffect(() => {
    document.title = "Dagsperiode - ebillet_admin";
  }, []);

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 6),
      key: "selection",
    },
  ]);

  setStartDate(format(range[0].startDate, "dd-MM-yyyy"));
  setEndDate(format(range[0].endDate, "dd-MM-yyyy"));

  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  //Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  

  //--------hent  button functionality---------
  const { startDate, endDate, setDagsData } = useContext(keyContext);
  // const [dagsData, setDagsData] = useState([]);
  const [result, setResult] = useState("");


  var endString = endDate.split("-").reverse().join("-");

  var startString = startDate.split("-").reverse().join("-");
  const strD = sessionStorage.getItem("dagsperiod");
  // console.log(strD)
  const fD = strD && strD.slice(0, 10);
  const lD = strD && strD.slice(14, 25);
    let ff = fD && fD.split("-").reverse().join("-");
  const dd = lD && lD.split("-").reverse().join("-");
  // console.log(ff, dd , 'SELECTED FIRST AND SECOND DATE')

  // console.log(moment(dd).format("DD.MMM.yyyy"))

  const override: CSSProperties = {
    display: "block",
    position:'absolute',
    top:'50%',
    left:'50%',
    margin: "0 auto",
    textAlign:"center",
    borderColor: "#982f2f",
    transform:'translate(-50%, -50%)'
  };



  function hentData() {
    setIsLoading(true);

    const filmID = sessionStorage.getItem("dagsFilm");
   
    // console.log(lD)
    // console.log(filmID,fD)
   
    // console.log(ff, dd)
    const orgNr = filmID && filmID.split(" ")[filmID.split(" ").length - 1];
    // console.log(fId)
     // console.log(startString, endString , 'start and end dates')
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
      orgNr ? orgNr : defaultFilmID
    }&StartDate=${fraDato ? fraDato : ff}&EndDate=${
      tilDato ? tilDato : dd
    }&FromTime=00:00:00&ToTime=23:59:59`;

    fetch(endpoint, {
      method: "GET",

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("fejl i API... kunne ikke vise data");
        }
        return res.json();
      })
      .then((result) => {
        // console.log(result)
        if (result.result === 0) {
          navigate("/");
        }
        setIsLoading(false);
        if (result.Message) {
          setNoData(true);
          setDagsData(null);
        } else {
          setIsLoading(false);
          setNoData(false);
          setDagsData(result);
          setResult(result);
        }
      })
      .catch((err) => setErr(err.message));
  }

  let arr = [];

  result && result.filter((i) => arr.push(i.ArrTime.slice(0, 10)));

  function toFindDuplicates(arr) {
    const uniqueElements = new Set(arr);
    // const filteredElements =
    arr.filter((item) => {
      if (uniqueElements.has(item)) {
        // uniqueElements.delete(item);
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateElements = toFindDuplicates(arr);
  let dt = duplicateElements.sort((a, b) => (a > b ? 1 : -1));

  // useEffect(() => {
  if (duplicateElements.length) {
    let period = `${dt[0].split("-").reverse().join("-")} to ${dt[dt.length - 1]
      .split("-")
      .reverse()
      .join("-")}`;
    sessionStorage.setItem("dagsperiod", period);
  }

  const [dateB4, setDateB4] = useState(true);
const [secondDateB4, setSecondDateB4] = useState(true);

  function handleFirstClick() {
    setshowFirstCal(true);
    setDateB4(false)
  }
function handleSecondClick() {
  setShowSecondCal(true)
  setSecondDateB4(false)
  
}
  function closeCalendar() {
    setshowFirstCal(false);
    
  }
  function closeCalendar2() {
      setShowSecondCal(false)
    
  }

  // console.log();
  
  
  const o = document.querySelectorAll('option')
const lenMax = 35
o.forEach(e => {
  if (e.textContent.length > lenMax) {
    e.textContent = e.textContent.substring(0,lenMax) + '...';  
  }
  
})
  return (
    <main>
      <div className="rapport">
        {/* <span>{firstDate}</span> */}
        <p style={{ fontSize:'25px' }}>Dagsperioder </p>
     
        <div className="filmDate" style={{position:'relative',top:'10px'}}>

          <form className="form" >
            <div className="biografBox">
              <p className="biograf" style={{color: theme === 'dark' ? '#fff' : '#46484c',opacity: theme === 'dark' ? '0.6' : ''}}>Biograf </p>
              
              <span className="custom-select-lineD" style={{backgroundColor: theme === 'dark' ? '#8d8d8d' :''}}></span>
              
              <select
              className="text-truncate form-select"
              style={{maxWidth:'400px',width:'293px',height:'40px',backgroundColor: theme === 'dark' ? '#65696c' : '', color: theme === 'dark' ? '#fff' : '', border:  '1px solid #8d8d8d', backgroundImage:`url(${ theme === 'dark' ? whiteArrow : downArrow})`,backgroundRepeat:'no-repeat',}}
              onChange={handleSelect}
              value={movies ? movies : selected}
            >
              {film &&
                film.map((item) => {
                  let bb = item.City + " - " + item.Name + " " + item.No;
                  return (
                    <option key={item.id} value={bb}  className="text-truncate"
                    style={{
                      maxWidth: 200,
                      marginLeft: '10px',
                      fontSize: "16px",
                      
                      
                    }}>
                      {item.City} - {item.Name}
                    </option>
                  );
                })}
            </select> 
              {/* </div> */}
            </div>
          </form>{" "}
          <section className="period-box">
            <p className="biograf" style={{color: theme === 'dark' ? '#fff' : '#46484c',opacity: theme === 'dark' ? '0.6' : ''}}>Periode
           
            </p>

            <div  className="periode-box">
              <span className="fra" style={{color: theme === 'dark' ? '#fff' : '#46484c',opacity: theme === 'dark' ? '0.6' : ''}}>Fra:</span>
              <div className="date-box" style={{backgroundColor:theme === 'dark' ? '#65696c' : '',border:'1px solid #8d8d8d'}}>
              <span className="first-date" style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>{ff && dateB4 === true ? moment(ff).format("DD.MMM. yyyy"):  firstDate}</span>
                <span className="date-box-line" style={{backgroundColor:  '#8d8d8d' }}></span>
                <img
                  src={theme === 'dark' ? whiteCalendar :calendar}
                  alt=""
                  className="date-box-calendar"
                  onClick={handleFirstClick}
                />
                {showFirstCal ? (
                  <div className="dagsCalendar">
                    <figure style={{ textAlign: "right", padding: 10 }}>
                      <img
                        src={close}
                        alt=""
                        style={{
                          cursor: "pointer",
                          border: "1px solid white",
                          backgroundColor: "white",
                        }}
                        onClick={closeCalendar}
                      />
                    </figure>
                    <div className="weekdays " style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>
                      <span className="days">Man</span>
                      <span className="days">Tirs</span>
                      <span className="days">Ons</span>
                      <span className="days">Tors</span>
                      <span className="days">Fre</span>
                      <span className="days">Lør</span>
                      <span className="days">Søn</span>
                    </div>
                    <DayPicker
                      locale={da}
                      mode="single"
                      fromYear={2015}
                      toYear={2030}
                      captionLayout="dropdown"
                      showOutsideDays
                      showWeekNumber
                      selected={firstSelectedDay}
                      month={month}
                      year={year}
                      onYearChange={setYear}
                      onMonthChange={setMonth}
                      onSelect={setFirstSelectedDay}
                      onDayClick={closeCalendar}
                      
                    />
                    <br />
                    <div style={{ textAlign: "center" }}>
                      <button
                        disabled={isSameMonth(today, month)}
                        onClick={() => {
                          setMonth(today);
                          setYear(today);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          // background: "transparent",
                          fontSize: 12 + "px",
                          fontWeight: "bold",
                        }}
                      >
                        <u> GÅ TIL I DAG </u>
                      </button>
                    </div>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <span className="fra" style={{color: theme === 'dark' ? '#fff' : '#46484c',opacity: theme === 'dark' ? '0.6' : ''}}>Til:</span>
              <div className="date-box-til" style={{backgroundColor:theme === 'dark' ? '#65696c' : '',border:'1px solid #8d8d8d'}}>
                <span className="first-date" style={{color: theme === 'dark' ? '#fff' : '#46484c'}}> {dd && secondDateB4 === true ? moment(dd).format("DD.MMM.yyyy"): secondDate} </span>
                <span className="date-box-til-line" style={{backgroundColor:  '#8d8d8d' }}></span>
                <img src={theme === 'dark' ? whiteCalendar :calendar} alt="" className="date-box-til-calendar" onClick={handleSecondClick}/>
                {showSecondCal ? (
                  <div className="dagsCalendar2">
                    <figure style={{ textAlign: "right", padding: 10 }}>
                      <img
                        src={close}
                        alt=""
                        style={{
                          cursor: "pointer",
                          border: "1px solid white",
                          backgroundColor: "white",
                        }}
                        onClick={closeCalendar2}
                      />
                    </figure>
                    <div className="weekdays" style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>
                      <span className="days">Man</span>
                      <span className="days">Tirs</span>
                      <span className="days">Ons</span>
                      <span className="days">Tors</span>
                      <span className="days">Fre</span>
                      <span className="days">Lør</span>
                      <span className="days">Søn</span>
                    </div>
                    <DayPicker
                      locale={da}
                      mode="single"
                      fromYear={2015}
                      toYear={2030}
                      captionLayout="dropdown"
                      showOutsideDays
                      showWeekNumber
                      selected={secondSelectedDay}
                      month={month}
                      year={year}
                      onYearChange={setYear}
                      onMonthChange={setMonth}
                      onSelect={setSecondSelectedDay}
                      onDayClick={closeCalendar2}
                      
                    />
                    <br />
                    <div style={{ textAlign: "center" }}>
                      <button
                        disabled={isSameMonth(today, month)}
                        onClick={() => {
                          setMonth(today);
                          setYear(today);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          // background: "transparent",
                          fontSize: 12 + "px",
                          fontWeight: "bold",
                        }}
                      >
                        <u> GÅ TIL I DAG </u>
                      </button>
                    </div>
                    <br />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        
          <div>
            <button onClick={hentData} className="hentBtn " style={{backgroundColor: theme === 'dark' ? '#4e5052': ''}}>
              VIS
            </button>
          </div>
        </div>
        {err && <h2>{err}</h2>}
        {/* {isLoading && <h2>Loading...</h2>} */}
        {noData && <h2>Ingen forestillinger i valgte biograf....</h2>}

        <div style={{height: isLoading === true && 200  , position:'relative'}}>
        {isLoading ? (
          <PulseLoader
           
            color="#982f2f" 
            loading={isLoading}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          " "
        )}
</div>

        <DayTable />
      </div>
    </main>
  );
}

export default Dagperiode;
