import React, {useEffect, useState} from 'react'
import moment from 'moment'

function FourWeeksTable ({selectedWeekNr, selectedWeekStart, selectedWeekEnd, data, 
     oms_in_all, 
    data_per_year,
     currentweekdata, week1data,week2data,week3data, week1start, week2start,week3start,selectedYear,orgNr,movies_nos,totalMovieSale,sortedData}) {

const yearData = data_per_year &&  data_per_year[0]
const omsData = oms_in_all && oms_in_all[0]
const theme = localStorage.getItem('theme')
const key = sessionStorage.getItem('key')
// console.log(omsData, 'yearly data')

const date1 = moment(selectedWeekStart).format('DD.MM.YYYY') ;
const date2 = moment(selectedWeekEnd).format('DD.MM.YYYY') ;

const week1nr =   selectedWeekNr < 4 ?  moment(week1start).format('w') : selectedWeekNr - 3 
const week2nr = selectedWeekNr < 4 ?  moment(week2start).format('w') : selectedWeekNr - 2
const week3nr =  selectedWeekNr < 4 ?  moment(week3start).format('w') : selectedWeekNr - 1
        
const weeksData = sortedData
 

    return (
    <main style ={{marginTop:'0px'}}>
       
       {weeksData ?
        <table className='table all_data_table' style={{borderColor:theme === 'dark' ? '#6c6c6c' : ''}}>
             <thead>
                <tr  className='positioned_row '>
                    <td className='tableTitle borders_color' style={{border:'1px',paddingLeft:0,width:'146px',opacity:theme === 'dark' ? 0.6 : ''}}>Omsætning</td>
                    <td style={{border:'1px'}}></td>
                    <td style={{border:'1px'}}></td>
                    <td style={{border:'1px'}}></td>
                    <td className='borders_color' colSpan='8' style={{border:'1px ', textAlign:'center',opacity:theme === 'dark' ? 0.6 : ''}}> Uge  {selectedWeekNr}
                    <span className='smallText' style={{marginLeft:'10px'}}>{ date1} - {date2}</span> </td>
                    <td style={{border:'1px',textAlign:'center',opacity:theme === 'dark' ? 0.6 : '',lineHeight:'15px'}}>Total</td>
                    <td style={{border:'1px',textAlign:'center',opacity:theme === 'dark' ? 0.6 : '',lineHeight:'15px'}}>Total</td>
                </tr>
                <tr style={{height:'50px'}}>
                    <td className='tableTitle borders_color' style={{paddingLeft:0,opacity:theme === 'dark' ? 0.6 : '',paddingTop:'3px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}>pr.film</td>
                    <td style={{opacity:theme === 'dark' ? 0.6 : '',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', width:'75px',}}> Uge { (selectedWeekNr < 13 && selectedWeekNr > 6) || week1nr == 1  || week1nr == 2 ? '0':''}{week1nr}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', width:'75px',opacity:theme === 'dark' ? 0.6 : ''}}> Uge { (selectedWeekNr < 12  && selectedWeekNr > 4) || week2nr == 1 || week2nr == 2  ? '0':''}{week2nr} </td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', width:'75px',opacity:theme === 'dark' ? 0.6 : ''}}> Uge { (selectedWeekNr < 11 && selectedWeekNr > 4) || week3nr == '1' || week3nr == '2' || week3nr == 3 ? '0':''}{week3nr}</td>
                    <td className='smallText ' style={{width:'56px'}}>Man.</td>
                    <td className='smallText ' style={{width:'56px'}}>Tir.</td>
                    <td className='smallText ' style={{width:'56px'}}>Ons.</td>
                    <td className='smallText ' style={{width:'56px'}}>Tors.</td>
                    <td className='smallText ' style={{width:'56px'}}>Fre.</td>
                    <td className='smallText ' style={{width:'56px'}}>Lør.</td>
                    <td className='smallText ' style={{width:'56px'}}>Søn.</td>
                    <td className='smallText ' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',width:'57px'}}>I alt</td>
                    <td className='smallText ' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',width:'67px',padding:0,lineHeight:'32px',textAlign:'center'}}>uge {week1nr == 1 || week1nr == 2 || week1nr == 3 || week1nr == 4 || week1nr == 5 || week1nr == 6 || week1nr == 7 || week1nr == 8 || week1nr == 9 ? 0 : ''}{week1nr} - {selectedWeekNr}</td>
                    <td className='smallText' style={{width:'80px',paddingBottom:'5px',lineHeight:'11px',textAlign:'center'}} >Hele spille-perioden</td>
                </tr>
             </thead>
             { weeksData &&  weeksData.map((data) => {
            return    <tbody className='formatted_body'>
                  <tr>
                    <td colSpan='4' style={{paddingLeft:'5px',paddingTop:'3px',paddingBottom:'3px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}} className='film_title nobottomborder'>{data.name}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}></td>
                    <td className='nobottomborder white_bg' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',backgroundColor:theme === 'dark' ? 'rgba(255,255,255,10%)' : ''}}></td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',fontWeight:'bold',backgroundColor:theme === 'dark' ? 'rgba(231,231,231,20%)' : 'rgba(231,231,231,45%)',}}></td>
                    <td className='nobottomborder' style={{fontWeight:'bold'}}></td>                  
                  </tr>
                  <tr style={{fontSize:'12px',paddingLeft:'5px'}}>
                    <td className='ticket-text nobottomborder' style={{paddingLeft:'5px',paddingTop:'3px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}>Billetter, antal</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.week1tickets).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.week2tickets).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.week3tickets).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.monday.bil).toLocaleString('da')  }</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.tuesday.bil).toLocaleString('da') }</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.wednesday.bil).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.thursday.bil).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.friday.bil).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.saturday.bil).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px'}}>{(data.sunday.bil).toLocaleString('da')}</td>
                    <td className='nobottomborder  white_bg' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'3px',backgroundColor:theme === 'dark' ? 'rgba(255,255,255,10%)' : ''}}>{(data.monday.bil+data.tuesday.bil+data.wednesday.bil+data.thursday.bil+data.friday.bil+data.saturday.bil+data.sunday.bil).toLocaleString('da')}</td>

                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',paddingTop:'3px',backgroundColor:theme === 'dark' ? 'rgba(231,231,231,20%)' : 'rgba(231,231,231,45%)'}}>{(data.monday.bil+data.tuesday.bil+data.wednesday.bil+data.thursday.bil+data.friday.bil+data.saturday.bil+data.sunday.bil+data.week1tickets+data.week2tickets+data.week3tickets).toLocaleString('da')}</td>

                    <td className='nobottomborder' style={{textAlign:'center',fontWeight:'bold',paddingTop:'3px'}}>{data.ticketCount}</td>
                </tr>
                <tr style={{fontSize:'12px',paddingLeft:'5px'}}>
                    <td className='ticket-text'  style={{paddingLeft:'5px',paddingBottom:'3px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}>Omsætning, dkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.week1oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.week2oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.week3oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.monday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.tuesday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.wednesday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.thursday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.friday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.saturday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(data.sunday.oms).toLocaleString('da')}</td>
                    <td className='white_bg' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',backgroundColor:theme === 'dark' ? 'rgba(255,255,255,10%)' : ''}}>{Math.round(data.monday.oms+data.tuesday.oms+data.wednesday.oms+data.thursday.oms+data.friday.oms+data.saturday.oms+data.sunday.oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',backgroundColor:theme === 'dark' ? 'rgba(231,231,231,20%)' : 'rgba(231,231,231,45%)'}}>{Math.round(data.monday.oms+data.tuesday.oms+data.wednesday.oms+data.thursday.oms+data.friday.oms+data.saturday.oms+data.sunday.oms+data.week1oms+data.week2oms+data.week3oms).toLocaleString('da')}</td>
                    <td style={{textAlign:'center',fontWeight:'bold'}}>{Math.round(data.ticketRevenue).toLocaleString('da')}</td>
                </tr>
                </tbody>
             })}
            
        </table>
 : ''}
{omsData ? 
        <table className='table oms_table yealy_data_table' style={{borderColor:theme === 'dark' ? '#6c6c6c' : '#dee0e3'}}>
             <thead>
                <tr  className='positioned_row '>
                    <td className='tableTitle' style={{border:'1px',paddingLeft:0,width:'147px',opacity:theme === 'dark' ? '0.6' : ''}}>Omsætning</td>
                    <td style={{border:'1px',width:'76px'}}></td>
                    <td style={{border:'1px',width:'76px'}}></td>
                    <td style={{border:'1px',width:'76px'}}></td>
                    <td colSpan='8' style={{border:'1px ', textAlign:'center',opacity:theme === 'dark' ? '0.6' : ''}}> Uge {selectedWeekNr}
                    <span className='smallText' style={{marginLeft:'10px'}}>{ date1} - {date2}</span> </td>
                    <td style={{border:'1px',textAlign:'center',opacity:theme === 'dark' ? '0.6' : '',lineHeight:'15px'}}>Total</td>
                    <td style={{border:'1px',textAlign:'center'}}></td>
                </tr>
                <tr style={{height:'50px'}}>
                    <td className='tableTitle' style={{paddingLeft:0,opacity:theme === 'dark' ? '0.6' : '',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',width:'147px',}}>i alt</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',opacity:theme === 'dark' ? '0.6' : '',width:'75px'}}> 
                      Uge  { (selectedWeekNr < 13 && selectedWeekNr > 6) || week1nr == 1  || week1nr == 2 ? '0':''}{week1nr}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',opacity:theme === 'dark' ? '0.6' : '',width:'75px'}}> Uge { (selectedWeekNr < 12  && selectedWeekNr > 4) || week2nr == 1 || week2nr == 2  ? '0':''}{week2nr}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',opacity:theme === 'dark' ? '0.6' : '',width:'75px'}}>  Uge { (selectedWeekNr < 11 && selectedWeekNr > 4) || week3nr == '1' || week3nr == '2' || week3nr == 3 ? '0':''}{week3nr}</td>
                    <td className='smallText' style={{width:'56px'}} >Man.</td>
                    <td className='smallText' style={{width:'56px'}}>Tir.</td>
                    <td className='smallText' style={{width:'56px'}}>Ons.</td>
                    <td className='smallText' style={{width:'56px'}}>Tors.</td>
                    <td className='smallText' style={{width:'56px'}}>Fre.</td>
                    <td className='smallText' style={{width:'56px'}}>Lør.</td>
                    <td className='smallText' style={{width:'56px',position:'relative'}}>Søn.   <span style={{position:'absolute', bottom:0,right:'0px',height:'25px',width:'1px',backgroundColor: theme === 'dark' ? '#6c6c6c':'#dee0e3'}}></span></td>
                    <td className='smallText' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',width:'57px',textAlign:'center'}}>I alt</td>
                    
                    <td className='smallText' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',width:'67px',padding:0,lineHeight:'32px',textAlign:'center'}}>uge {week1nr == 1 || week1nr == 2 || week1nr == 3 || week1nr == 4 || week1nr == 5 || week1nr == 6 || week1nr == 7 || week1nr == 8 || week1nr == 9 ? 0 : ''}{week1nr} - {selectedWeekNr}</td>
                     <td className='smallText' style={{width:'82px',paddingBottom:'5px',lineHeight:'11px',textAlign:'center',border:'none'}} ></td> 
               </tr>
             </thead>
             <tbody className='formatted_body '>
           
                <tr style={{fontSize:'12px',paddingLeft:'5px'}}>
                    <td className='ticket-text nobottomborder' style={{paddingTop:'3px',paddingLeft:'5px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}>Billetter, antal</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week1totaltickets).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week2totaltickets).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week3totaltickets).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.monday.selectedWeektotaltickets}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.tuesday.selectedWeektotaltickets}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.wednesday.selectedWeektotaltickets}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.thursday.selectedWeektotaltickets}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.friday.selectedWeektotaltickets}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.saturday.selectedWeektotaltickets}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{omsData.sunday.selectedWeektotaltickets}</td>
                   
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',paddingTop:'3px',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',paddingTop:'3px',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets+omsData.week1totaltickets+omsData.week2totaltickets+omsData.week3totaltickets).toLocaleString('da')}</td>
                   
                 </tr>
                <tr style={{fontSize:'12px',}}>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px'}} className='ticket-text nobottomborder'>Billetomsætning, dkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.week1totaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.week2totaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.week3totaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.monday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.tuesday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.wednesday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.thursday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.friday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.saturday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Math.round(omsData.sunday.selectedWeektotaloms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{Math.round(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{Math.round(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms+ omsData.week1totaloms + omsData.week2totaloms + omsData.week3totaloms).toLocaleString('da')}</td>
                   
                </tr>
                <tr className='' style={{fontSize:'12px'}}>
                    <td className='ticket-text'style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingBottom:'3px',paddingLeft:'5px'}}>Gns. billetpris, dkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.week1Gnmtktpris).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.week2Gnmtktpris).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.week3Gnmtktpris).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.monday.selectedWeekGnmtktpris > 0 ? (omsData.monday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.tuesday.selectedWeekGnmtktpris > 0 ? (omsData.tuesday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.wednesday.selectedWeekGnmtktpris > 0 ? (omsData.wednesday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.thursday.selectedWeekGnmtktpris > 0 ? (omsData.thursday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.friday.selectedWeekGnmtktpris > 0 ? (omsData.friday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.saturday.selectedWeekGnmtktpris > 0 ? (omsData.saturday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{omsData.sunday.selectedWeekGnmtktpris > 0 ? (omsData.sunday.selectedWeekGnmtktpris).toLocaleString('da') : 0}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{Math.round((omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms)/(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets)).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{Math.round((omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms+ omsData.week1totaloms + omsData.week2totaloms + omsData.week3totaloms)/(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets+omsData.week1totaltickets+omsData.week2totaltickets+omsData.week3totaltickets)).toLocaleString('da')}</td>
                   
                </tr>
                </tbody> 
                <tbody className='formatted_body '>
                <tr style={{fontSize:'12px',}}>
                    <td className='ticket-text nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px',paddingTop:'3px'}}>Kioskomsætning, dkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px',}}>{(omsData.week1cons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week2cons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week3cons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.monday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.tuesday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.wednesday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.thursday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.friday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.saturday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.sunday.selectedWeekcons).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',paddingTop:'3px',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{Math.round(omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',paddingTop:'3px',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#f7f7f7'}}>{Math.round(omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons+omsData.week1cons+omsData.week2cons+omsData.week3cons).toLocaleString('da')}</td>
                   
                </tr>
                <tr style={{fontSize:'12px',paddingLeft:'5px'}}>
                    <td className='ticket-text nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px'}}> Kioskoms pr. billet</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{(omsData.week1cons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{(omsData.week2cons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{(omsData.week3cons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.monday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.tuesday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.wednesday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.thursday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.friday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.saturday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.sunday.selectedWeekcons_pr_tkt).toLocaleString('da')}</td>
                   
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{((omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons)/(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets)).toLocaleString('da',{ maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#f7f7f7'}}>{Math.round((omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons+omsData.week1cons+omsData.week2cons+omsData.week3cons)/(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets+omsData.week1totaltickets+omsData.week2totaltickets+omsData.week3totaltickets)).toLocaleString('da')}</td>
                   
                </tr>
                
                <tr style={{fontSize:'12px',paddingLeft:'5px'}}>
                    <td className='ticket-text' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px',paddingBottom:'3px'}}>Kioskoms./billettoms.</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.week1cons_tktoms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.week2cons_tktoms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.week3cons_tktoms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.monday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.tuesday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.wednesday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.thursday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.friday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.saturday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingBottom:'3px'}}>{Number(omsData.sunday.selectedWeekcons_tkt_oms).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',paddingBottom:'3px',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>
                    {
                    ( (omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons)/(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms)).toLocaleString('da',{ maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',paddingBottom:'3px',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#f7f7f7'}}>{
                    ( (omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons+omsData.week1cons+omsData.week2cons+omsData.week3cons)/(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms+ omsData.week1totaloms + omsData.week2totaloms + omsData.week3totaloms)).toLocaleString('da',{ maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                   
                </tr>
                </tbody>
                <tbody className='formatted_body '>
                <tr style={{fontSize:'12px',}}>
                    <td className='ticket-text nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px',paddingTop:'3px'}}>Anden omsætning,dkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week1another).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week2another).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.week3another).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.monday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.tuesday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.wednesday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.thursday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.friday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.saturday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',paddingTop:'3px'}}>{(omsData.sunday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',paddingTop:'3px',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{Math.round(omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',paddingTop:'3px',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{Math.round(omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother+omsData.week1another+omsData.week2another+omsData.week3another).toLocaleString('da')}</td>
                   
                </tr>
                <tr style={{fontSize:'12px',}}>
                    <td className='ticket-text nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px'}}>Anden oms.pr.billet</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{(omsData.week1another_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{(omsData.week2another_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{(omsData.week3another_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.monday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.tuesday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.wednesday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.thursday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.friday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.saturday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none'}}>{Number(omsData.sunday.selectedWeekanother_pr_tkt).toLocaleString('da')}</td>
                    
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{((omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother)/(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets)).toLocaleString('da',{ maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', borderBottom:'none',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{((omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother+omsData.week1another+omsData.week2another+omsData.week3another)/(omsData.monday.selectedWeektotaltickets+omsData.tuesday.selectedWeektotaltickets+omsData.wednesday.selectedWeektotaltickets+omsData.thursday.selectedWeektotaltickets+omsData.friday.selectedWeektotaltickets+omsData.saturday.selectedWeektotaltickets+omsData.sunday.selectedWeektotaltickets+omsData.week1totaltickets+omsData.week2totaltickets+omsData.week3totaltickets)).toLocaleString('da',{ maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                   
                </tr>
                <tr style={{fontSize:'12px',}}>
                    <td className='ticket-text' style={{paddingLeft:'5px',paddingBottom:'3px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}>Anden oms./billettoms.</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.week1another_tktoms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.week2another_tktoms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.week3another_tktoms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.monday.selectedWeekanother_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.tuesday.selectedWeekanother_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.wednesday.selectedWeekanother_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.thursday.selectedWeekanother_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.friday.selectedWeekanother_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Number(omsData.saturday.selectedWeekanother_tkt_oms).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{(Number(omsData.sunday.selectedWeekanother_tkt_oms)).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{((omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother)/(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms)).toLocaleString('da',{ maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{((omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother+omsData.week1another+omsData.week2another+omsData.week3another)/(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms+ omsData.week1totaloms + omsData.week2totaloms + omsData.week3totaloms)).toLocaleString('da')}</td>
                   
                </tr>

</tbody>
<tbody style={{position:'relative'}}>
                <tr style={{fontSize:'12px',paddingLeft:'5px',lineHeight:'13px',fontWeight:'bold',borderBottom:'1px solid #46484c'}}>
                    <td className='ticket-text' style={{letterSpacing:'0.24px',paddingLeft:'5px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3'}}>Totalomsætning, dkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.week1totaloms+omsData.week1cons+omsData.week1another).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.week2totaloms+omsData.week2cons+omsData.week2another).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.week3totaloms+omsData.week3cons+omsData.week3another).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.monday.selectedWeektotaloms+omsData.monday.selectedWeekcons+omsData.monday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.tuesday.selectedWeektotaloms+omsData.tuesday.selectedWeekcons+omsData.tuesday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.wednesday.selectedWeektotaloms+omsData.wednesday.selectedWeekcons+omsData.wednesday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.thursday.selectedWeektotaloms+omsData.thursday.selectedWeekcons+omsData.thursday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.friday.selectedWeektotaloms+omsData.friday.selectedWeekcons+omsData.friday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight:'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.saturday.selectedWeektotaloms+omsData.saturday.selectedWeekcons+omsData.saturday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px'}}>{Math.round(omsData.sunday.selectedWeektotaloms+omsData.sunday.selectedWeekcons+omsData.sunday.selectedWeekanother).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(255,255,255,10%)' : '#fff'}}>{Math.round(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms+omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons+omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother).toLocaleString('da')}</td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center', paddingTop:'8px',fontWeight:'bold',backgroundColor: theme === 'dark' ? 'rgba(231,231,231,20%)' : '#e7e7e7'}}>{Math.round(omsData.monday.selectedWeektotaloms+omsData.tuesday.selectedWeektotaloms+omsData.wednesday.selectedWeektotaloms+omsData.thursday.selectedWeektotaloms+omsData.friday.selectedWeektotaloms+omsData.saturday.selectedWeektotaloms+omsData.sunday.selectedWeektotaloms+ omsData.week1totaloms + omsData.week2totaloms + omsData.week3totaloms+omsData.monday.selectedWeekcons+omsData.tuesday.selectedWeekcons+omsData.wednesday.selectedWeekcons+omsData.thursday.selectedWeekcons+omsData.friday.selectedWeekcons+omsData.saturday.selectedWeekcons+omsData.sunday.selectedWeekcons+omsData.week1cons+omsData.week2cons+omsData.week3cons+omsData.monday.selectedWeekanother+omsData.tuesday.selectedWeekanother+omsData.wednesday.selectedWeekanother+omsData.thursday.selectedWeekanother+omsData.friday.selectedWeekanother+omsData.saturday.selectedWeekanother+omsData.sunday.selectedWeekanother+omsData.week1another+omsData.week2another+omsData.week3another).toLocaleString('da')}</td>
                   <td style={{borderBottomColor:theme === 'dark' ? 'transparent':'white'}}></td>
                </tr>
               
               </tbody>
        </table> 
        : ''}
        <br />
        { yearData  ? 
        <table className='table yealy_data_table' style={{borderColor:theme === 'dark' ? '#6c6c6c' : ''}}>
            <thead>
                <tr>
                    <td className='tableTitle'  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'0',opacity:theme === 'dark' ? '0.6' : ''}}>År til dato</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',opacity:theme === 'dark' ? '0.6' : ''}}>{selectedYear}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',opacity:theme === 'dark' ? '0.6' : ''}}>{selectedYear - 1}</td>
                    <td style={{textAlign:'center',opacity:theme === 'dark' ? '0.6' : ''}}>{selectedYear - 2}</td>
                </tr>
            </thead>
            <tbody className='formatted_body  '>
                <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingTop:'8px'}}>Billetter,antal</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.currentYear.total_tkts).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.lastYear.total_tkts).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{textAlign:'center',paddingTop:'6px'}}>
                      {yearData.twoYearsAgo.total_tkts == false ? 0 : (yearData.twoYearsAgo.total_tkts).toLocaleString('da')}</td>
                    
               </tr>
                <tr style={{fontSize:'12px'}}>            
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingBottom:'6px'}}>Index (a)</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{yearData.currentYear.index_a}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{yearData.lastYear.index_a}</td>
                    <td style={{textAlign:'center'}}>{yearData.twoYearsAgo.index_a}</td>
                    
                </tr>
            </tbody>
            <tbody className='formatted_body '>
                <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingTop:'6px'}}>Billetomsætning, dkk</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.currentYear.total_sold_amount).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.lastYear.total_sold_amount).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{textAlign:'center',paddingTop:'6px'}}>{(yearData.twoYearsAgo.total_sold_amount).toLocaleString('da')}</td>
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',}}>Index (b)</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{yearData.currentYear.index_b}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{yearData.lastYear.index_a}</td>
                    <td className='nobottomborder' style={{textAlign:'center',}}>{yearData.twoYearsAgo.index_b}</td>
                    
               </tr>
                <tr style={{fontSize:'12px'}}>            
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingBottom:'6px'}}>Gns. billetpris, dkkk</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{(yearData.currentYear.gnmTktPris).toLocaleString('da')}</td>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{isNaN(yearData.lastYear.gnmTktPris ) ? 0 : (yearData.lastYear.gnmTktPris).toLocaleString('da')}</td>
                    <td style={{textAlign:'center'}}>{(yearData.twoYearsAgo.gnmTktPris).toLocaleString('da')}</td>
                    
                </tr>
            </tbody>
            <tbody className='formatted_body'>
            <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingTop:'6px'}}>Kioskomsætning, dkk</td>
                     <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.currentYear.yearly_cons).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.lastYear.yearly_cons).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{textAlign:'center',paddingTop:'6px'}}>{(yearData.twoYearsAgo.yearly_cons).toLocaleString('da')}</td>
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',}}>Index (c)</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{yearData.currentYear.index_c}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{yearData.lastYear.index_a}</td>
                    <td className='nobottomborder' style={{textAlign:'center',}}>{yearData.twoYearsAgo.index_c}</td>
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',}}>Kioskoms. pr. billet</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{(yearData.currentYear.yearly_cons_pr_tkt).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{(yearData.lastYear.yearly_cons_pr_tkt).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{textAlign:'center',}}>{(yearData.twoYearsAgo.yearly_cons_pr_tkt).toLocaleString('da')}</td> 
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingBottom:'6px'}}>Kioskoms./billetoms.</td>
                     <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{Number(yearData.currentYear.yearly_cons_pr_tktoms).toLocaleString('da')}</td>
                    <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{Number(yearData.lastYear.yearly_cons_pr_tktoms).toLocaleString('da')}</td>
                    <td  style={{textAlign:'center',}}>{Number(yearData.twoYearsAgo.yearly_cons_pr_tktoms).toLocaleString('da')}</td> 
                    
               </tr>
            </tbody>
            <tbody className='formatted_body'>
            <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingTop:'6px'}}>Anden omsætning, dkk</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.currentYear.yearly_another).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',paddingTop:'6px'}}>{(yearData.lastYear.yearly_another).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{textAlign:'center',paddingTop:'6px'}}>{(yearData.twoYearsAgo.yearly_another).toLocaleString('da')}</td>
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',}}>Index (d)</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{yearData.currentYear.index_d}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{yearData.lastYear.index_a}</td>
                    <td className='nobottomborder' style={{textAlign:'center',}}>{yearData.twoYearsAgo.index_d}</td>
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',}}>Anden oms. pr. billet</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{(yearData.currentYear.yearly_another_pr_tkt).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{(yearData.lastYear.yearly_another_pr_tkt).toLocaleString('da')}</td>
                    <td className='nobottomborder' style={{textAlign:'center',}}>{(yearData.twoYearsAgo.yearly_another_pr_tkt).toLocaleString('da')}</td>
                    
               </tr>
               <tr style={{fontSize:'12px'}}>
                    <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',paddingBottom:'6px'}}>Anden oms./billetoms.</td>
                    <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{Number(yearData.currentYear.yearly_another_pr_tktoms).toLocaleString('da')}</td>
                    <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',}}>{Number(yearData.lastYear.yearly_another_pr_tktoms).toLocaleString('da')}</td>
                    <td  style={{textAlign:'center',}}>{Number(yearData.twoYearsAgo.yearly_another_pr_tktoms).toLocaleString('da')}</td>
                    
               </tr>
            </tbody>
                
         <tfoot className='formatted_body'>
                <tr style={{padding:0,height:'40px',borderBottom: theme === 'dark' ? '' : '1px solid #46484c'}}>
                    <td  style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3', width:'147px',paddingLeft:'5px',fontWeight:'bold',fontSize:'12px'}}>Totalomsætning, dkk</td>
                    <td  style={{letterSpacing:'0.32px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(yearData.currentYear.total_sold_amount+yearData.currentYear.yearly_cons+yearData.currentYear.yearly_another).toLocaleString('da')}</td>
                    <td  style={{letterSpacing:'0.32px',borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center'}}>{Math.round(yearData.lastYear.total_sold_amount+yearData.lastYear.yearly_cons+yearData.lastYear.yearly_another).toLocaleString('da')}</td>
                    <td  style={{letterSpacing:'0.32px',textAlign:'center'}}>{Math.round(yearData.twoYearsAgo.total_sold_amount+yearData.twoYearsAgo.yearly_cons+yearData.twoYearsAgo.yearly_another).toLocaleString('da')}</td>
                </tr>
                <tr style={{fontSize:'12px'}}>
                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',paddingLeft:'5px',borderBottom:'none',paddingTop:'10px'}}>Indextal</td> 

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',borderBottom:'none',paddingTop:'10px'}}>
                      {Math.round(((yearData.currentYear.total_sold_amount+yearData.currentYear.yearly_cons+yearData.currentYear.yearly_another)/(yearData.lastYear.total_sold_amount+yearData.lastYear.yearly_cons+yearData.lastYear.yearly_another))*100)}
                    </td>

                    <td style={{borderRight: theme === 'dark' ? '1px solid #6c6c6c':'1px solid #dee0e3',textAlign:'center',fontWeight:'bold',borderBottom:'none',paddingTop:'10px'}}>100</td>
                    <td style={{textAlign:'center',fontWeight:'bold',borderBottom:'none',paddingTop:'10px'}}>
                      {Math.round(((yearData.twoYearsAgo.total_sold_amount+yearData.twoYearsAgo.yearly_cons+yearData.twoYearsAgo.yearly_another)/(yearData.lastYear.total_sold_amount+yearData.lastYear.yearly_cons+yearData.lastYear.yearly_another))*100)}
                    </td>
                </tr>
            </tfoot>
        </table>
        : ''}
        <br />
    </main>
)
}
export default FourWeeksTable