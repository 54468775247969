import React,{useEffect, useState,useContext} from 'react';
import checkmark from '../images/checkmark.svg';
import square from '../images/square.svg'
import small_square from '../images/small_square.svg'
import small_checkmark from '../images/small_checkmark.svg'
import downArrow from "../images/arrow-down.svg";
// import Draggable from "react-draggable";
import Modal from '../components/Modal'
import alertIcon from '../images/Icon-Altert_Small.png';
import { keyContext } from '../components/KeyContext';
import moment from 'moment';

function FilmWidget({index, film, extendedBackColor,timelineBackColor,priceDivBackColor, start_Time,showTime,end_Time,  salNr,
  internetOrderAll, internetSellAll, internetShowAll, ticketSellAll, ticketOrderAll, saveEditBtn, theWidth, arrtypename,price,webresinfo, webbuyinfo,webshowinfo,buyenabled,resenabled}) {

  const {selectAllFilmAtOnce, setSelectAllFilmAtOnce} = useContext(keyContext)

  const [modalShown, toggleModal] = useState(false);

  const [isActive, setIsActive]   = useState(false);
  const [isShown, setIsShown]     = useState(false);
  const [activeDrag, setActiveDrags] = useState(0);
  const [movieSelected, setMovieSelected] = useState(false);

  const [internetOrder, setInternetOrder]     = useState(webresinfo  === 1 ? true :false)
  const [internetSell, setInternetSell]       = useState(webbuyinfo  === 1 ? true :  false)
  const [internetShow, setInternetShow]       = useState(webshowinfo === 1 ? true :  false)
  const [ticketSellSell, setTicketSellSell]   = useState(buyenabled  === 1 ? true :  false)
  const [ticketSellOrder, setTicketSellOrder] = useState(resenabled  === 1 ? true :  false)

 
  const handleClick = (event) => {
    setIsShown((current) => !current);
    setIsActive(current => !current); 
    // console.log(moment().format('hh:mm:ss'))
  };
//  console.log(saveEditBtn,'save btn')

// zindex 10 to the film div which is clicked at last
useEffect(() => {
  if(isShown === true) {
    // console.log(moment().format('hh:mm:ss'))
  }
},[isShown])

   useEffect(() => {
    if(saveEditBtn === false) {
      setMovieSelected(selectAllFilmAtOnce === true && selectAllFilmAtOnce) 
    }  
 },[selectAllFilmAtOnce,internetOrderAll])

const handleMovieSelect = () => { 
  setMovieSelected((current) => !current);
}
  //--- movie check mark---
 const handleInternetSell = () => {
  setInternetSell((current) => !current)
 }
 const handleInternetShow = () => {
  setInternetShow((current) => !current)
 }
 const handleTicketSellSell = () => {
  setTicketSellSell((current) => !current)
 }
 const handleTicketSellOrder = () => {
  setTicketSellOrder((current) => !current)
 }
 
  const handleInternetOrder = () => {
    setInternetOrder((current) => !current)
  }
  // console.log(internetOrderAll, 'internet order all ')
//  console.log(internetOrder, 'internet order')
  // console.log(movieSelected, 'movie selected')
  // console.log(selectAllFilmAtOnce, 'select all film')
  return (
        
 <div style={{height:'43px',}}> 
  
    <div  style={{display:'flex',padding:'0',justifyContent:'space-between'}}>
          <span style={{position:'absolute',top:'-20px',fontSize:'12px'}}>{showTime && start_Time}</span>  
     
      <div className="shown-men" style={{width:'82%',height:'43px',display:'flex', justifyContent:'space-between'}}>
            <div className="" style={{display:'flex',width:'100%',paddingTop:'10px',paddingRight:'10px'}}>
            <p style={{height:'11px',width:'1px',backgroundColor:'rgba(255,255,255,0.3',marginRight:'2px',marginTop:'7px',padding:0,marginLeft:'5px'}}></p>
    <p style={{height:'11px',width:'1px',backgroundColor:'rgba(255,255,255,0.3',marginRight:'5px',marginTop:'7px',padding:0}}></p>
              <p className='film_name'>{film}</p>
              <button className="arrow_btn" style={{marginLeft:'0px', width:'20px',marginRight:'10px',border:'1px '}} onClick={() => handleClick()}>
                <span  > 
                  <svg style={{transform: isShown? 'rotate(180deg)' : '' }} xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 15 9" >
                  <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                  <g stroke="#46484c" strokeWidth="2.5">
                  <g>
                  <path d="M934 19.25L940.5 26.25 947 19.25" transform="translate(-1053 -509) translate(120 491)"/>
                   </g>
                   </g>
                   </g>
                   </svg> 
                    </span>
        </button>
            </div>

          </div>
  
        <div className='film_check'  onClick={handleMovieSelect}>
         <img style={{marginBottom:'12px'}} 
            src={movieSelected || ( movieSelected && selectAllFilmAtOnce) ? checkmark :square} alt="" />
      
        </div>

      {/* ALERT ICON when show edit begins  */}
    {(movieSelected && internetOrderAll) || (movieSelected && internetSellAll) || (movieSelected && internetShowAll) 
    || (movieSelected && ticketSellAll) || (movieSelected && ticketOrderAll)  ? 
     <img style={{position:'absolute',right:'-5px',top:'17px'}} src={alertIcon} alt='alert-icon' /> : ''
       } 
        
       
    </div>  
    <div id='filmInfoDiv' style={{position:'relative', zIndex:8, backgroundColor: isShown ? extendedBackColor : '',marginTop:isShown ? '-2px' : '', borderBottomLeftRadius:'4px', borderBottomRightRadius:'4px'}}>
    { isShown &&
          <main >
           <div style={{height:'111px',paddingLeft: theWidth > 180 ? '10px' :'0px'}}>
            <p style={{margin:0,paddingTop:'0px',color:'#46484c', fontSize : theWidth < 180 ? '13px' : ''}}> <span style={{fontWeight:'bold',paddingRight:'10px'}}>{salNr}</span>{start_Time} - {end_Time}</p>
            
            <div style={{paddingTop:'10px'}}>

               <div onClick={handleInternetOrder} style={{display:'flex',marginTop:'0px',border:'1px ',height:'13px',marginBottom:'3px'}}>
               <img  src= { internetOrder || (movieSelected && internetOrderAll) ?  small_checkmark: small_square }  alt=""  />
                 <p style={{fontSize:'12px',color:'#46484c',margin:'-4px 8px',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>Internetbestilling </p>
                {/* {internetOrder ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> } */}

               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'13px',marginBottom:'3px',border:'1px '}} onClick={handleInternetSell}>
               <img  src= { internetSell || (movieSelected && internetSellAll) ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',margin:'-4px 8px',color:'#46484c',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>Internet salg</p>
                 {/* {internetSell ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> } */}
               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'13px',marginBottom:'3px',border:'1px '}} onClick={handleInternetShow}>
               <img  src= { internetShow || (movieSelected && internetShowAll) ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',margin:'-4px 8px',color:'#46484c',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>Intervisning</p>
                 {/* {internetShow  ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> } */}
               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'13px',marginBottom:'3px',border:'1px'}} onClick={handleTicketSellSell}>
               <img  src= { ticketSellSell || (movieSelected && ticketSellAll) ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',margin:'-4px 8px',color:'#46484c',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>Salg i billetsalg</p>
                 {/* {ticketSellSell ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> } */}
               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'13px',marginBottom:'3px',border:'1px '}} onClick={handleTicketSellOrder}>
               <img  src= { ticketSellOrder || (movieSelected && ticketOrderAll) ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',margin:'-4px 8px',color:'#46484c',textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap'}}>Bestilling i billetsalg</p>
                 {/* {ticketSellOrder ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> } */}
               </div>

            </div>
         </div>
         <div style={{display: theWidth > 180 ? 'grid' : 'flex',gap:'1px', flexDirection :  theWidth < 180 ? 'column' : '',
         gridTemplateColumns:'auto 76px',margin:'15px 4px 13px',color:'#46484c'}}>
           <div style={{backgroundColor:priceDivBackColor,paddingLeft:'5px',borderTopLeftRadius:'5px',borderBottomLeftRadius:'5px'}}>
            <p  style={{fontSize:'12px', margin:0, padding:0,fontWeight:'bold'}}>{price} dkk</p>
            <p  style={{fontSize:'12px', margin:0, padding:0}}>{arrtypename}</p>
            <p className='text_elip'  style={{fontSize:'12px', margin:0, padding:0}}>Billettype: 5 valgte</p>
           </div>
           
           <div style={{backgroundColor:priceDivBackColor,textAlign:'center',borderTopRightRadius:'5px',borderBottomRightRadius:'5px'}}>
            <button style={{border:'none',background:'transparent',textDecoration:'underline',fontFamily:'lato',color:'#46484c',fontSize:'12px',lineHeight:theWidth > 180 ?'50px' : ''}} onClick={() => {
                          toggleModal(!modalShown);
                      }}>REDIGER</button>
           </div>
           <Modal
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
       movie_name={film} sal_nr={salNr} start_time={start_Time} end_time={end_Time}
      >   
      </Modal>
         </div>
          
          <div style={{height:'20px',borderRadius:'4px',backgroundColor:timelineBackColor,padding:0,margin:'5px'}}>12:00</div>
      
          </main>
           }
    </div>
     </div>
      
  )
}

export default FilmWidget