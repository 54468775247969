import React from "react";
import { useContext, useState , useEffect, useRef, useLayoutEffect} from "react";
import { keyContext } from "./KeyContext";
import moment from "moment";
import '../components/css/daytable.css'
import ScrollToTop from "react-scroll-to-top";
import arrows from '../images/arrow-down.svg'
import whiteArror from '../images/white-arrow.svg';
import whiteCalendar from '../images/whiteCalendar.svg'
import { tr } from "date-fns/locale";

function DayTable() {
  const { dagsData } = useContext(keyContext);
  let burgermenu = localStorage.getItem('burgerMenuChecked')
  const theme = localStorage.getItem('theme')

let arr = []
  dagsData && dagsData.filter((i) => arr.push(i.ArrTime.slice(0, 10)));

  //find duplicates
  function toFindDuplicates(arr) {
    const uniqueElements = new Set(arr);
    
    arr.filter((item) => {
      if (uniqueElements.has(item)) {
        
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }

  const duplicateElements = toFindDuplicates(arr);

  var days = [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
  ];

  let forsCount = 0;
  let Reserveret = 0;
  let solgte = 0;
  let total = 0;
  let totalSeat = 0;
  let oms = 0;
//  let tid ='';

  function FindSumRes(filmname, startTime, endTime) {
    let res = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        res = res + item.TotalResCount;
      }
    });
    return res;
  }
  function FindSumSold(filmname, startTime, endTime) {
    let sold = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        sold = sold + item.TotalSoldCount;
      }
    });
    return sold;
  }

  function FindSumOms(filmname, startTime, endTime) {
    let oms = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        oms = oms + item.TotalSoldAmount;
      }
    });
    return oms;
  }
  function FindSumTotal(filmname, startTime, endTime) {
    let total = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        total = total + item.TotalResCount + item.TotalSoldCount;
      }
    });
    return total;
  }

  function totalSeatCount(filmname, startTime, endTime) {
    let total = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        total = total + item.TotalSeatCount;
      }
    });
    return total;
  }

  function findBel(filmname, startTime, endTime) {
    let tot = 0;
    let res = 0;
    let sold = 0;
    let seat = 0;
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        res = res + item.TotalResCount;
      }
    });
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        sold = sold + item.TotalSoldCount;
      }
    });
    dagsData.map((item) => {
      if (
        item.HomeTeamName === filmname &&
        moment(item.ArrTime.slice(11, 16), "hh:mm").isBetween(
          startTime,
          endTime
        )
      ) {
        seat = seat + item.TotalSeatCount;
      }
    });
    tot = Math.round(((res + sold) * 100) / seat);
    return tot;

    
  }

  //formiddag forestillinger
  let startTim = moment("9:00", "hh:mm");
  let endTim = moment("11:59", "hh:mm");
  let fr = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(startTim, endTim)) {
        fr.push(i);
      }
    });
 
  let farr = [];
  fr && fr.filter((i) => farr.push(i.HomeTeamName));

  function FilmDuplicates(farr) {
    const uniqueElements = new Set(farr);

    farr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicatefar = FilmDuplicates(farr);

  let formiddag = [];
  for (let index = 0; index < duplicatefar.length; index++) {
    formiddag.push({
      id: index,
      name: duplicatefar[index],
      sumRes: FindSumRes(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
      belag: findBel(
        duplicatefar[index],
        moment("9:00", "hh:mm"),
        moment("11:59", "hh:mm")
      ),
    });
  }

  // 1 eftermiddag forestillinger
  let starTime = moment("11:59", "hh:mm");
  let endime = moment("14:59", "hh:mm");
  let er = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(starTime, endime)) {
        er.push(i);
      }
    });
 
  let err = [];
  er && er.filter((i) => err.push(i.HomeTeamName));

  function FilmDuplicates(err) {
    const uniqueElements = new Set(err);

    err.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateerr = FilmDuplicates(err);
// console.log(duplicateear)
  let firstEftermiddag = [];
  for (let index = 0; index < duplicateerr.length; index++) {
    firstEftermiddag.push({
      id: index,
      name: duplicateerr[index],
      // tid: index === 0 ? 'eftermidaf' : '',
      sumRes: FindSumRes(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
      belag: findBel(
        duplicateerr[index],
        moment("11:59", "hh:mm"),
        moment("14:59", "hh:mm")
      ),
    });
  }
 
  // 2 eftermiddag forestillinger
  let startTime = moment("14:59", "hh:mm");
  let endTime = moment("17:00", "hh:mm");
  let ar = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(startTime, endTime)) {
        ar.push(i);
      }
    });

  let earr = [];
  ar && ar.filter((i) => earr.push(i.HomeTeamName));

  function FilmDuplicates(earr) {
    const uniqueElements = new Set(earr);

    earr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateear = FilmDuplicates(earr);
  // below are the 5 functions which sums reservation, belæg, oms, sold and total

  let andenEftermiddag = [];
  for (let index = 0; index < duplicateear.length; index++) {
    andenEftermiddag.push({
      id: index,
      name: duplicateear[index],
      sumRes: FindSumRes(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:00", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:00", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:00", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:00", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:00", "hh:mm")
      ),
      belag: findBel(
        duplicateear[index],
        moment("14:59", "hh:mm"),
        moment("17:00", "hh:mm")
      ),
    });
  }
 

  // 1 aften forestillinger
  let startT = moment("17:00", "hh:mm");
  let endT = moment("19:45", "hh:mm");
  let afr = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(startT, endT)) {
        afr.push(i);
      }
    });
 
  let afrr = [];
  afr && afr.filter((i) => afrr.push(i.HomeTeamName));

  function FilmDuplicates(afrr) {
    const uniqueElements = new Set(afrr);

    afrr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateafr = FilmDuplicates(afrr);

  let firstAften = [];
  for (let index = 0; index < duplicateafr.length; index++) {
    firstAften.push({
      id: index,
      name: duplicateafr[index],
      sumRes: FindSumRes(
        duplicateafr[index],
        moment("17:00", "hh:mm"),
        moment("19:45", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateafr[index],
        moment("17:00", "hh:mm"),
        moment("19:45", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateafr[index],
        moment("17:00", "hh:mm"),
        moment("19:45", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateafr[index],
        moment("17:00", "hh:mm"),
        moment("19:45", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateafr[index],
        moment("17:00", "hh:mm"),
        moment("19:45", "hh:mm")
      ),
      belag: findBel(
        duplicateafr[index],
        moment("17:00", "hh:mm"),
        moment("19:45", "hh:mm")
      ),
    });
  }


  // 2 aften forestiliinger
  let start = moment("19:45", "hh:mm");
  let end = moment("24:00", "hh:mm");
  let af = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(start, end)) {
        af.push(i);
      }
    });
  // console.log(af, 'af')
  let afarr = [];
  af && af.filter((i) => afarr.push(i.HomeTeamName));

  function FilmDuplicates(afarr) {
    const uniqueElements = new Set(afarr);

    afarr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateaften = FilmDuplicates(afarr);
// console.log(duplicateaften, 'dup aften')
  let andenAften = [];
  for (let index = 0; index < duplicateaften.length; index++) {
    andenAften.push({
      id: index,
      name: duplicateaften[index],
      sumRes: FindSumRes(
        duplicateaften[index],
        moment("19:45", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateaften[index],
        moment("19:45", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateaften[index],
        moment("19:45", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateaften[index],
        moment("19:45", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateaften[index],
        moment("19:45", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
      belag: findBel(
        duplicateaften[index],
        moment("19:45", "hh:mm"),
        moment("24:00", "hh:mm")
      ),
    });
  }

  // nat forestillinger
  let star = moment("00:00", "hh:mm");
  let ende = moment("05:00", "hh:mm");
  let nf = [];
  dagsData &&
    dagsData.map((i) => {
      let time = moment(i.ArrTime.slice(11, 16), "hh:mm");

      if (time.isBetween(star, ende)) {
        nf.push(i);
      }
    });

  let narr = [];
  nf && nf.filter((i) => narr.push(i.HomeTeamName));

  function FilmDuplicates(narr) {
    const uniqueElements = new Set(narr);

    narr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateanat = FilmDuplicates(narr);

  let nat = [];
  for (let index = 0; index < duplicateanat.length; index++) {
    nat.push({
      id: index,
      name: duplicateanat[index],
      sumRes: FindSumRes(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumSold: FindSumSold(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumTotal: FindSumTotal(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumOms: FindSumOms(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      sumSeatCount: totalSeatCount(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
      belag: findBel(
        duplicateanat[index],
        moment("00:00", "hh:mm"),
        moment("05:00", "hh:mm")
      ),
    });
  }

  // FINDING REPEATED FILM NAMES
  let filmArr = [];
  dagsData && dagsData.filter((i) => filmArr.push(i.HomeTeamName));

  function FilmDuplicates(filmArr) {
    const uniqueElements = new Set(filmArr);

    filmArr.filter((item) => {
      if (uniqueElements.has(item)) {
      } else {
        return item;
      }
    });

    return [...new Set(uniqueElements)];
  }
  const duplicateFilm = FilmDuplicates(filmArr);
 
// console.log(duplicateFilm)
  let resSum = 0;
  let soldSum = 0;
  let totalSum = 0;
  let omsSum = 0;
  let belSum = 0;
  let totalBelSum = 0;
  let filmCount = 0;
  let totalBelagSum = 0;

  

  //sorting of columns in first eftermiddag  periode
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    function requestSort(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort } = useSortableData(firstEftermiddag);

//  console.log(formiddag, 'formiddags for')
  //sorting of columns in formiddag  periode
  const useSortableDataFor = (itemsFor, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsFor];
      
        sortableItems.sort((a,b) => 
        a.sumTotal > b.sumTotal ? -1 : 1
      )

      if (sortConfig !== null) {
        
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          
          return 0;
          
        });
       
      }
      

      return sortableItems;
    }, [itemsFor, sortConfig]);

    function requestSortFor(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsFor: sortedItems, requestSortFor, sortConfig };
  };

  const { itemsFor, requestSortFor } = useSortableDataFor(formiddag);
  


  // sorting of columns in  anden eftermiddag periode
  const useSortableDataAnden = (itemsAnden, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAnden];

      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAnden, sortConfig]);

    function requestSortAnden(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAnden: sortedItems, requestSortAnden, sortConfig };
  };

  const { itemsAnden, requestSortAnden } =
    useSortableDataAnden(andenEftermiddag);

  // sorting of columns in først aften  periode
  const useSortableDataAften = (itemsAften, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAften];

      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAften, sortConfig]);

    function requestSortAften(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAften: sortedItems, requestSortAften, sortConfig };
  };

  const { itemsAften, requestSortAften } = useSortableDataAften(firstAften);

  // sorting of columns in anden aften  periode
  const useSortableDataAften2 = (itemsAften2, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAften2];

      sortableItems.sort((a,b) => 
      a.sumTotal > b.sumTotal ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAften2, sortConfig]);

    function requestSortAften2(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAften2: sortedItems, requestSortAften2, sortConfig };
  };

  const { itemsAften2, requestSortAften2 } = useSortableDataAften2(andenAften);
// console.log(itemsAften2)
  // sorting of columns in nat  periode
  const useSortableDataNat = (itemsNat, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsNat];

      sortableItems.sort((a,b) => 
        a.sumOms > b.sumOms ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsNat, sortConfig]);

    function requestSortNat(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsNat: sortedItems, requestSortNat, sortConfig };
  };

  const { itemsNat, requestSortNat } = useSortableDataNat(nat);

  
  //enkelte dags formiddag fors.
  let strt = moment("9:00", "hh:mm");
  let endt = moment("11:59", "hh:mm");
  let ef = [];

 dagsData&& dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(strt, endt))
        ef.push(i);
    });
  });

  let filmsPerPeriode = [];

  for (let index = 0; index < ef.length; index++) {
    filmsPerPeriode.push({
      id: index,
      date: ef[index].ArrTime.slice(0, 10),
      name: ef[index].HomeTeamName,
      sal: ef[index].LocName,
      starTime: ef[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(ef[index].ArrTime.slice(11, 13))
        .minute(ef[index].ArrTime.slice(14, 16))
        .add(Math.floor(ef[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (ef[index].DurationSecs -
              Math.floor(ef[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: ef[index].TotalSoldAmount,
      res: ef[index].TotalResCount,
      sold: ef[index].TotalSoldCount,
      total: ef[index].TotalResCount + ef[index].TotalSoldCount,
      seatCount: ef[index].TotalSeatCount,
      bel: Math.round(
        ((ef[index].TotalResCount + ef[index].TotalSoldCount) * 100) /
          ef[index].TotalSeatCount
      ),
    });
  }

  //enkelte dags 1 eftermiddag forstillinger
  let strT = moment("11:59", "hh:mm");
  let enT = moment("14:59", "hh:mm");
  let efa = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(strT, enT))
        efa.push(i);
    });
  });

  let firstEFterFors = [];
  for (let index = 0; index < efa.length; index++) {
    firstEFterFors.push({
      id: index,
      date: efa[index].ArrTime.slice(0, 10),
      name: efa[index].HomeTeamName,
      sal: efa[index].LocName,
      starTime: efa[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(efa[index].ArrTime.slice(11, 13))
        .minute(efa[index].ArrTime.slice(14, 16))
        .add(Math.floor(efa[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (efa[index].DurationSecs -
              Math.floor(efa[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: efa[index].TotalSoldAmount,
      res: efa[index].TotalResCount,
      sold: efa[index].TotalSoldCount,
      total: efa[index].TotalResCount + efa[index].TotalSoldCount,
      seatCount: efa[index].TotalSeatCount,
      bel: Math.round(
        ((efa[index].TotalResCount + efa[index].TotalSoldCount) * 100) /
          efa[index].TotalSeatCount
      ),
    });
  }
  // enkelte dags 2 eftermiddag forstilinger
  let stTime = moment("14:59", "hh:mm");
  let enTime = moment("17:00", "hh:mm");
  let aef = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTime, enTime))
        aef.push(i);
    });
  });

  let andenEFterFors = [];
  for (let index = 0; index < aef.length; index++) {
    andenEFterFors.push({
      id: index,
      date: aef[index].ArrTime.slice(0, 10),
      name: aef[index].HomeTeamName,
      sal: aef[index].LocName,
      starTime: aef[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(aef[index].ArrTime.slice(11, 13))
        .minute(aef[index].ArrTime.slice(14, 16))
        .add(Math.floor(aef[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (aef[index].DurationSecs -
              Math.floor(aef[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: aef[index].TotalSoldAmount,
      res: aef[index].TotalResCount,
      sold: aef[index].TotalSoldCount,
      total: aef[index].TotalResCount + aef[index].TotalSoldCount,
      seatCount: aef[index].TotalSeatCount,
      bel: Math.round(
        ((aef[index].TotalResCount + aef[index].TotalSoldCount) * 100) /
          aef[index].TotalSeatCount
      ),
    });
  }
  
  // eneklt dags first aften forstllinger
  let stTim = moment("17:00", "hh:mm");
  let enTim = moment("19:45", "hh:mm");
  let faf = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTim, enTim))
        faf.push(i);
    });
  });

  let firstAftenFors = [];
  for (let index = 0; index < faf.length; index++) {
    firstAftenFors.push({
      id: index,
      date: faf[index].ArrTime.slice(0, 10),
      name: faf[index].HomeTeamName,
      sal: faf[index].LocName,
      starTime: faf[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(faf[index].ArrTime.slice(11, 13))
        .minute(faf[index].ArrTime.slice(14, 16))
        .add(Math.floor(faf[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (faf[index].DurationSecs -
              Math.floor(faf[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: faf[index].TotalSoldAmount,
      res: faf[index].TotalResCount,
      sold: faf[index].TotalSoldCount,
      total: faf[index].TotalResCount + faf[index].TotalSoldCount,
      seatCount: faf[index].TotalSeatCount,
      bel: Math.round(
        ((faf[index].TotalResCount + faf[index].TotalSoldCount) * 100) /
          faf[index].TotalSeatCount
      ),
    });
  }
  // enkelte dags anden aften fors.
  let stTm = moment("19:45", "hh:mm");
  let enTm = moment("24:00", "hh:mm");
  let aaf = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTm, enTm))
        aaf.push(i);
    });
  });

  let andenAftenFors = [];
  for (let index = 0; index < aaf.length; index++) {
    andenAftenFors.push({
      id: index,
      date: aaf[index].ArrTime.slice(0, 10),
      name: aaf[index].HomeTeamName,
      sal: aaf[index].LocName,
      starTime: aaf[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(aaf[index].ArrTime.slice(11, 13))
        .minute(aaf[index].ArrTime.slice(14, 16))
        .add(Math.floor(aaf[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (aaf[index].DurationSecs -
              Math.floor(aaf[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: aaf[index].TotalSoldAmount,
      res: aaf[index].TotalResCount,
      sold: aaf[index].TotalSoldCount,
      total: aaf[index].TotalResCount + aaf[index].TotalSoldCount,
      seatCount: aaf[index].TotalSeatCount,
      bel: Math.round(
        ((aaf[index].TotalResCount + aaf[index].TotalSoldCount) * 100) /
          aaf[index].TotalSeatCount
      ),
    });
  }
 
  // enkelte dags nat fors.
  let stTme = moment("00:00", "hh:mm");
  let enTme = moment("05:00", "hh:mm");
  let naf = [];

  dagsData.map((i) => {
    let time = moment(i.ArrTime.slice(11, 16), "hh:mm");
    duplicateElements.map((date) => {
      if (i.ArrTime.slice(0, 10) === date && time.isBetween(stTme, enTme))
        naf.push(i);
    });
  });

  let natFors = [];
  for (let index = 0; index < naf.length; index++) {
    natFors.push({
      id: index,
      date: naf[index].ArrTime.slice(0, 10),
      name: naf[index].HomeTeamName,
      sal: naf[index].LocName,
      starTime: naf[index].ArrTime.slice(11, 16),
      endT: moment()
        .hour(naf[index].ArrTime.slice(11, 13))
        .minute(naf[index].ArrTime.slice(14, 16))
        .add(Math.floor(naf[index].DurationSecs / 3600), "hours")
        .add(
          Math.floor(
            (naf[index].DurationSecs -
              Math.floor(naf[index].DurationSecs / 3600) * 3600) /
              60
          ),
          "minutes"
        )
        .format("HH:mm"),
      oms: naf[index].TotalSoldAmount,
      res: naf[index].TotalResCount,
      sold: naf[index].TotalSoldCount,
      total: naf[index].TotalResCount + naf[index].TotalSoldCount,
      seatCount: naf[index].TotalSeatCount,
      bel: Math.round(
        ((naf[index].TotalResCount + naf[index].TotalSoldCount) * 100) /
          naf[index].TotalSeatCount
      ),
    });
  }

  // sorting af formiddag forstiliinger per enkelte dag
  const useSortSingleDayFormiddag = (itemsforDay, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsforDay];
      sortableItems.sort((a,b) => 
        a.total > b.total ? -1 : 1
      );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsforDay, sortConfig]);

    function requestSortForDay(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsforDay: sortedItems, requestSortForDay, sortConfig };
  };

  const { itemsforDay, requestSortForDay } =
    useSortSingleDayFormiddag(filmsPerPeriode);

  //sorting of first eftermiddag forstiliinger per enkelte dag
  const useSortSingleDayFirstEF = (itemsFirstEfDay, config = null) => {
   
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsFirstEfDay];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1
    );
      if (sortConfig !== null) {
        
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsFirstEfDay, sortConfig]);

    function requestSortFirstEfDay(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsFirstEfDay: sortedItems, requestSortFirstEfDay, sortConfig };
  };
// console.log(itemsFirstEfDay)
  const { itemsFirstEfDay, requestSortFirstEfDay } =
    useSortSingleDayFirstEF(firstEFterFors);

  // sorting of 2 eftermiddag fors. per enkelte dag
  const useSortSingleDayAndenEF = (itemsAndenEfDay, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAndenEfDay];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1

    );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAndenEfDay, sortConfig]);

    function requestSortAndenEfDay(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAndenEfDay: sortedItems, requestSortAndenEfDay, sortConfig };
  };

  const { itemsAndenEfDay, requestSortAndenEfDay } =
    useSortSingleDayAndenEF(andenEFterFors);

  // sorting of 1 aften fors. per enkelte dag
  const useSortSingleDayFirstAften = (itemsFirstAften, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsFirstAften];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1

    );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsFirstAften, sortConfig]);

    function requestSortFirstAften(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsFirstAften: sortedItems, requestSortFirstAften, sortConfig };
  };

  const { itemsFirstAften, requestSortFirstAften } =
    useSortSingleDayFirstAften(firstAftenFors);

  // sorting of 2 aften fors. per enkelte dag
  const useSortSingleDayAndenAften = (itemsAndenAften, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemsAndenAften];
      sortableItems.sort((a,b) => 
      a.total > b.total ? -1 : 1

    );
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemsAndenAften, sortConfig]);

    function requestSortAndenAften(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemsAndenAften: sortedItems, requestSortAndenAften, sortConfig };
  };

  const { itemsAndenAften, requestSortAndenAften } =
    useSortSingleDayAndenAften(andenAftenFors);
// console.log(itemsAndenAften)
  // sorting of nat fors. per enkelte dag
  const useSortSingleDayNat = (itemNat, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...itemNat];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [itemNat, sortConfig]);

    function requestSortNatFors(key) {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    }

    return { itemNat: sortedItems, requestSortNatFors, sortConfig };
  };

  const { itemNat, requestSortNatFors } = useSortSingleDayNat(natFors);

  // variabler til summering af perioden per period
  // til first eftermiddags period
  let resA = 0;
  let soldA = 0;
  let belA = 0;
  let totalA = 0;
  let totalBelA = 0;
  let omsA = 0;
 // til anden eftermiddags period
  let resB = 0;
  let soldB = 0;
  let belB = 0;
  let totalB = 0;
  let totalBelB = 0;
  let omsB = 0;
 //til first aftens period
  let resC = 0;
  let soldC = 0;
  let belC = 0;
  let totalC = 0;
  let totalBelC = 0;
  let omsC = 0;
 //til anden aftens period
  let resD = 0;
  let soldD = 0;
  let belD = 0;
  let totalD = 0;
  let totalBelD = 0;
  let omsD = 0;
 //til nat period
  let resE = 0;
  let soldE = 0;
  let belE = 0;
  let totalE = 0;
  let totalBelE = 0;
  let omsE = 0;

  // new dagsperiod layout style
  const [isShown, setIsShown] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
  
    setIsShown((current) => !current);
   
    setIsActive(current => !current);
   
  };
//  console.log(items)
//  useEffect(() => {
  const [test, setTest] = useState('');
// console.log(test)
 let reserv =dagsData&& dagsData.map((i) => i.TotalResCount)
 let soldcount = dagsData && dagsData.map((i) => i.TotalSoldCount)
 let seatcount = dagsData && dagsData.map((i) => i.TotalSeatCount)
 let soldamount = dagsData && dagsData.map((i) => i.TotalSoldAmount)
//  console.log(soldcount)
//  }, []);
let sumOfRes = 0
let sumOfSold = 0
let sumOfSeat = 0
let sumOfAmount = 0
  for ( let i=0; i < reserv.length; i++){
    sumOfRes += reserv[i]
  }
  for ( let i=0; i < soldcount.length; i++){
    sumOfSold += soldcount[i]
  }
  for ( let i=0; i < seatcount.length; i++){
    sumOfSeat += seatcount[i]
  }
  for ( let i=0; i < soldamount.length; i++){
    sumOfAmount += soldamount[i]
  }



let formiddagCount = 0;
let firstEFCount = 0;
let secondEFCount = 0;
let firstAftenCount = 0;
let secondAftenCount = 0;
let natCount = 0;


  return dagsData && dagsData.length ? (
    <>
    
    <table className="table" style={{border:'1px solid transparent', margin:0,marginTop:'40px'}}>
    <thead>
        <tr>
          <td style={{fontSize:'18px', fontWeight:'bold',paddingLeft:0,letterSpacing:'0.36px',opacity: theme === 'dark' ? '0.6':'',paddingBottom:'5px'}}>Summerede film i perioden</td>
          <td style={{width:burgermenu === 'true' ? '485px' : '250px'}}></td>
          <td style={{color: theme === 'dark' ? '#fff' : '#8d8d8d', opacity: theme === 'dark' ? '0.6' : '',paddingBottom:'5px',letterSpacing:'0.36px'}}>Reservationer</td>
          <td style={{color: theme === 'dark' ? '#fff' : '#8d8d8d', opacity: theme === 'dark' ? '0.6' : '',paddingBottom:'5px',letterSpacing:'0.36px'}}> Solgte</td>
          <td style={{color: theme === 'dark' ? '#fff' : '#8d8d8d', opacity: theme === 'dark' ? '0.6' : '',paddingBottom:'5px',letterSpacing:'0.36px'}}>Total</td>
          <td style={{color: theme === 'dark' ? '#fff' : '#8d8d8d', opacity: theme === 'dark' ? '0.6' : '',paddingBottom:'5px',letterSpacing:'0.36px'}}>Belægning</td>
          <td style={{color: theme === 'dark' ? '#fff' : '#8d8d8d', opacity: theme === 'dark' ? '0.6' : '',paddingBottom:'5px',letterSpacing:'0.36px'}}>Omsætning</td>
          <td style={{width:'55px'}}></td>
        </tr>
      </thead>
    </table>
    <table className="table responsive day_table" style={{color:'#46484c',letterSpacing:'0.3px'}}>
    
      <thead className="" style={{border:'1px solid #6c6c6c', borderRadius:'10px',height:'51px', }}>
        <tr >
          <td style={{fontWeight:600,fontSize:'15px',padding:0}}><p style={{paddingLeft:'14px',color: theme === 'dark' ? 'white' : '',height:'20px'}}> {duplicateFilm.length} film i peioden </p></td>
          <td style={{}}></td>
          <td style={{textAlign:'right',width:'80px',backgroundColor:'',padding:0}}><p style= {{color:theme === 'dark' ? 'white' : '',backgroundColor: theme === 'dark' ?  '' : '#f7f7f7',paddingBottom:'-9px',paddingRight:'8px',height:'20px'}}>{sumOfRes}</p></td>
          <td style={{textAlign:'right',width:'80px',backgroundColor:'',padding:0}}><p style= {{color:theme === 'dark' ? 'white' : '',backgroundColor: theme === 'dark' ?  '' : '#f7f7f7',marginTop:0,paddingRight:'8px',height:'20px'}}>{sumOfSold}</p></td>
          <td style={{textAlign:'right',width:'70px',backgroundColor:'',padding:0}}><p style= {{color:theme === 'dark' ? 'white' : '',backgroundColor: theme === 'dark' ?  '' : '#f7f7f7',marginTop:0,paddingRight:'8px',height:'20px'}}>{sumOfRes+sumOfSold}</p></td>
          <td style={{textAlign:'right', width:'70px',backgroundColor:'',padding:0}}><p style={{color:theme === 'dark' ? 'white' : '',backgroundColor: theme === 'dark' ?  '' : '#f7f7f7',marginTop:0,paddingRight:'8px',height:'20px'}}>{Math.round(((sumOfRes+sumOfSold)*100)/sumOfSeat)}%</p></td>
          <td style={{textAlign:'right',width:'130px', paddingRight:'30px',backgroundColor:'',padding:'0px 10px 0px 0px'}}><p style={{color:theme === 'dark' ? 'white' : '',backgroundColor: theme === 'dark' ?  '' : '#f7f7f7',marginTop:0,paddingRight:'25px',height:'20px'}}>{Math.round(sumOfAmount).toLocaleString('da')}</p></td>
          <td style={{borderLeft: theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',width:'55px',textAlign:'center',padding:0}}> <button  style={{border:'none ' ,}}onClick={() => handleClick()}>
          <p style={{paddingTop: isActive? '0px' : '',}} className={isActive ? "rotated" : ""}>
            <img style={{position: isActive ? 'absolute':'',right: isActive ? '-8px': '', top:isActive ? '-5px':''}} src={ theme === 'dark' ? whiteArror :arrows} alt="" /> </p>
        </button></td>
        </tr>
      </thead>
      
      {isShown && < >

        {itemsFor.length ?   <tbody className="formatted_table tbody_space" style={{borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3'}}>
        {itemsFor.map((i, index) => {
              filmCount = filmCount + 1;
              resSum = resSum + i.sumRes;
              soldSum = soldSum + i.sumSold;
              totalSum = totalSum + i.sumTotal;
              omsSum = omsSum + i.sumOms;
              belSum = belSum + i.sumBel;
              totalBelSum = totalBelSum + i.sumSeatCount;
              totalBelagSum = Math.round(
                ((i.sumRes + i.sumSold) * 100) / i.sumSeatCount
              );
              return <tr style={{backgroundColor:theme === 'dark' ? '#1b5ba2' : 'rgba(169, 229, 255, .5)',color: theme === 'dark' ? 'white' : ''}}>
                <td style={{paddingLeft:'4px'}}>{ index === 0 ? 'Formiddag' : ''}</td>
                <td style={{}}>{i.name}</td>
                <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumRes}</td>
                <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumSold}</td>
                <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumTotal}</td>
                <td style={{textAlign:'right',paddingRight:'8px'}}>{i.belag}%</td>
                <td style={{textAlign:'right',paddingRight:'30px'}}>{Math.round(i.sumOms).toLocaleString('da')}</td>
              </tr>
        })}
        <tr style={{backgroundColor: theme === 'dark' ? 'rgba(27, 91, 162, .85)' : 'rgba(169, 229, 255, .8)',fontWeight:'bold',color: theme === 'dark' ? 'white' : ''}}>
          <td style={{padding:'4.25px'}}> {filmCount} film i perioden</td>
          <td></td>
          <td  style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{resSum}</td>
          <td  style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{soldSum}</td>
          <td  style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{totalSum}</td>
          <td  style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{Math.round((totalSum * 100) / totalBelSum)}%</td>
          <td style={{textAlign:'right',paddingRight:'30px',paddingTop:'3px'}}>{Math.round(omsSum).toLocaleString('da')}</td>
        </tr>
      </tbody> : ''
}

      <tbody className={"formatted_table " + `${itemsFor.length ? '' : 'tbody_space'}`} style={{borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3'}} >
     { items    
        .map((i,index) => {
          filmCount = filmCount + 1;
         resA = resA + i.sumRes;
         soldA = soldA + i.sumSold;
         totalA = totalA + i.sumTotal;
         omsA = omsA + i.sumOms;
         totalBelA = totalBelA + i.sumSeatCount;
         belA = Math.round(((resA + soldA) * 100) / totalBelA );
          resSum = resSum + i.sumRes;
          soldSum = soldSum + i.sumSold;
          totalSum = totalSum + i.sumTotal;
          omsSum = omsSum + i.sumOms;
          belSum = belSum + i.sumBel;
          totalBelSum = totalBelSum + i.sumSeatCount;
          totalBelagSum = Math.round(
            ((i.sumRes + i.sumSold) * 100) / i.sumSeatCount
          );
          return <tr style={{backgroundColor: theme === 'dark' ?'#dfbd73' :'rgba(255, 155, 47, .5',color:theme === 'dark' ? '#101010' : ''}}>
            <td style={{paddingLeft:'4px'}}>{ index === 0 ? '1. Eftermiddag' : ''}</td>
            <td>{i.name}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumRes}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumSold}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumTotal}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.belag}%</td>
            <td style={{textAlign:'right',paddingRight:'30px'}}>{Math.round(i.sumOms).toLocaleString('da')}</td>
          </tr>
       })}
       <tr style={{backgroundColor:theme === 'dark' ?'#dfbd73' :'rgba(255, 155, 47, .65',fontWeight:'bold',opacity: theme === 'dark' ? '0.85' : '',color:theme === 'dark' ? '#101010' : ''}}>
        <td style={{padding:'4.25px'}}>{items.length} film i perioden</td>
        <td></td>
        <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{resA}</td>
        <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{soldA}</td>
        <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{totalA}</td>
        <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{belA}%</td>
        <td style={{textAlign:'right',paddingRight:'30px',paddingTop:'3px'}}>{Math.round(omsA).toLocaleString('da')}</td>
       </tr>
      </tbody>
      
      <tbody  className="formatted_table" style={{borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3'}}>
{
  itemsAnden    
  .map((i,index) => {
    filmCount = filmCount + 1;
    resB = resB + i.sumRes;
   soldB = soldB + i.sumSold;
   totalB = totalB + i.sumTotal;
   omsB = omsB + i.sumOms;
   totalBelB = totalBelB + i.sumSeatCount;
   belB = Math.round(((resB + soldB) * 100) / totalBelB );
    resSum = resSum + i.sumRes;
    soldSum = soldSum + i.sumSold;
    totalSum = totalSum + i.sumTotal;
    omsSum = omsSum + i.sumOms;
    belSum = belSum + i.sumBel;
    totalBelSum = totalBelSum + i.sumSeatCount;
    return <tr style={{backgroundColor:theme === 'dark' ? '#286465':'rgba(219, 231, 84, .5)',color:theme === 'dark' ? 'white' : ''}}>
         <td style={{paddingLeft:'4px'}}>{ index === 0 ? '2. Eftermiddag' : ''}</td>
         <td>{i.name}</td>
         <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumRes}</td>
         <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumSold}</td>
         <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumTotal}</td>
         <td style={{textAlign:'right',paddingRight:'8px'}}>{i.belag}%</td>
         <td style={{textAlign:'right',paddingRight:'30px'}}>{Math.round(i.sumOms).toLocaleString('da')}</td>
    </tr>
  })}
<tr style={{backgroundColor:theme === 'dark' ? '#286465':'rgba(219, 231, 84, .75)',fontWeight:'bold',color:theme === 'dark' ? 'white' : '',opacity:theme === 'dark' ? '0.85' : ''}}>
      <td style={{padding:'4.25px'}}>{itemsAnden.length} film i perioden</td>
      <td></td>
      <td style={{textAlign:'right',paddingRight:'8px', paddingTop:'3px'}}>{resB}</td>
      <td style={{textAlign:'right',paddingRight:'8px', paddingTop:'3px'}}>{soldB}</td>
      <td style={{textAlign:'right',paddingRight:'8px', paddingTop:'3px'}}>{totalB}</td>
      <td style={{textAlign:'right',paddingRight:'8px', paddingTop:'3px'}}>{belB}%</td>
      <td style={{textAlign:'right',paddingRight:'30px',paddingTop:'3px'}}>{Math.round(omsB).toLocaleString('da')}</td>
</tr>
</tbody>
<tbody className="formatted_table" style={{borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3'}}>
{itemsAften    
        .map((i,index) => {
          filmCount = filmCount + 1;
          resC = resC + i.sumRes;
         soldC = soldC + i.sumSold;
         totalC = totalC + i.sumTotal;
         omsC = omsC + i.sumOms;
         totalBelC = totalBelC + i.sumSeatCount;
         belC = Math.round(((resC + soldC) * 100) / totalBelC );
          resSum = resSum + i.sumRes;
          soldSum = soldSum + i.sumSold;
          totalSum = totalSum + i.sumTotal;
          omsSum = omsSum + i.sumOms;
          belSum = belSum + i.sumBel;
          totalBelSum = totalBelSum + i.sumSeatCount;
          return <tr style={{backgroundColor:theme === 'dark' ? '#9b9eee' :'rgba(173, 176, 255, .5)',color:theme === 'dark' ? '#101010' : ''}}>
            <td style={{paddingLeft:'4px'}}>{index === 0 ? '1. Aften' : ''}</td>
            <td>{i.name}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumRes}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumSold}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumTotal}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.belag}%</td>
            <td style={{textAlign:'right',paddingRight:'30px'}}>{Math.round(i.sumOms).toLocaleString('da')}</td>
          </tr>
        })}
        <tr style={{backgroundColor:theme === 'dark' ? '#9b9eee':'rgba(173, 176, 255, .7)',fontWeight:'bold',opacity: theme === 'dark' ? '0.85' : '',color:theme === 'dark' ? '#101010' : ''}}>
       <td style={{padding:'4.25px'}}>{itemsAften.length} film i perioden</td>
       <td></td>
       <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{resC}</td>
       <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{soldC}</td>
       <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{totalC}</td>
       <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{belC}%</td>
       <td style={{textAlign:'right',paddingRight:'30px',paddingTop:'3px'}}>{Math.round(omsC).toLocaleString('da')}</td>
        </tr>
</tbody>

<tbody className="formatted_table" style={{borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3', marginBottom:'10px'}}>
{itemsAften2    
        .map((i,index) => {
          filmCount = filmCount + 1;
          resD = resD + i.sumRes;
          soldD = soldD + i.sumSold;
          totalD = totalD + i.sumTotal;
          omsD = omsD + i.sumOms;
          totalBelD = totalBelD + i.sumSeatCount;
          belD = Math.round(((resD + soldD) * 100) / totalBelD ); 
          resSum = resSum + i.sumRes;
          soldSum = soldSum + i.sumSold;
          totalSum = totalSum + i.sumTotal;
          omsSum = omsSum + i.sumOms;
          belSum = belSum + i.sumBel;
          totalBelSum = totalBelSum + i.sumSeatCount;
          return <tr style={{backgroundColor: theme === 'dark' ? '#4289bd' : 'rgba(126, 229, 109, .5)',color:theme === 'dark' ? 'white' : ''}}>
            <td style={{paddingLeft:'4px'}}>{index === 0 ? '2. Aften' : ''}</td>
            <td>{i.name}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumRes}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumSold}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.sumTotal}</td>
            <td style={{textAlign:'right',paddingRight:'8px'}}>{i.belag}%</td>
            <td style={{textAlign:'right',paddingRight:'30px'}}>{Math.round(i.sumOms).toLocaleString('da')}</td>
          </tr>
        })}
        <tr  style={{backgroundColor: theme === 'dark' ? 'rgba(66, 137, 189,0.8)' :'rgba(126, 229, 109, .8)',fontWeight:'bold',color:theme === 'dark' ? 'white' : ''}}>
          <td style={{padding:'4.25px'}}>{itemsAften2.length} film i perioden</td>
          <td></td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{resD}</td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{soldD}</td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{totalD}</td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{belD}%</td>
          <td style={{textAlign:'right',paddingRight:'30px',paddingTop:'3px'}}>{Math.round(omsD).toLocaleString('da')}</td>
        </tr>
       
</tbody>
{/* the below tr gives extra spacing below the tbody*/}
<tr style={{fontSize:'5px', color:'transparent', borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3', borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderBottom:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3'}}>ff</tr> 
<tbody className="formatted_table" style={{borderLeft:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3',borderRight:theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #dee0e3'}}>
{itemsNat 
        .map((i,index) => {
          filmCount = filmCount + 1;
          resE = resE + i.sumRes;
         soldE = soldE + i.sumSold;
         totalE = totalE + i.sumTotal;
         omsE = omsE + i.sumOms;
         totalBelE = totalBelE + i.sumSeatCount;
         belE = Math.round(((resE + soldE) * 100) / totalBelE );
          resSum = resSum + i.sumRes;
          soldSum = soldSum + i.sumSold;
          totalSum = totalSum + i.sumTotal;
          omsSum = omsSum + i.sumOms;
          belSum = belSum + i.sumBel;
          totalBelSum = totalBelSum + i.sumSeatCount;
          return <tr style={{backgroundColor:'rgba(219, 231, 84, .5)'}} >
              <td style={{paddingLeft:'4px'}}>{index === 0 ? 'Nat' : ''}</td>
              <td>{i.name}</td>
              <td>{i.sumRes}</td>
              <td>{i.sumSold}</td>
              <td>{i.sumTotal}</td>
              <td>{i.belag}%</td>
              <td>{Math.round(i.sumOms).toLocaleString('da')}</td>
          </tr>
        })}
        {itemsNat.length > 0 ? 
        <tr style={{backgroundColor:'rgba(219, 231, 84, .5)',fontWeight:'bold'}}>
          <td style={{padding:'4.25px'}}>{itemsNat.length} film i perioden</td>
          <td></td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{soldE}</td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{totalE}</td>
          <td style={{textAlign:'right',paddingRight:'8px',paddingTop:'3px'}}>{belE}%</td>
          <td style={{textAlign:'right',paddingRight:'30px',paddingTop:'3px'}}>{Math.round(omsE).toLocaleString('da')}</td>
        </tr>
        : ''}

</tbody>

      </>}

    </table>
    

     
     
      <div className="dagsperioder" style={{width:'100%'}}>
    
        <br />
      <p style={{fontSize:'18px',fontWeight:'bold', color: theme === 'dark' ? '#fff':'rgb(70,72,76)',opacity:theme === 'dark'? '0.6' :'',letterSpacing:'0.36px'}}>Dagsperioder</p>

     
{ duplicateElements.sort((a,b) => (a > b ? 1 : -1)).map((dates) => (
  <>
  <table className="table formatted_table" style={{letterSpacing:'0.3px'}} key={dates}>
     <thead style={{lineHeight:'30px',letterSpacing:'0.32px'}}>
      <tr style={{color:'#8d8d8d'}}>
        <td style={{width:'200px'}}> {days[new Date(new Date(dates).toUTCString()).getDay()]} d. {""}
           {dates.split("-").reverse().join("/").replace('/20','/')}</td>
           <td style={{width:'100px'}}>Nr.</td>
           <td style={{cursor:'pointer'}} onClick={() => {
                          requestSortForDay("name");
                          requestSortFirstEfDay("name");
                          requestSortAndenEfDay("name");
                          requestSortFirstAften("name");
                          requestSortAndenAften("name");
                          requestSortNatFors("name");
                        }}>Film</td>
           <td style={{cursor:'pointer'}} onClick={() => {
                          requestSortForDay("starTime");
                          requestSortFirstEfDay("starTime");
                          requestSortAndenEfDay("starTime");
                          requestSortFirstAften("starTime");
                          requestSortAndenAften("starTime");
                          requestSortNatFors("starTime");
                        }}>Start</td>
           <td style={{textAlign:'center',width:'150px',cursor:'pointer'}} onClick={() => {
                          requestSortForDay("sold");
                          requestSortFirstEfDay("sold");
                          requestSortAndenEfDay("sold");
                          requestSortFirstAften("sold");
                          requestSortAndenAften("sold");
                         requestSortNatFors("sold");
                        }}>Billetter solgt</td>
                       
      </tr>
     </thead>

     <tbody style={{fontSize:'15px',}}>
     {itemsforDay.map((item) => {
       if (item.date === dates) {
        formiddagCount = formiddagCount + 1;
        forsCount = forsCount + 1;
        Reserveret = Reserveret + item.res;
        solgte = solgte + item.sold;
        total = total + item.res + item.sold;
        oms = oms + item.oms;
        totalSeat = totalSeat + item.seatCount;
        return <tr   style={{backgroundColor:theme === 'dark' ? '#1b5ba2':'rgb(211 242 255',color:theme === 'dark' ? 'white'  :'#46484c' }}>              
                <td style={{paddingLeft:'4px'}}>{formiddagCount === 1 ? 'Formiddag' : ''}</td>
                <td>{formiddagCount}</td>
                <td>{item.name}</td>
                <td>{item.starTime}</td>
                <td style={{textAlign:'center'}} >{item.sold}</td>               
        </tr>
       }
     })
     }
     </tbody>
    
       <p style={{display:'none'}}>{formiddagCount = 0}</p>
       <tbody>
    { itemsFirstEfDay.map((item) => {
          if (item.date === dates) {
            forsCount = forsCount + 1;
            firstEFCount = firstEFCount + 1;
            Reserveret = Reserveret + item.res;
            solgte = solgte + item.sold;
            total = total + item.res + item.sold;
            oms = oms + item.oms;
            totalSeat = totalSeat + item.seatCount;
            return <tr  style={{backgroundColor:theme==='dark' ? '#ffd77f' :'rgb(240 196 151)',color:theme==='dark'?'#101010' :'#46484c'}}>       
              <td style={{paddingLeft:'4px'}}>{firstEFCount === 1 ? '1. eftermiddag' : ''}</td>
              <td>{firstEFCount}</td>
              <td>{item.name}</td>
              <td>{item.starTime}</td>
              <td style={{textAlign:'center'}} >{item.sold}</td>
                     
          </tr>
          }      
        })
       }
       </tbody>
     
          <p style={{display:'none'}}>{firstEFCount = 0}</p>
<tbody>
{itemsAndenEfDay.map((item) => {
    if (item.date === dates) {
      forsCount = forsCount + 1;
      secondEFCount = secondEFCount + 1;
      Reserveret = Reserveret + item.res;
      solgte = solgte + item.sold;
      total = total + item.res + item.sold;
      oms = oms + item.oms;
      totalSeat = totalSeat + item.seatCount;
      return <tr style={{backgroundColor:theme === 'dark'? '#286465' :'rgb(237 242 169)',color:theme=== 'dark' ? '#fff':'#46484c'}}>    
          <td style={{paddingLeft:'4px'}}>{secondEFCount === 1 ? '2. eftermiddag' : ''}  </td>
          <td>{secondEFCount}</td>
          <td>{item.name}</td>
          <td>{item.starTime}</td>
          <td style={{textAlign:'center'}} ><p style={{padding:0, margin:0,textAlign:'end', width:'80px'}}>{ item.sold}</p></td>
           
      </tr>
    }
})}
</tbody>

    <p style={{display:'none'}}>{secondEFCount = 0}</p>
<tbody>
{itemsFirstAften.map((item) => {
   if (item.date === dates) {
    forsCount = forsCount + 1;
    firstAftenCount = firstAftenCount + 1
    Reserveret = Reserveret + item.res;
    solgte = solgte + item.sold;
    total = total + item.res + item.sold;
    oms = oms + item.oms;
    totalSeat = totalSeat + item.seatCount;
    return <tr style={{backgroundColor:theme === 'dark' ? '#9b9eee':'rgba(173, 176, 255, .5)',color:theme === 'dark' ? '#101010':'#46484c'}}>
        <td style={{paddingLeft:'4px'}}>{firstAftenCount === 1 ? '1. aften' : ''}</td>
        <td>{firstAftenCount}</td>
        <td>{item.name}</td>
          <td>{item.starTime}</td>
          <td style={{textAlign:'center'}}><p style={{padding:0, margin:0,textAlign:'end', width:'80px'}}>{ item.sold}</p></td>
    </tr>
  }

})}
</tbody>

  <p style={{display:'none'}}>{firstAftenCount = 0}</p>

<tbody>
{ itemsAndenAften.map((item) => {
  if (item.date === dates) {
    secondAftenCount = secondAftenCount + 1;
    forsCount = forsCount + 1;
    Reserveret = Reserveret + item.res;
    solgte = solgte + item.sold;
    total = total + item.res + item.sold;
    oms = oms + item.oms;
    totalSeat = totalSeat + item.seatCount;
    return <tr style={{backgroundColor:theme === 'dark' ? '#4289bd' : 'rgba(126, 229, 109, .5)',color:theme === 'dark' ? '#fff':'#46484c'}}>
        <td style={{paddingLeft:'4px'}}>{secondAftenCount === 1 ? '2. aften' : ''}</td>
        <td>{secondAftenCount}</td>
        <td>{item.name}</td>
          <td>{item.starTime}</td>
          <td style={{textAlign:'center'}} ><p style={{padding:0, margin:0,textAlign:'end', width:'80px'}}>{ item.sold}</p></td>
    </tr>
  }

})}
</tbody>

  <p style={{display:'none'}}>{secondAftenCount = 0}</p>
<tbody>
{itemsNat.map((item) => {
if (item.date === dates) {
  natCount = natCount + 1;
  forsCount = forsCount + 1;
  Reserveret = Reserveret + item.res;
  solgte = solgte + item.sold;
  total = total + item.res + item.sold;
  oms = oms + item.oms;
  totalSeat = totalSeat + item.seatCount;
  return <tr style={{backgroundColor:'rgba(126, 229, 109, .5)',color:'#46484c'}}>
        <td style={{paddingLeft:'4px'}}>{natCount === 1 ? 'Nat' : ''}</td>
        <td>{natCount}</td>
        <td>{item.name}</td>
          <td>{item.starTime}</td>
          <td style={{textAlign:'center'}} ><p style={{padding:0, margin:0,textAlign:'end', width:'80px'}}>{ item.sold}</p></td>
    </tr>
    
}

})}
</tbody>

<p style={{display:'none'}}>{natCount = 0}</p>
<hr style={{height:'3px', margin:0,padding:0,color:'transparent'}}/>
<tfoot style={{backgroundColor:theme === 'dark' ? '':'#f8f8f8',marginTop:'5px',height:'39px',color:'#46484c',borderBottom:theme=== 'dark' ?'1px solid #dee0e3' :''}}>
  <tr style={{borderTop: theme === 'dark' ? '1px solid #6c6c6c' : ''}}>
    <td style={{color:theme === 'dark' ? 'transparent':'#46484c', paddingLeft:'3px',fontWeight:'600' }}> I alt</td>
    <td></td>
    <td style={{color: theme === 'dark' ? '#ecc590':'#46484c', paddingLeft:'3px',fontWeight:'bold' }}>{forsCount} forestillinger</td>
    <td></td>
    <td style={{color:theme === 'dark' ? '#ecc590':'#46484c', textAlign:'center',width:'57px',fontWeight:'bold' }}>
    <p style={{padding:0, margin:0,textAlign:'end', width:'80px'}}>{solgte}</p>
      </td>
    
  </tr>

</tfoot>
  </table>
  <br />
  <p className="text-dark hide">
                    {(forsCount = 0)}
                    {(Reserveret = 0)}
                    {(solgte = 0)}
                    {(total = 0)}
                    {(oms=0)}
                  
                  </p>
  </>
  
))

}
        <ScrollToTop smooth color="#000" style={{marginBottom: 40 + 'px'}}/>
      </div>
  
    </>
  ) : (
    <p></p>
  );
  
}

export default DayTable;
