import "./App.css";
import "./components/darkmode.css";
import { BrowserRouter, Routes,   Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import KeyContextProvider from "./components/KeyContext";
import Login from "./views/Login";

import WeekReport from "./views/WeekReport";
import WeekReportMobil from "./views/WeekReportMobil";
import WeekReportMobile2 from "./views/WeekReportMobile2";

import Home from "./views/Home";

import DayPeriod from "./views/DayPeriode";
import DayPeriodMobil from "./views/DayPeriodeMobil";

import Configuration from "./views/Cofiguration";

import Dagens from "./views/Dagens";
import DagensMobil from "./views/DagensMobil";

import Messages from "./views/Messages";
import Header from "./components/header/Header";
import Reports from "./views/Reports";

import PeriodeSam from './views/PeriodeSam'
import PeriodeSamMobil from "./views/PeriodeSamMobil";

import Incidents from "./components/Incidents/Incidents";
import AdminIncidents from "./views/AdminIncidents";

import WorkPlan from "./views/WorkPlan";
import WorkplanMobil from "./views/WorkplanMobil";

import Program from './views/Program'

import FourWeeksReportMobil from "./views/FourWeeksReportMobil";

function App() {
  const key = sessionStorage.getItem("key");
  // console.log(key && key.length)
  const o = document.querySelectorAll('option')
  const lenMax = 35
  o.forEach(e => {
    if (e.textContent.length > lenMax) {
      e.textContent = e.textContent.substring(0,lenMax) + '...';  
    }
    
  })
  return (
    <>
      <div className="App" >
        
        <KeyContextProvider>
          <BrowserRouter>
            <Routes>
             
              <Route path="/" element={<Login />}></Route>
             
              <Route exact path="/admin" element={ <Home /> }></Route>
             
              <Route path="/configuration" element={ <Configuration /> }></Route>
              
              <Route path="/messages" element={ <Messages/> }></Route>
              <Route path="/program" element={ <Program />}></Route>
              <Route path="/reports" element={ <Reports/> }></Route>

              <Route path="/rapport" element={<WeekReport />}></Route>
              <Route path="/ugesrapport" element={ <WeekReportMobil />}></Route>
              <Route path="/ugesrapport2" element={ <WeekReportMobile2 />}></Route>

              <Route path="/dagsrapport" element={ <DayPeriodMobil />}></Route>
              <Route path="/dagsperiode" element={<DayPeriod />}></Route>

              <Route path ="/incidents" element={<AdminIncidents/>}></Route>

              <Route path="/dagensrapport" element={<Dagens />}></Route>
              <Route path="/dagenrapport" element={<DagensMobil />}></Route>

              <Route path ="/periode"  element={<PeriodeSam />}></Route>
              <Route path ="/perioder" element={<PeriodeSamMobil />}></Route>

              <Route path="/workplan" element={<WorkPlan />}></Route>
              <Route path="/workplans" element={<WorkplanMobil />}></Route>

              <Route path="/fourweeksreport" element={<FourWeeksReportMobil />}></Route>
              
             
            </Routes>
          </BrowserRouter>
          
        </KeyContextProvider>

        
      </div>
    </>
  );
}

export default App;
