import React, {
    useEffect,
    useState,
    CSSProperties,
    useRef,
    useContext,
  } from "react";
  import moment from "moment";
  import {
    format,
    isSameMonth,
    addDays,
    addYears,
    startOfWeek,
    subDays,
    subWeeks,
  } from "date-fns";
  import { DayPicker } from "react-day-picker";
  import da from "date-fns/locale/da";
  import { useNavigate } from "react-router-dom";
  import PulseLoader from "react-spinners/PulseLoader";
  import { CountdownCircleTimer } from "react-countdown-circle-timer";
  
  //INTERNAL IMPORTS
  import Tables from "../components/FilmPerDayTable";
  import calendar from "../images/Icon-Calendar.png";
  import close from "../images/close.png";
  import whiteCalendar from '../images/whiteCalendar.svg'
  import "../components/css/rapport.css";
  
  // import NavMenu from "../components/NavMenu";
  import BiografSelect from "../components/BiografSelect";
  // import DateRangeSelect from "../components/DateRangeSelect";
  import { keyContext } from "../components/KeyContext";
  import FilmPerForsTable2 from "../components/FilmPerForsTable2";
  
  function BilletsalgPerFors2({setIsShown}) {
    const { startDate, endDate, filmId, data, setData, defaultFilm } =  useContext(keyContext);
    const defaultFilmID = sessionStorage.getItem("default-filmID");
   
    const navigate = useNavigate();
    function not() {
  
    }
    let path = window.location.pathname
   path === '/reports' ? setIsShown(true) : not()
    const [isLoading, setIsLoading] = useState(false);
    const [noData, setNoData] = useState(false);
    const [err, setErr] = useState(null);
  
    const today = new Date();
    const nextYear = addYears(new Date(), 1);
    const [month, setMonth] = useState(new Date());
    const [year, setYear] = useState(nextYear);
    const [decrease, setDecrease] = useState(false);
    const [increase, setIncrease] = useState(false);
  
    const [radioChecked, setRadioChecked] = useState(
      localStorage.getItem("weekstart") ? localStorage.getItem("weekstart") : "1"
    );
    
    const override: CSSProperties = {
      display: "block",
      position: "absolute",
      top: "50%",
      left: "50%",
      margin: "0 auto",
      textAlign: "center",
      borderColor: "#982f2f",
      transform: "translate(-50%, -50%)",
    };
  
    useEffect(() => {
      document.title = "Ugestatestik rapport - ebillet_admin";
    }, []);
  
    const [result, setResult] = useState("");
  
    var endString = endDate.split("-").reverse().join("-");
  
    var startString = startDate.split("-").reverse().join("-");
    //  console.log(startString,endString)
  
    const key = sessionStorage.getItem("key");
    let theme = localStorage.getItem("theme");
    addDays(new Date(), 6);
    const [showFirstCal, setshowFirstCal] = useState(false);
    const [showSecondCal, setShowSecondCal] = useState(false);
    const [firstSelectedDay, setFirstSelectedDay] = useState(new Date());
    const [secondSelectedDay, setSecondSelectedDay] = useState(
      addDays(new Date(), 6)
    );
    const [currentDate, setCurrentDate] = useState(new Date());
    //vis rapport for
    const [selected, setSelected] = useState(6);
    const [showBtn, setShowBtn] = useState(false);
    
    var dateFrom = new Date(moment().subtract(1, "week").startOf("week"));
    
    useEffect(() => {
      if (selected === 0 && radioChecked == 1) {
        setFirstSelectedDay(new Date(moment().startOf("isoweek")));
        setSecondSelectedDay(new Date(moment().endOf("isoweek")));
      }
      if (selected === 1 && radioChecked == 1) {
        setFirstSelectedDay(
          new Date(moment().subtract(1, "week").startOf("isoweek"))
        );
        setSecondSelectedDay(
          new Date(moment().subtract(1, "week").endOf("isoweek"))
        );
      }
      if (selected === 0 && radioChecked == 4) {
        setFirstSelectedDay(startOfWeek(new Date(), { weekStartsOn: 4 }));
        setSecondSelectedDay(
          addDays(startOfWeek(new Date(), { weekStartsOn: 4 }), 6),
          "end day"
        );
      }
      if (selected === 1 && radioChecked == 4) {
        setFirstSelectedDay(
          startOfWeek(new Date(moment().subtract(1, "week")), { weekStartsOn: 4 })
        );
        setSecondSelectedDay(
          addDays(
            startOfWeek(new Date(moment().subtract(1, "week")), {
              weekStartsOn: 4,
            }),
            6
          )
        );
      }
    }, [selected, radioChecked]);
  // console.log(firstSelectedDay, secondSelectedDay)
    // BELOW ARE FUNCTIONS TO DECREASE AND INCREASED DAYS BY 1 OR 7
    function decreaseByOne() {
      // console.log("clicked");
      setSelected(2);
      setFirstSelectedDay(subDays(firstSelectedDay, 1));
      setSecondSelectedDay(subDays(secondSelectedDay, 1));
    }
    function increaseByOne() {
      console.log("clicked");
      setSelected(3);
      setFirstSelectedDay(addDays(firstSelectedDay, 1));
      setSecondSelectedDay(addDays(secondSelectedDay, 1));
    }
    function decreaseBySeven() {
      console.log("clicked");
      setSelected(4);
      setFirstSelectedDay(subDays(firstSelectedDay, 7));
      setSecondSelectedDay(subDays(secondSelectedDay, 7));
    }
    function increaseBySeven() {
      console.log("clicked");
      setSelected(5);
      setFirstSelectedDay(addDays(firstSelectedDay, 7));
      setSecondSelectedDay(addDays(secondSelectedDay, 7));
    }
  
    let firstDate;
    if (firstSelectedDay) {
      firstDate = (
        <span>
          {" "}
          {format(firstSelectedDay, "d.")}{" "}
          {moment(firstSelectedDay).locale("da").format("MMM")}{" "}
          {format(firstSelectedDay, "yyyy")}{" "}
        </span>
      );
    }
    let secondDate;
    if (secondSelectedDay) {
      secondDate = (
        <span>
          {" "}
          {format(secondSelectedDay, "d. ")}{" "}
          {moment(secondSelectedDay).locale("da").format("MMM")}{" "}
          {format(secondSelectedDay, "yyyy")}{" "}
        </span>
      );
    }
    let fraDato = moment(firstSelectedDay).format("yyyy-MM-DD");
    let tilDato = moment(secondSelectedDay).format("yyyy-MM-DD");
    // console.log(moment(firstSelectedDay).format("DD-MM-yyyy"), 'firs
    // console.log(firstDate)
    const strD = sessionStorage.getItem("period");
    // console.log(strD)
    const fD = strD && strD.slice(0, 10);
    const lD = strD && strD.slice(14, 25);
    // console.log(lD)
    // console.log(filmID,fD)
    const ff = fD && fD.split("-").reverse().join("-");
    const dd = lD && lD.split("-").reverse().join("-");
    // console.log(fraDato)
  
    // console.log(firstSelectedDay)
    // console.log(fraDato, tilDato)
    const filmID = sessionStorage.getItem("film");
  
    const orgNr = filmID && filmID.split(" ")[filmID.split(" ").length - 1];
  
    const hentData = (runAgain) => {
      setIsLoading(true);
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
          orgNr ? orgNr : defaultFilmID
        }&StartDate=${fraDato}&EndDate=${tilDato}&FromTime=00:00:00&ToTime=23:59:59`,
        {
          method: "GET",
  
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw Error("ingen forstillinger i valgte biograf..");
          }
  
          return res.json();
        })
        .then((result) => {
          // when app pool reloades so new KEY being feched here
          if (
            result.key === null &&
            result.result === 0 &&
            result.data === null
          ) {
            let userN = sessionStorage.getItem("user");
            let kodeN = sessionStorage.getItem("password");
            const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_login?User=${userN}&PW=${encodeURIComponent(
              kodeN
            )}`;
            fetch(endpoint, {
              method: "GET",
  
              headers: {
                ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                // console.log(result, 'result from inside')
                sessionStorage.setItem("key", result.key);
                if (runAgain) {
                  hentData(false);
                }
              });
          }
  
          setIsLoading(false);
  
          if (result.Message) {
            setNoData(true);
            setData(null);
          } else {
            setIsLoading(false);
            setNoData(false);
  
            setData(result);
            setResult(result);
          }
        })
        .catch((err) => setErr(err.message));
    };
  
    useEffect(() => {
      hentData();
    }, [selected, tilDato, fraDato, filmId, orgNr, defaultFilmID]);
  
    let arr = [];
  
    result && result.filter((i) => arr.push(i.ArrTime.slice(0, 10)));
  
    function toFindDuplicates(arr) {
      const uniqueElements = new Set(arr);
      // const filteredElements =
      arr.filter((item) => {
        if (uniqueElements.has(item)) {
          // uniqueElements.delete(item);
        } else {
          return item;
        }
      });
  
      return [...new Set(uniqueElements)];
    }
  
    const duplicateElements = toFindDuplicates(arr);
    // console.log(duplicateElements)
  
    let dt = duplicateElements.sort((a, b) => (a > b ? 1 : -1));
  
    if (duplicateElements.length) {
      let period = `${dt[0].split("-").reverse().join("-")} to ${dt[dt.length - 1]
        .split("-")
        .reverse()
        .join("-")}`;
      sessionStorage.setItem("period", period);
    }
  
    const [dateB4, setDateB4] = useState(true);
    const [secondDateB4, setSecondDateB4] = useState(true);
  
    function handleFirstClick() {
      setshowFirstCal(true);
      setDateB4(false);
      setSelected(6);
    }
    function handleSecondClick() {
      setShowSecondCal(true);
      setSecondDateB4(false);
      setSelected(6);
    }
    function closeCalendar() {
      setshowFirstCal(false);
    }
    function closeCalendar2() {
      setShowSecondCal(false);
    }
    const o = document.querySelectorAll("option");
    const lenMax = 35;
    o.forEach((e) => {
      if (e.textContent.length > lenMax) {
        e.textContent = e.textContent.substring(0, lenMax) + "...";
      }
    });
  
    const lists = [
      { id: 0, title: "DENNE UGE", nr: "0" },
      { id: 1, title: "SIDSTE UGE" },
      { id: 6, title: "VIS" },
    ];
  
    const handleColor = (row) => {
      setSelected(row.id);
      
    };
    
  
    // handling week start check and saving to local storage
    function handleWeekStart(e) {
      setRadioChecked(e.target.value);
      localStorage.setItem("weekstart", e.target.value);
    }
  
    //react countdown timer
    const [minut, setMinut] = useState("");
  
    const renderTime = ({ remainingTime }) => {
      setMinut(Math.round((remainingTime / 600) * 100) + "%");
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;
  
      return (
        <p
          className="timeRemained"
          style={{ color:theme === 'dark' ? '#fff': "#800409", backgroundColor: theme === 'dark' ? '': "white" }}
        >
          <i>
            {minutes < 10 ? "0" : ""}
            {minutes}:{seconds < 10 ? "0" : ""}
            {seconds}
          </i>
        </p>
      );
    };
  
    return (
      <main className='rapport'>
          <div className="titleContainer" style={{border:'1px '}}>
          <p style={{ fontWeight: "600", fontSize: "25px", color:theme === 'dark' ? '#fff': "" }}>
          Billetsalg pr. forestilling
          </p>
          <div>
          {data && data.length ? (
         <>
           <div className="countdownContiner" style={{color: theme === 'dark' ? 'white' : "#982f2f",}}>
             {" "}
             Opdateres om
             <div >
             <div>
               <div className="countdownDiv">
                 <CountdownCircleTimer
                   isPlaying
                   duration={600}
                   size={0}
                   colors={["#982f2f", "#F7B801", "#A30000", "#A30000"]}
                   colorsTime={[10, 6, 3, 0]}
                   onComplete={() => {
                     hentData(true);
                     return { shouldRepeat: true };
                   }}
                 >
                   {renderTime} 
                 </CountdownCircleTimer>{" "}
               </div>
             </div>
             
             </div>
             min.
           </div>

           <div
             className="progressbarContainer"
             style={{ display: "flex", justifyContent: "flex-end" }}
           >
             <div
               class="progress"
               style={{
                 width: "180px",
                 height: "8px",
                 borderRadius: "0",
                 backgroundColor: theme === 'dark' ? '#65696c':"white",
                 border: theme === 'dark' ?  '1px solid #8d8d8d': "1px solid lightgrey",
                 justifyContent: "end",
               }}
             >
               <div
                 class="progress-bar"
                 style={{
                   width: minut,
                   backgroundColor: "#982f2f",
                   margin: "1px",
                 }}
                 role="progressbar"
                 aria-label="Basic example"
                 aria-valuenow="25"
                 aria-valuemin="0"
                 aria-valuemax="100"
               ></div>
             </div>
           </div>
         </>
       ) : (
         ""
       )}
       </div>
        </div>
          <br />
          <div className="biograf_box" style={{ display:'flex',justifyContent:'space-between'}}>
                  <p
                    className="period-title"
                    style={{ color: theme === "dark" ? "#fff" : "#46484c",opacity:theme === 'dark'? '0.6':'' }}
                  >
                    Vis rapport for Perioden
                  </p>
                 <BiografSelect />

                  </div>
          <section className="datesContainer" style={{height:'165px'}}>
  
  
  <div className="periodAndWeeksContainer">
    <div style={{ position: "relative", display: "inline-block" }}>
      <div className="periodAndShow" style={{}}>
      <section className="period-box">
                 
                 
                 <div className="periode-box">
                   <span
                     className="fra"
                     style={{ color: theme === "dark" ? "#fff" : "#46484c",opacity:theme === 'dark'? '0.6':''  ,marginTop:'10px'}}
                   >
                     Fra:
                   </span>
                   <div className="date-box" style={{backgroundColor: theme === 'dark' ? '#65696c' : '',border:  '1px solid #8d8d8d'}}>
                     <span
                       className="first-date"
                       style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                     >
                       {ff && dateB4 === true
                         ? moment(ff).format("DD. MMM. yyyy")
                         : firstDate}
                     </span>
                     <span className="date-box-line" style={{backgroundColor:'#8d8d8d'}}></span>
                     <img
                       src={ theme === 'dark' ? whiteCalendar :calendar}
                       alt=""
                       className="date-box-calendar"
                       onClick={handleFirstClick}
                     />
                     {showFirstCal ? (
                       <div className="dagsCalendar">
                         <figure style={{ textAlign: "right", padding: 10 }}>
                           <img
                             src={close}
                             alt=""
                             style={{
                               cursor: "pointer",
                               border: "1px solid white",
                               backgroundColor: "white",
                             }}
                             onClick={closeCalendar}
                           />
                         </figure>
                         <div
                           className="weekdays "
                           style={{
                             color: theme === "dark" ? "#fff" : "#46484c",
                           }}
                         >
                           <span className="days">Man</span>
                           <span className="days">Tirs</span>
                           <span className="days">Ons</span>
                           <span className="days">Tors</span>
                           <span className="days">Fre</span>
                           <span className="days">Lør</span>
                           <span className="days">Søn</span>
                         </div>
                         <DayPicker
                           locale={da} 
                           mode="single"
                           fromYear={2015}
                           toYear={2030}
                           captionLayout="dropdown"
                           showOutsideDays
                           showWeekNumber
                           selected={firstSelectedDay}
                           month={month}
                           year={year}
                           onYearChange={setYear}
                           onMonthChange={setMonth}
                           onSelect={setFirstSelectedDay}
                           onDayClick={closeCalendar}
                         />
                         <br />
                         <div style={{ textAlign: "center" }}>
                           <button
                             disabled={isSameMonth(today, month)}
                             onClick={() => {
                               setMonth(today);
                               setYear(today);
                             }}
                             style={{
                               border: "none",
                               backgroundColor: "white",
                               // background: "transparent",
                               fontSize: 12 + "px",
                               fontWeight: "bold",
                             }}
                           >
                             <u> GÅ TIL I DAG </u>
                           </button>
                         </div>
                         <br />
                       </div>
                     ) : (
                       ""
                     )}
                   </div>

                   <span
                     className="fra"
                     style={{ color: theme === "dark" ? "#fff" : "#46484c" ,opacity:theme === 'dark'? '0.6':'',marginTop:'10px' }}
                   >
                     Til:
                   </span>
                   <div className="date-box-til" style={{backgroundColor: theme === 'dark' ? '#65696c' : '',border: '1px solid #8d8d8d'}}>
                     <span
                       className="first-date"
                       style={{ color: theme === "dark" ? "#fff" : "#46484c" }}
                     >
                       {dd && secondDateB4 === true
                         ? moment(dd).format("DD. MMM. yyyy")
                         : secondDate}
                     </span>
                     <span className="date-box-til-line" style={{backgroundColor:'#8d8d8d'}}></span>
                     <img
                       src={theme === 'dark' ? whiteCalendar :calendar}
                       alt=""
                       className="date-box-til-calendar"
                       onClick={handleSecondClick}
                     />
                     {showSecondCal ? (
                       <div className="dagsCalendar2">
                         <figure style={{ textAlign: "right", padding: 10 }}>
                           <img
                             src={close}
                             alt=""
                             style={{
                               cursor: "pointer",
                               border: "1px solid white",
                               backgroundColor: "white",
                             }}
                             onClick={closeCalendar2}
                           />
                         </figure>
                         <div
                           className="weekdays"
                           style={{
                             color: theme === "dark" ? "#fff" : "#46484c",
                           }}
                         >
                           <span className="days">Man</span>
                           <span className="days">Tirs</span>
                           <span className="days">Ons</span>
                           <span className="days">Tors</span>
                           <span className="days">Fre</span>
                           <span className="days">Lør</span>
                           <span className="days">Søn</span>
                         </div>
                         <DayPicker
                           locale={da}
                           mode="single"
                           fromYear={2015}
                           toYear={2030}
                           captionLayout="dropdown"
                           showOutsideDays
                           showWeekNumber
                           selected={secondSelectedDay}
                           month={month}
                           year={year}
                           onYearChange={setYear}
                           onMonthChange={setMonth}
                           onSelect={setSecondSelectedDay}
                           onDayClick={closeCalendar2}
                         />
                         <br />
                         <div style={{ textAlign: "center" }}>
                           <button
                             disabled={isSameMonth(today, month)}
                             onClick={() => {
                               setMonth(today);
                               setYear(today);
                             }}
                             style={{
                               border: "none",
                               backgroundColor: "white",
                               // background: "transparent",
                               fontSize: 12 + "px",
                               fontWeight: "bold",
                             }}
                           >
                             <u> GÅ TIL I DAG </u>
                           </button>
                         </div>
                         <br />
                       </div>
                     ) : (
                       ""
                     )}
                   </div>
                 </div>
               </section>
      </div>
  
      <br />
      <div className="btns-contair">
                <div className="btnsgroup ">
                  {lists.map((list, index) => (
                    <button
                      className={`butto button_${index}`}
                      key={list.id}
                      onClick={() => handleColor(list)}
                      style={{
                        backgroundColor:
                          list.id === selected && showBtn === false
                            ? "#982f2f"
                            :theme === 'dark' ? '#4e5052' : '',
                        color:
                          list.id === selected && showBtn === false
                            ? "#fff"
                            :theme === 'dark'? 'white' : "",
                        fontSize: "14px",
                        padding: "0",
                        border: theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #982f2f',
                        marginRight: "11px",
                        fontWeight: 700,
                      }}
                    >
                      {list.title}
                    </button>
                  ))}
                  <p className="divider" style={{backgroundColor:theme === 'dark' ? '#6c6c6c' : '#d9d9d9'}}></p>
                  <button
                    className="butto button_3"
                    style={{
                      backgroundColor: selected === 2 ? "#982f2f" :theme === 'dark' ? '#4e5052' : '' ,
                      color: selected === 2 ? "#fff" :theme === 'dark'? 'white' : "",
                      fontSize: "14px",
                      padding: "0",
                      border: theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #982f2f',
                      marginRight: "11px",
                      fontWeight: 700,
                    }}
                    onClick={decreaseByOne}
                  >
                    - 1 DAG
                  </button>

                  <button
                    className="butto button_4"
                    style={{
                      backgroundColor: selected === 3 ? "#982f2f" :theme === 'dark' ? '#4e5052' : '',
                      color: selected === 3 ? "#fff" :theme === 'dark'? 'white' : "",
                      fontSize: "14px",
                      padding: "0",
                      border: theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #982f2f',
                      marginRight: "11px",
                      fontWeight: 700,
                    }}
                    onClick={increaseByOne}
                  >
                    + 1 DAG
                  </button>

                  <button
                    className="butto button_5"
                    style={{
                      backgroundColor: selected === 4 ? "#982f2f" :theme === 'dark' ? '#4e5052' : '',
                      color: selected === 4 ? "#fff" : theme === 'dark'? 'white' :"",
                      fontSize: "12px",
                      padding: "0",
                      border: theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #982f2f',
                      marginRight: "11px",
                      fontWeight: 700,letterSpacing:'0.6'
                    }}
                    onClick={decreaseBySeven}
                  >
                    - 1 UGE
                  </button>
                  <button
                    className="butto button_6"
                    style={{
                      backgroundColor: selected === 5 ? "#982f2f" :theme === 'dark' ? '#4e5052' : '',
                      color: selected === 5 ? "#fff" :theme === 'dark'? 'white' :"",
                      fontSize: "14px",
                      padding: "0",
                      border: theme === 'dark' ? '1px solid #6c6c6c' : '1px solid #982f2f',
                      marginRight: "11px",
                      fontWeight: 700,
                    }}
                    onClick={increaseBySeven}
                  >
                    + 1 UGE
                  </button>
                </div>
              </div>
    </div>
    <div className="weekRadioBtns" style={{backgroundColor: theme === 'dark' ? '' :'#f7f7f7',border: theme === 'dark' ? '1px solid #6c6c6c':''}}>
              <span style={{ color:theme === 'dark'? '#fff': "#46484c" , fontWeight: "600" ,opacity: theme === 'dark' ? '0.6' :''}}>
                Ugen starter med
              </span>{" "}
              <br />
              <label
                htmlFor=""
                style={{ padding: "10px 0px", color:theme === 'dark'? '#fff': "#46484c" }}
              >
                <input
                
                  type="radio"
                  value="1"
                  name="week" style={{border: theme === 'dark' ? 
                '1px solid white' : '1px solid #982f2f'}}
                  checked={radioChecked === "1"}
                  onChange={(e) => handleWeekStart(e)}
                />{" "}
                Mandag
              </label>{" "}
              <br />
              <label htmlFor="" style={{ color:theme === 'dark'? '#fff': "#46484c" }}>
                <input
                  type="radio"
                  value="4"
                  name="week" style={{border: theme === 'dark' ? 
                  '1px solid white' : '1px solid #982f2f'}}
                  checked={radioChecked === "4"}
                  onChange={(e) => handleWeekStart(e)}
                />{" "}
                Torsdag
              </label>
            </div>
  </div>
  </section>
  
  <div className="tableData">
            {/* {err && <h2>{err}</h2>} */}
            {/* {isLoading && <h4>HENTER...</h4>} */}
            {noData && <h2>Ingen forestillinger i valgte biograf....</h2>}
  
            <div
              style={{ height: isLoading === true && 200, position: "relative" }}
            >
              {isLoading ? (
                <PulseLoader
                  color="#982f2f"
                  loading={isLoading}
                  cssOverride={override}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                " "
              )}
            </div>
           
            {data && data.length && !noData ? <FilmPerForsTable2 hentData={hentData}/> : ""}
          </div>
      </main>
    )
  }
  
  export default BilletsalgPerFors2