import React, {useState, useEffect, useContext, useRef} from 'react'
import Header from "../components/header/Header";
import '../components/css/program.css'
import arrows from '../images/arrow-down.svg'
import Modal from '../components/Modal';
import { DayPicker  } from 'react-day-picker';
import da from "date-fns/locale/da";
import close from '../images/icon-arrow.svg'
import searchIcon from '../images/search-26233.png'
import calendar from '../images/Icon-Calendar.png'
import { keyContext } from "../components/KeyContext";
import FilmWidget from '../components/FilmWidgets'
import trans from '../images/trans.png'
import moment from 'moment'
import small_square from '../images/small_square.svg'
import small_checkmark from '../images/small_checkmark.svg'
import alertIcon from '../images/Icon-Altert_Small.png'
import iconCross from '../images/Icon-Cross.png'
import { format, set } from 'date-fns'
import DatePicker from '../components/DatePicker/DatePicker';
import  ScaleLoader  from 'react-spinners/ScaleLoader';

function Program() {
  const { selectAllFilmAtOnce, setSelectAllFilmAtOnce, selectedDate,} = useContext(keyContext)
  const key = sessionStorage.getItem("key");
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Programlægning - ebillet_admin";
   
  }, []);

  const override: CSSProperties = {
    display: "block",
    position: "absolute",
    top: "30%",
    left: "50%",
    margin: "0 auto",
    textAlign: "center",
    borderColor: "#982f2f",
    transform: "translate(-50%, -50%)",
  };

  const disabledDays = [{ from: new Date(2022, 0, 1), to:  moment().subtract(1, 'day').toDate() }] ;
  const [modalShown, toggleModal] = useState(false);
  const [showFilm, setShowFilm]   = useState(false);
  const [widgets, setWidgets] = useState([])
  const [showTime,setShowTime] = useState(false)
  const [startTime,setStartTime] = useState([])
  const [draggedFilm, setDraggedFilm] = useState('')
  const [filmWidth, setFilmWidth] = useState()
  const [showG,setShowG] = useState(false)
  const [editChosen, setEditChosen] = useState(false)
  const [copyChosen, setCopyChosen] = useState(false)
  const [xAxis,setXAxis] = useState([])
  const [yAxis, setYAxis] = useState([])
  const [showStartTimeArr, setShowStartTimeArr] = useState([])
  const [showEndTimeArr, setShowEndTimeArr] = useState([])
  const [saveFilmEdit, setSaveFilmEdit] = useState(false);
  
  const [openCalendar,setOpenCalendar] = useState(false)
  const [startDatoCal,setStartDatoCal] = useState(false)
  const [endDatoCal,setEndDatoCal] = useState(false)

  const [startDate,setStartDate] = useState(new Date()) 
  const [endDate, setEndDate] = useState(new Date()) 

 let start_dato = moment(startDate).format('DD/MM/YY')
 let end_dato =   moment(endDate).format('DD/MM/YY')
 
  const [x, setX ] = useState(0)
  const [y, setY ] = useState(0)

//------ the value from search input
  const [q, setQ] = useState("");

  const [showAlert, setShowAlert] = useState(false);

  const [internetOrder, setInternetOrder]     = useState(false);
  const [internetSell, setInternetSell]       = useState(false)
  const [internetShow, setInternetShow]       = useState(false)
  const [ticketSellSell, setTicketSellSell]   = useState(false)
  const [ticketSellOrder, setTicketSellOrder] = useState(false)
  const [allMovies, setAllMovies] = useState([]);
  const [numberOfSal, setnumberOfSal] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [filmSearch, setFilmSearch ] = useState([])

  //----- events variables declarations --------------
  const [allTicketTypes, setAllTicketTypes] = useState([]);

// console.log(allMovies)
//----------api call for data-----------
  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getprogram?Key=${key}&strOrganizers=110`;
    if(key) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
      .then((res) => res.json())
      .then((result) => {
          console.log(result)
        setAllMovies(result.Movies)
        setnumberOfSal(result.Locations)
        setAllEvents(result.Events)
        setFilmSearch(result.Movies)
        setAllTicketTypes(result.TicketTypes)
        setIsLoading(false)
      })
      
    }
  }, [key])
 
//------ api call for movie search ------------
useEffect(() => {
  const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_movie_search?Key=${key}&search=${q}`;
    if(key && q ) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
      .then((res) => res.json())
      .then((result) => { 
      q.length &&  setFilmSearch(result)
        
      } )
    }
},[key,q])

// console.log(outsideMovies, 'outside movies')
 //--- movie check mark---
 const handleInternetSell = () => {
  setInternetSell((current) => !current)
 }
 const handleInternetShow = () => {
  setInternetShow((current) => !current)
 }
 const handleTicketSellSell = () => {
  setTicketSellSell((current) => !current)
 }
 const handleTicketSellOrder = () => {
  setTicketSellOrder((current) => !current)
 }
 
  const handleInternetOrder = () => {
    setInternetOrder((current) => !current)
  }

  const step_minutes = 5

  // ----search functionality------
 
  const [searchParam] = useState(["name"]);
  const [filterParam, setFilterParam] = useState(["name"]);
 
  const data = Object.values(filmSearch.length ? filmSearch : allMovies)
   
  function search(items) {
    return items.filter((item) => {
     
        if (filterParam == "name") {
            return searchParam.some((newItem) => {
                return (
                  item[newItem] && item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        }
    });
   
  
  }
// console.log(isloading)
  //---------time calculations below ------------
  let filmLists = []
  // let timeStamp = []

//  const [timeEnd, setTimeEnd] = useState('');



  function handleShowFilm () {
        setShowFilm( current => !current)
  }

     function findDuration(name) {
      let dura 
                  allMovies.filter((movie) => {
                    if(movie.name === name) { 
                      dura = movie.length
                      return dura
                    }
                  })
                  return dura
     }
 //-----------creating the filmlist object------------  
      for (let i = 0; i < widgets.length; i++) {     
        filmLists.push({
          id: i,
          name: widgets[i],
          duration:findDuration(widgets[i]),
          y_axis: yAxis[i],
          x_axis: xAxis[i],         
          start_time: showStartTimeArr[i],
          end_time: showEndTimeArr[i],
          sal: yAxis[i] == 55 ? 'Sal 1' : yAxis[i] == 103 ? 'Sal 2' : yAxis[i] == 151 ? 'Sal 3' : 'Sal 4'
        })
      }   

  // ---- creating programmed fils object ---
 
 
  function findLocations (i) {
    // console.log(i)
    let sal
         allMovies.map((m) => {
          numberOfSal.map((s) => {

            if(s.No === i.locationId && m.No === i.movieId) {
            
               sal = s.name
            }
           })
        })
        return sal
  }
  function findMovieName (i) {
    let name 
     allMovies.map((movie) => {
          
            if(i.movieId === movie.No) {
              name= movie.name
            }         
     })
     return name
  }
  function findFDuration (i) {
      let length 
      allMovies.map((movie) => {
        if (movie.No === i.movieId) {
          length = movie.length
        }
      })
      return length > 0 ? length : 120   // if film does not have any length then it should be by default 120 (in minutes) 2 hours by default
  }
// ------------FILTERING EVENTS PER DATE --------------
  let eventsPerDay = allEvents && allEvents.filter(e => moment(e.start).format('DD-MM-YYYY') === moment(selectedDate).format('DD-MM-YYYY'))
    
console.log(eventsPerDay, 'event per day')
// console.log(allMovies)
    function calculateX (e, fors_start, fors_end) {
      let table_width = document.getElementById('program_table').clientWidth
      
      fors_start = Date.parse(moment(fors_start).format('yyyy-MM-DD HH:00'))    
      
      if (moment(fors_end).format('mm') > '00') fors_end = moment(fors_end).add(1,'hours')

      fors_end = Date.parse(moment(fors_end).format("yyyy-MM-DD HH:00"))

      let total_minutes = (fors_end - fors_start)/60000
      let pixelsprminute = table_width / total_minutes
       
      let start_minute_count = (moment(e.start) - fors_start)/60000
      let startx = Math.round(start_minute_count * pixelsprminute)
     
      return startx
   
    }

    function calculateY (e) {
      let salIndex
      for(let x=0;x<numberOfSal.length;x++)
      {
         if (numberOfSal[x].No === e.locationId)
         {
           salIndex=x
           break;
         }
      } 
      // let tbodyHeight = 48 * numberOfSal.length
      let rowheight = (document.getElementById('t_body').clientHeight/numberOfSal.length)
      let y =  52 + (salIndex * rowheight)

//  console.log(document.getElementById('program_table').clientWidth)

   return Math.round(y+3)
   
    }
    // console.log(allEvents)
function findLocIndex (i) {
  let salIndex
  for(let x=0;x<numberOfSal.length;x++)
  {
     if (numberOfSal[x].No === i.locationId)
     {
       salIndex=x
       break;
     }
  }
    return salIndex
}

function findTickettypesName (e) {
console.log(e, 'ticket type name')
}

  let ProgrammedEvents = []
  let times = []
  let dateTimesArr = []
  let showEndTime = []
 
  for(let i = 0; i < eventsPerDay.length; i++) {
   
    ProgrammedEvents.push({
      index : findLocIndex(eventsPerDay[i]),
      name: findMovieName(eventsPerDay[i]),
      length: findFDuration(eventsPerDay[i]),
      y_axis:calculateY(eventsPerDay[i]),
      x_axis:0,
      locationId: eventsPerDay[i].locationId,
      start: moment(eventsPerDay[i].start).format('HH:mm'),     
      end: moment(moment(eventsPerDay[i].start).add(findFDuration(eventsPerDay[i]), 'm').toDate()).format('HH:mm'),
      sal:findLocations(eventsPerDay[i]),
      ArrTypeName: eventsPerDay[i].ArrTypeName,
      webReserveInfo: eventsPerDay[i].WebreserveInfo.enabled,
      webbuyInfo: eventsPerDay[i].WebbuyInfo.enabled,
      webshowInfo: eventsPerDay[i].WebshowInfo.enabled,
      buyEnabled: eventsPerDay[i].BuyEnabled,
      reserveEnabled: eventsPerDay[i].ReserveEnabled,
      priceGroups:eventsPerDay[i].PriceGroups,
      tickettypes: findTickettypesName (eventsPerDay[i].TicketTypes)
      
    })
    times.push(moment(eventsPerDay[i].start).format('HH:mm'))
    dateTimesArr.push(moment(eventsPerDay[i].start).format('YYYY-MM-DD HH:mm'))
    showEndTime.push(moment(moment(eventsPerDay[i].start).add(findFDuration(eventsPerDay[i]), 'm').toDate()).format('YYYY-MM-DD HH:mm'))

  }
 
  dateTimesArr = dateTimesArr.sort()
  showEndTime = showEndTime.sort()

  for(let i = 0; i < eventsPerDay.length; i++) {
    ProgrammedEvents[i].x_axis = calculateX(eventsPerDay[i], dateTimesArr[0], showEndTime[showEndTime.length-1])
  }


  const [last, setLast] = useState();
  // console.log(dateTimesArr, 'date time arr')
  // console.log(showEndTime, 'show end time')
  // console.log(ProgrammedEvents, 'programmed films')
  let g = showEndTime[showEndTime.length - 1]
  // console.log(moment(g).format('mm'), 'g')
  useEffect(() => {
    if(moment(g).format('mm') > '00') {
      // console.log('add 1 hour')
      let d = moment(g).add(1, 'hour')
      // console.log(m.add(1, 'hours').toDate())
      setLast(moment(d))
     }
     else 
     setLast(g)
  },[g])
 

    //---------time calculations below ------------
    let timeStamp = []

  const [timeEnd, setTimeEnd] = useState('');
  let fors_start = dateTimesArr[0]
  
  let fors_end   = showEndTime[showEndTime.length - 1]
  // let timeS = moment(new Date(fors_start)).format('HH:MM')
  // let timeE = moment(new Date(fors_end)).format('HH:MM')
  let theEndTime = timeEnd ? moment(timeEnd).format("HH") : moment(new Date(fors_end)).format('HH')
  //  console.log(fors_end, ' FORS end')
// console.log(fors_start, 'fors start')

  const dt_start = Date.parse(fors_start)
  const dt_end = timeEnd ? Date.parse(moment(timeEnd).format( "YYYY-MM-DD HH:mm")) :  Date.parse(last)
  
  let ts = moment(new Date(fors_start)).format('hH')
  
  let te = timeEnd ? moment(timeEnd).format('HH') : moment(last).format('HH')
// console.log( moment(dt_end), 'dt end')
    // console.log(te,'te')
    // console.log(timeEnd, 'time end')
   
    for (let index =  dt_start; index < dt_end ;  index = moment(index).add(1,'hours') ) {
      
            if(te !== moment(index).format('HH')) 
          timeStamp.push(moment(index).format('HH') )
         
        }
        timeEnd && timeStamp.pop()
        // console.log(timeStamp, 'time stamp')
  let pixelWidthPerMin = 1698/(timeStamp.length*60).toFixed(2)
// console.log(1698/(timeStamp.length*60))
    // console.log(pixelWidthPerMin,'pixelWidthPerMin')
//--------- 4 button click functions -----------
function handleSelectAll () {
  // console.log('mark all clicked')
  
  setSelectAllFilmAtOnce((current) => !current)
  
}
function handleCopy () {
  setCopyChosen((current) => !current)
}
function handleDelete () {

}
function handleEdit () {
  setEditChosen((current => !current))

}

//-----on click function on button

function saveTheEdit () {
   setSaveFilmEdit(true)
   setEditChosen((current => !current))
}
function deleteTheEdit () {
  setInternetOrder(false)
  setInternetSell(false)
  setInternetShow(false)
  setTicketSellSell(false)
  setTicketSellOrder(false)
  setEditChosen((current => !current))
  setSaveFilmEdit(false)

}
function editChosenFilm () {
  setEditChosen((current => !current))
}

function handleCalendar () {
  setOpenCalendar((current => !current))
}
function handleStartDatoCal () {
  if(endDatoCal   === true) setEndDatoCal(false)
  setStartDatoCal((current => !current))
}
function handleEndDatoCal () {
  if(startDatoCal === true) setStartDatoCal(false)
  setEndDatoCal((current => !current))
}
function closeCalendar() {
  setStartDatoCal(false)
}

useEffect(() => {
 internetOrder === true ? setShowAlert(true) : setShowAlert(false)
})

// transparent image on drag
    const img = new Image()
    img.src = trans
    
 //------------ runs when dragging the film image starts----------   
 function handleOnDrag (e,widgetType ) {
  
    e.dataTransfer.setDragImage(img, 0,0)
    e.nativeEvent.hidden = true
    e.dataTransfer.setData('widgetType', widgetType)
    setDraggedFilm(e.target.name)
    setFilmWidth(e.target.attributes.duration.value) 
 }
 //----------runs when dragging the film image ends ---------
 function imgDragEnd(e) { 
  e.dataTransfer.dropEffect === 'none' ? setShowG(false) : setShowG()
  setShowTime(false) 
}
//--------- removing ghost image while dragging the film ----------
 function draggingDiv(e) {
   const img = new Image()
         img.src = ''
        e.dataTransfer.setDragImage(img, 0,0)
 }
//-------- runs when film div is dropped on table-----------------

 function handleOnDrop (e) {
  // console.log('dropped')
   setShowTime(false)
  //let tbodyHeight = document.getElementById('t_body').clientHeight
  let theadHeight = document.getElementById('t_head').clientHeight
  const widgetType = e.dataTransfer.getData('widgetType') 
 widgetType && setWidgets([...widgets, widgetType]) 
  //-----------formula for obtaining Y-AXIS--------------
  let sal_y = calculateYaxis(e)

 if (sal_y < 0) sal_y = 0
 sal_y = sal_y + 3

const [snap_to_x, snap_to_x_before ]  = calculateXaxis(e)

  //------------saving the start time of particular film---------
  let start_time = moment(dt_start).add(Math.round(snap_to_x_before)*step_minutes, 'm').toDate();
 setShowStartTimeArr([...showStartTimeArr, moment(start_time).format('HH:mm')])
 let end_time = moment(start_time).add(filmWidth, 'm').toDate();
 setShowEndTimeArr([...showEndTimeArr, moment(end_time).format('HH:mm')])
  
//--------------limiting y axis to be the number of sals length----------
 let limitY = numberOfSal.length === 1 ? 55 : numberOfSal.length === 2 ? 103 : numberOfSal.length === 3 ? 151 : numberOfSal.length === 4 ? 199 : 247
//---setting the calculated x-axis and y-axis values generated from above formulas to data set
  e.target.innerHTML &&   setYAxis([...yAxis, sal_y + theadHeight > limitY ? limitY :  sal_y + theadHeight])
   e.target.innerHTML &&   setXAxis([...xAxis, snap_to_x])
   setShowG(false)
   setDraggedFilm('')
 }

 
 function calculateXaxis (e) {
  let mouse_x =  Math.floor(e.clientX - document.getElementById('program_table').getBoundingClientRect().x )
  let table_width = document.getElementById('program_table').clientWidth
  let total_minutes = (dt_end - dt_start)/60000
  let time_steps    = total_minutes / step_minutes
  let pixelPrStep   = table_width / time_steps;
  let rest_x        = mouse_x % pixelPrStep
  let snap_to_x_before = ((mouse_x - rest_x) / pixelPrStep)
  let snap_to_x     = Math.round(snap_to_x_before * pixelPrStep)
    return [snap_to_x , snap_to_x_before ]
 }
function calculateYaxis (e) {
  let tbodyHeight = document.getElementById('t_body').clientHeight
  let theadHeight = document.getElementById('t_head').clientHeight

  let dd = e.clientY - document.getElementById('program_table').getBoundingClientRect().y + theadHeight

  let rest  =  ((dd - theadHeight) % (tbodyHeight /  numberOfSal.length))
  let sal_y = ((dd - theadHeight - rest) / (tbodyHeight /  numberOfSal.length)-1) * (tbodyHeight /  numberOfSal.length) 
  return sal_y

}

//------------runs when dragged div is dragged over the table area ---------------
 function handleDragOver(e) {

   e.preventDefault();
  
   e.dataTransfer.types.length && draggedFilm ? setShowG(true) : setShowG(false) 
   
    let tbodyHeight = document.getElementById('t_body').clientHeight
    let theadHeight = document.getElementById('t_head').clientHeight

  
  let sal_y = calculateYaxis(e)

   if (sal_y<0) sal_y = 0
   if (sal_y>(tbodyHeight-1)) sal_y=tbodyHeight-(tbodyHeight/numberOfSal.length)
   sal_y = sal_y + 3
  
   setY(sal_y + theadHeight)
   
//-----------formula for obtaining x axis--------

const [snap_to_x, snap_to_x_before ]  = calculateXaxis(e)
let start_time = moment(dt_start).add(Math.round(snap_to_x_before)*step_minutes, 'm').toDate();
setStartTime(moment(start_time).format('HH:mm'))
 let end_time = moment(start_time).add(filmWidth, 'm').toDate();
 setX(snap_to_x ) 
if((moment(end_time).format('HH') === theEndTime) && (moment(end_time).format('mm') > '00' )) {
  setTimeEnd( moment(end_time).add(1,'hours').toDate())
}

 }



// ----following function runs when the film div is moved------
function dragTheDiv (e) {
    // console.log(e)
  setShowTime(true)
// setSeed(Math.random())
    //let tbodyHeight = document.getElementById('t_body').clientHeight
    let theadHeight = document.getElementById('t_head').clientHeight
  //  let dd = e.clientY - document.getElementById('program_table').getBoundingClientRect().y + theadHeight
 
  //  let rest = ((dd - theadHeight) % (tbodyHeight /  numberOfSal.length))
  //  let sal_y = ((dd - theadHeight - rest) / (tbodyHeight /  numberOfSal.length)-1) * (tbodyHeight /  numberOfSal.length) 
  let sal_y = calculateYaxis(e)
   if (sal_y < 0) sal_y = 0
       sal_y = sal_y + 3

  //-------------x axis --------------
//   let mouse_x =  Math.floor(e.clientX - document.getElementById('program_table').getBoundingClientRect().x)
  let table_width = document.getElementById('program_table').clientWidth
// let total_minutes = (dt_end - dt_start)/60000
// let time_steps = total_minutes / step_minutes
// let pixelPrStep = table_width / time_steps;
// let rest_x = mouse_x % pixelPrStep
// let snap_to_x_before = ((mouse_x - rest_x) / pixelPrStep)
// let snap_to_x = Math.round(snap_to_x_before * pixelPrStep)
let [snap_to_x, snap_to_x_before ]  = calculateXaxis(e)

 let filmDivWidth = e.target.clientWidth

 let limitX = table_width - filmDivWidth;
let newWidth 
filmLists.map((f) => {
    if(f.id == e.target.id){
     newWidth= f.duration
    }
})

// calculateAxis(e, newWidth)

    if(snap_to_x < 0)  snap_to_x = 0
   else if(snap_to_x > limitX) snap_to_x = limitX
  
//----------resetting the Yaxis and  Xaxis when the film div is dragged---------
let limitY = numberOfSal.length === 1 ? 55 : numberOfSal.length === 2 ? 103 : numberOfSal.length === 3 ? 151 :
numberOfSal.length === 4 ? 199 : 247

setYAxis(filmLists.map((f) => {
  if (f.id == e.target.id) {  
    f.y_axis  = sal_y + theadHeight  
  }
  return  f.y_axis
}))

setXAxis(filmLists.map((f) => {
  if(f.id == e.target.id) {
    f.x_axis = snap_to_x
  }
  return f.x_axis
}))



let start_time = moment(dt_start).add(Math.round(snap_to_x_before)*step_minutes, 'm').toDate();
let end_time = moment(start_time).add(newWidth, 'm').toDate();

setShowStartTimeArr(filmLists.map((f) => {
  if(f.id == e.target.id) {
    f.start_time = moment(start_time).format('HH:mm') 
    //  < ts ? ts+':00' : moment(start_time).format('HH:mm')
  }
  return f.start_time
}))

setShowEndTimeArr(filmLists.map((f) => {
  if(f.id == e.target.id) {
    f.end_time = moment(end_time).format('HH:mm') 
    // > te ? te : moment(end_time).format('HH:mm')
  }
  return f.end_time
}))

if((moment(end_time).format('HH') === theEndTime) && (moment(end_time).format('mm') > '00')) {
  // console.log('ADD 1 HOUR TO THE GIVEN TIME')
    setTimeEnd( te === '01' ?'' : moment(end_time).add(1,'hours').toDate())
  // console.log()
}

}
console.log(filmLists)
console.log(ProgrammedEvents)
// -------if yAxis array and xAxis array length is longer than filmlist array then the following code will remove the extra entry from X and Y arrays
useEffect(() => {
 if(yAxis.length >  filmLists.length) {  yAxis.pop() }
 if(xAxis.length >  filmLists.length) {  xAxis.pop() }
 if(showStartTimeArr.length >  filmLists.length) {  showStartTimeArr.pop() }
 if(showEndTimeArr.length >  filmLists.length) {  showEndTimeArr.pop() }
},[yAxis,xAxis,showStartTimeArr,showEndTimeArr])


//-------------colors which specifies the sal/halls 
let sal1Color  = '#dbe754';
let sal1Color2 = '#f5fabf';
let sal1Color3 = '#eaf380';

let sal2Color  = '#adb0ff'; 
let sal2Color2 = '#e5e6fb';
let sal2Color3 = '#d7d8f5';

let sal3Color  = '#ff9b2f';
let sal3Color2 = '#feebd7';
let sal3Color3 = '#ffe1c1';

let sal4Color  = '#a9e5ff';
let sal4Color2 = '#def5ff';
let sal4Color3 = '#cfecf9';

let sal5Color  = '#7ee56d';
let sal5Color2 = '#c9fbc0';
let sal5Color3 = '#b4f6a8';

let sal6Color ='#f15f56'
let sal6Color2 ='#f6d2d0'
let sal6Color3 ='#f4928c'

let sal7Color = '#2c6b0f'
let sal7Color2='#b9e8a3'
let sal7Color3 = '#73b355'

let sal8Color = '#6978ff'
let sal8Color2='#b6bdfb'
let sal8Color3='#909bfb'

let sal9Color = '#9c7000'
let sal9Color2='#f7d786'
let sal9Color3='#eab93d'

let sal10Color = '#3bc4a0'
let sal10Color2='#aaf5e1'
let sal10Color3='#82f7d8'

//-------- following function runs when the existing film-show div is moved ----------
function dragExisting (e) {
  // console.log(e.target.style)
 let [snap_to_x, snap_to_x_before ]  = calculateXaxis(e)
 console.log(e.clientY, 'e')
 let sal_y = calculateYaxis(e)+7
  if(sal_y < 55) sal_y = 55
   //console.log(sal_y, 'y')
  
   e.target.style.left = snap_to_x < 0 ? 0+'px' :snap_to_x+'px'
 
   e.target.style.top= sal_y+'px'
   e.target.style.backgroundColor= sal_y === 55 ? sal1Color : sal_y === 103 ? sal2Color : sal_y === 151 ? sal3Color : sal_y === 199 ? sal4Color : sal_y === 247 ? sal5Color :
   sal_y === 295 ? sal6Color : sal_y === 343 ? sal7Color : sal_y === 391 ? sal8Color : sal_y === 439 ? sal9Color : sal_y === 487 ? sal10Color : ''
 } 

//--------------generating film div -----------------
function generateFilms () {
    let ff = []
    filmLists.forEach( (film) => {
           ff.push( 
            film.y_axis  ?
             <div  id={film.id} draggable onDrag={(e) => dragTheDiv(e)} onDragStart={(e) => draggingDiv(e)} style={{position:'absolute',top:`${ film.y_axis}px`,left:`${film.x_axis}px`, width:`${(pixelWidthPerMin*film.duration).toString().split(".")[0] + 'px' }`,
             backgroundColor:film.y_axis == 55 ? sal1Color : film.y_axis == 103 ? sal2Color  :film.y_axis == 151 ? sal3Color:
             film.y_axis == 199 ? sal4Color  : film.y_axis == 247 ? sal5Color : film.y_axis === 295 ? sal6Color : film.y_axis === 343 ? sal7Color : film.y_axis === 391 ? sal8Color 
             : film.y_axis === 439 ? sal9Color : film.y_axis === 487 ? sal10Color :  '',
             borderRadius:'4px'   }}> 
            <FilmWidget salNr={film.sal} saveEditBtn={saveFilmEdit} 
            internetOrderAll={saveFilmEdit && internetOrder && true } internetSellAll={saveFilmEdit && internetSell && true}
            internetShowAll={saveFilmEdit && internetShow && true} ticketSellAll={saveFilmEdit && ticketSellSell && true}
            ticketOrderAll={saveFilmEdit && ticketSellOrder && true}
             film={film.name} end_Time={film.end_time} start_Time={film.start_time} showTime={showTime && startTime}
            extendedBackColor={film.y_axis == 55 ? sal1Color2 : film.y_axis == 103 ? sal2Color2  :film.y_axis == 151 ? sal3Color2:
             film.y_axis == 199 ? sal4Color2  : film.y_axis == 247 ? sal5Color2 : film.y_axis === 295 ? sal6Color2 : film.y_axis === 343 ? sal7Color2 : film.y_axis === 391 ? sal8Color2 
             : film.y_axis === 439 ? sal9Color2 : film.y_axis === 487 ? sal10Color2 : ''} 

             timelineBackColor={film.y_axis == 55 ? sal1Color : film.y_axis == 103 ? sal2Color  :film.y_axis == 151 ? sal3Color:
              film.y_axis == 199 ? sal4Color  : film.y_axis == 247 ? sal5Color : film.y_axis === 295 ? sal6Color : film.y_axis === 343 ? sal7Color : film.y_axis === 391 ? sal8Color 
              : film.y_axis === 439 ? sal9Color : film.y_axis === 487 ? sal10Color : ''}
            
              priceDivBackColor={film.y_axis == 55 ? sal1Color3 : film.y_axis == 103 ? sal2Color3  :film.y_axis == 151 ? sal3Color3:
                film.y_axis == 199 ? sal4Color3  : film.y_axis == 247 ? sal5Color3 : film.y_axis === 295 ? sal6Color3 : film.y_axis === 343 ? sal7Color3 : film.y_axis === 391 ? sal8Color3 
                : film.y_axis === 439 ? sal9Color3 : film.y_axis === 487 ? sal10Color3 : ''}
              /> 
              
             </div>
       : ''
           )
  })
       return ff     
}

const films = generateFilms() ;

  return (
    <main  programactive='active'  style={{width:'1920px', position:'relative',margin:'auto'}}>
          <Header programActive='active'/>
          
          <section id='tes' className='programContainer' style={{display:'grid', gridTemplateColumns: showFilm ? 'auto 62px ' : 'auto 62px'}}>
            
               <div className='programContainer_main'>

              <button className='saveBtn' >GEM I BILLETSYSTEM </button>
          <Modal
        shown={modalShown}
        close={() => {
          toggleModal(false);
        }}
      >
       
      </Modal>
<br />
<br />
<div style={{width:'58%',display:'flex',justifyContent:'space-between',border:'1px '}}>

  <p className='program_title'>Programlægning</p>
  <DatePicker />
  
</div>

<div  style={{display:'flex', justifyContent:'space-between',marginTop:'10px',marginRight:'4px'}}>
  <div>
    <button className='daysBtn'> DAGSVISNING</button>
    <button className='weeksBtn'>UGEVISNING </button>
  </div>

  <div style={{display:'flex',position:'relative'}}>
    <button className='markAllBtn' onClick={handleSelectAll}>MARKER ALLE</button>
    {copyChosen ? <section>
      <div className='editBtn' style={{display:'flex'}}>
      <button style={{width:'90px',color:'#982f2f',textDecoration:'underline', fontWeight:'bold'}} onClick={handleCopy}>ANNULLER</button> 
       <p style={{width:'1px',backgroundColor:'#982f2f',height:'39px'}}></p>
       <button style={{width:'80px',color:'#982f2f',textDecoration:'underline',fontWeight:'bold'}} >KOPIER</button>
      </div>
      <div className='copyDiv'>
        <p style={{color:'#46484c',fontWeight:'600',paddingLeft:'5px',marginBottom:'5px'}}>Kopier valgte</p>
        <div style={{border: startDatoCal ? '1px solid #982f2f' : '',backgroundColor:'#fff',width:'155px',height:'43px',display:'flex', justifyContent:'space-between',borderRadius:'5px',padding:'5px'}}>
           <div style={{}}>
               <p style={{fontSize:'12px', color:'#46484c', fontWeight:'bold'}}>Startdato:</p> 
               <p style={{fontSize:'12px', color:'#46484c',marginTop:'-20px'}}>{start_dato}</p> 
           </div>
           <button style={{marginTop:'-4px'}} onClick={handleStartDatoCal}><img src={calendar} alt="" /></button>        
        </div>
        <div style={{border: endDatoCal  ? '1px solid #982f2f' : '',backgroundColor:'#fff',width:'155px',height:'43px',display:'flex', justifyContent:'space-between',borderRadius:'5px',padding:'5px',marginTop:'2px'}}>
           <div>
               <p style={{fontSize:'12px', color:'#46484c', fontWeight:'bold'}}>Slutdato:</p> 
               <p style={{fontSize:'12px', color:'#46484c',marginTop:'-20px'}}> { end_dato ?  end_dato : 'Tilføj dato(er)' }</p> 
           </div>
           <button style={{marginTop:'-4px'}} onClick={handleEndDatoCal}><img src={calendar} alt="" /></button>        
        </div>
      </div>
    </section>
    : 
    <button className='copyBtn' onClick={handleCopy}>  
     KOPIER VALGTE
    </button>
    }
    {startDatoCal ? (
    <div className='startDatoCal'>
        <div style={{height:'35px',backgroundColor:'#f1f5f9',display:'flex',justifyContent:'space-between',margin:'4px',}}>
           <p style={{fontSize:'12px',fontWeight:'bold',padding:'8px'}}>Startdato</p>
           <button><img src={iconCross} alt="" onClick={handleStartDatoCal}/></button>
        </div>
        <div className="weekdays"  >
                            <span className="days">Man</span>
                            <span className="days">Tirs</span>
                            <span className="days">Ons</span>
                            <span className="days">Tors</span>
                            <span className="days">Fre</span>
                            <span className="days">Lør</span>
                            <span className="days">Søn</span>
                          </div>
        <DayPicker locale={da} mode="single" ISOWeek  showOutsideDays  showWeekNumber selected= {startDate}captionLayout="dropdown" fromYear={2022} toYear={2030} onSelect={setStartDate} onDayClick={handleStartDatoCal}  disabled={disabledDays}/>
    </div>
  ) : ''}

   {endDatoCal ? (
    <div className='endDatoCal'>
        <div style={{height:'35px',backgroundColor:'#f1f5f9',display:'flex',   justifyContent:'space-between',margin:'4px',}}>
           <p style={{fontSize:'12px',fontWeight:'bold',padding:'8px'}}>Slutdato</p>
           <button style={{fontSize:'12px', textDecoration:'underline',color:'grey',}}>Vælg samme som startdato</button>
           <button><img src={iconCross} alt="" onClick={handleEndDatoCal}/></button>
        </div>
        <div className="weekdays"  >
                            <span className="days">Man</span>
                            <span className="days">Tirs</span>
                            <span className="days">Ons</span>
                            <span className="days">Tors</span>
                            <span className="days">Fre</span>
                            <span className="days">Lør</span>
                            <span className="days">Søn</span>
                          </div>
        <DayPicker  mode="single" showOutsideDays  showWeekNumber selected= {endDate} captionLayout="dropdown" fromYear={2022} toYear={2030}  ISOWeek
          onDayClick={handleEndDatoCal} onSelect={setEndDate}   
          disabled={disabledDays}/>
    </div>
  ) : ''}

    <button className='deleteBtn'> SLET VALGTE</button>
    {editChosen ?
    <section>
    <div className='editBtn' style={{display:'flex'}}>
       <button style={{width:'100px',color:'#982f2f',textDecoration:'underline'}} onClick={deleteTheEdit}>ANNULLER</button> 
       <p style={{width:'1px',backgroundColor:'#982f2f',height:'39px'}}></p>
       <button style={{width:'60px',color:'#982f2f',textDecoration:'underline'}} onClick={saveTheEdit}>GEM</button>
    </div>
    <div className='editDiv'>
       <div style={{paddingLeft:'10px'}}>
           <p style={{color:'#46484c',fontWeight:'600'}}>Rediger Valgte</p>

           <div style={{marginTop:'-5px'}}>
               <div onClick={handleInternetOrder} style={{display:'flex',marginTop:'0px',border:'1px  ',height:'14px',marginBottom:'5px',justifyContent:'space-between',width:'145px'}}>
               <img  src= { internetOrder ?  small_checkmark: small_square }  alt=""  />
                 <p style={{fontSize:'12px',color:'#46484c',marginTop:'-3px',marginLeft: '-18px'}}>Internetbestilling </p>
                {internetOrder ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> }
               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'14px',marginBottom:'5px',border:'1px  ',justifyContent:'space-between',width:'145px'}} onClick={handleInternetSell}>
               <img  src= { internetSell ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',marginTop:'-3px',marginLeft:'-42px',color:'#46484c'}}>Internet salg</p>
                 {internetSell ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> }

               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'14px',marginBottom:'5px',border:'1px ',justifyContent:'space-between',width:'145px'}} onClick={handleInternetShow}>
               <img  src= { internetShow ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',marginTop:'-3px',marginLeft:'-45px',color:'#46484c'}}>Intervisning</p>
                 {internetShow  ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> }

               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'14px',marginBottom:'5px',border:'1px  ',justifyContent:'space-between',width:'145px'}} onClick={handleTicketSellSell}>
               <img  src= { ticketSellSell ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',marginTop:'-3px',marginLeft:'-32px',color:'#46484c'}}>Salg i billetsalg</p>
                 {ticketSellSell ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> }

               </div>

               <div style={{display:'flex',margin:0,padding:0,height:'14px',marginBottom:'5px',border:'1px ',justifyContent:'space-between',width:'145px'}} onClick={handleTicketSellOrder}>
               <img  src= { ticketSellOrder ?  small_checkmark : small_square }  alt=""  />
                 <p style={{fontSize:'12px',padding:'0px',marginTop:'-3px',marginLeft:'-5px',color:'#46484c'}}>Bestilling i billetsalg</p>
                 {ticketSellOrder ? <img src={ alertIcon} alt='alert-icon' /> : <p style={{color:'transparent'}}>fh</p> }

               </div>

           </div>
          
       </div>
       <div style={{border:'1px ',backgroundColor:'#dde6f0',marginTop:'15px',paddingLeft:'10px',paddingTop:'4px',borderTopLeftRadius:'6px', borderTopRightRadius:'6px'}}>
               <p style={{margin:0,fontSize:'12px',color:'#46484c'}}>Prisgrupper</p>
               <p style={{margin:0,fontSize:'12px',color:'#46484c'}}>Billettyper</p>
               <p style={{margin:0,fontSize:'12px',color:'#46484c',paddingBottom:'3px'}}>Forestillingstype</p>
           </div>
           
           <div style={{backgroundColor:'#dde6f0',marginTop:'1px',borderBottomLeftRadius:'6px',borderBottomRightRadius:'6px',paddingLeft:'3px',height:'30px'}}>
            <button style={{fontSize:'12px',color:'#46484c',textDecoration:'underline'}} onClick={() => {
                          toggleModal(!modalShown);
                      }}>REDIGER</button>
           </div>
    </div>
    </section>
        :
        <button className='editBtn' onClick={handleEdit}> REDIGER VALGTE </button>
   }
  </div>
</div>

<br />
<br />

<ScaleLoader color="#982f2f" loading={isloading} height={100}  cssOverride={override}/>

<div  className='salContainer' >
  {numberOfSal.sort(function (a,b) { 
       return a.name > b.name ? 1 : -1
      } ).map((sal) => (
    <p className='sal_name' >{sal.name}</p>
  ))}
   
</div>
    
     <div style={{border:'1px', width:'100%',   padding: '0'}} onDragOver={(e) => handleDragOver(e)} onDrop={(e)=> {handleOnDrop(e)}}  >
    
    
     <table className='table table-bordered'   id="program_table" style={{width:'1698px'}}>
      { isloading === false && 
      <thead id='t_head'>
        <tr style={{height:'52px',borderTop:'3px solid #dee0e3',borderBottom:'3px solid #dee0e3',}}>
          {timeStamp.map((t) => (
                 <td className='formattedTD' style={{position:'relative',borderLeft: t[0] ? '1px solid lightgrey' : ''}}>
                     <span style={{position:'absolute',top:'13px',left:'-21px'}}>{t}:00</span>
                </td>
          )
          )}        
         <p className='twelve' style={{backgroundColor:modalShown?'':'#fff',paddingTop:'0px',color:modalShown?'#524f4f':'#8d8d8d'}}> <span style={{color:'#8d8d8d'}}>{te}:00</span> </p>
         
        </tr>
      </thead>
    }
      <tbody id='t_body' >
        {numberOfSal.map((sal,index) => (
         <tr style={{height:'48px',}}>
           {timeStamp.map((t) => (
            <td style={{borderLeft: t[0] ? '1px solid lightgrey' : ''}}></td>
           ))}         
        </tr>        
        ))}     
      </tbody>
 
     {showG === true ?
    
      <div  style={{position:'absolute',top:`${y}px`,left:`${x}px`,height:'45px',  color:'white' }}> 
      <span style={{position:'absolute', top:'-15px',fontSize:'12px',color:'#46484c'}}>{startTime}</span>
      <div style={{height:'43px', backgroundColor:'#982f2f',
      width:`${(pixelWidthPerMin*filmWidth).toString().split(".")[0] + 'px'}`,zIndex:9 ,padding:'10px',color:'white' }}>{draggedFilm}</div>     
      </div>     
       : ''
        }  
  
      {films}

      {ProgrammedEvents && ProgrammedEvents.map((event) => {
           
            return <div draggable id={event.id} onDrag={(e) => dragExisting(e)} onDragStart={(e) => draggingDiv(e)} 
            style={{position:'absolute',top:`${event.y_axis}px`,left:`${event.x_axis}px`,width:`${(pixelWidthPerMin*event.length).toString().split(".")[0] + 'px' }`, backgroundColor: event.index === 0 ? sal1Color : event.index === 1 ? sal2Color : event.index === 2 ? sal3Color : event.index === 3 ? sal4Color : event.index === 4 ?  sal5Color : event.index === 5 ? sal6Color : event.index === 6 ? sal7Color : event.index === 7 ? sal8Color :
            event.index === 8 ? sal9Color : event.index === 9 ? sal10Color : '', 
            }}>
            <FilmWidget arrtypename={event.ArrTypeName} film={event.name} salNr={event.sal}  saveEditBtn={saveFilmEdit} theWidth={(pixelWidthPerMin*event.length).toString().split(".")[0]} price={event.priceGroups.length === 1 ?  event.priceGroups[0].Price : 'more prices'} 
            webresinfo={event.webReserveInfo} webbuyinfo={event.webbuyInfo} webshowinfo={event.webshowInfo} buyenabled={event.buyEnabled} resenabled={event.reserveEnabled}
            internetOrderAll={saveFilmEdit && internetOrder && true } internetSellAll={saveFilmEdit && internetSell && true}
            internetShowAll={saveFilmEdit && internetShow && true} ticketSellAll={saveFilmEdit && ticketSellSell && true}
            ticketOrderAll={saveFilmEdit && ticketSellOrder && true}
            end_Time={event.end} start_Time={event.start}  

            extendedBackColor= {event.index === 0  ? sal1Color2 : event.index === 1 ? sal2Color2: event.index === 2 ? sal3Color2 : event.index === 3 ? sal4Color2 : event.index === 4 ? sal5Color2 : event.index === 5 ? sal6Color2 : event.index === 6 ? sal7Color2 : event.index === 7 ? sal8Color2 : event.index === 8 ? sal9Color2 : event.index === 9 ? sal10Color2:  ''} 

            timelineBackColor = {event.index === 0 ? sal1Color : event.index === 1 ? sal2Color : event.index === 2 ? sal3Color : event.index === 3 ? sal4Color  :event.index === 4 ?  sal5Color : event.index === 5 ? sal6Color  : event.index === 6 ? sal7Color : event.index === 7 ? sal8Color : event.index === 8 ? sal9Color : event.index === 9 ? sal10Color : ''}

            priceDivBackColor={event.index === 0 ? sal1Color3 : event.index === 1 ? sal2Color3 : event.index === 2 ? sal3Color3 : event.index === 3 ? sal4Color3 : event.index === 4 ? sal5Color3 : event.index === 5 ? sal6Color3 : 
            event.index === 6 ? sal7Color3 : event.index === 7 ? sal8Color3 : event.index === 8 ? sal9Color3 : event.index === 9 ? sal10Color3  : '' }

            /> 
         </div>
            
  })}


     </table>
    
                </div> 

           </div>
      { showFilm === false ?
               <aside className='programContainer_aside' >
                 <p className='choose' >Vælg</p>
                 <p className='film'>film</p>
                 <div className='vector'></div>
                 <div className='doubleArrows' style={{marginTop:'30vh'}} onClick={handleShowFilm}>
                    <img src={arrows} alt="" style={{transform:'rotate(90deg)'}}/>
                    <img src={arrows} alt="" style={{transform:'rotate(90deg)', marginLeft:'-5px'}}/>

                </div>
               
               </aside> : ''
                }
          </section>
          <div id='t'  style={{display: showFilm ? 'grid' : 'none', gridTemplateColumns: showFilm ? '1fr 1fr' : '',marginTop:'1px'}} className='chooseFilmContainer' >
          <aside className='programContainer_aside' >
                 
               <div className='vector'></div>
               <div  style={{marginTop:'42vh', transform:'rotate(180deg)',marginRight:'20px'}} onClick={handleShowFilm}>
                    <img src={arrows} alt="" style={{transform:'rotate(90deg)'}}/>
                    <img src={arrows} alt="" style={{transform:'rotate(90deg)', marginLeft:'-5px'}}/>
               </div>
               
               </aside>
                <div style={{ width:'338px',paddingLeft:'30px'}}>
                  <button style={{float:'right',border:'none', background:'none'}} onClick={handleShowFilm}>
                  <img src={close} alt='close'/> </button>
                  <br />
                  <br />
                  <br />
                <p className='choose_film' >Vælg film</p>
             
                <div style={{position:'relative'}}>
                <input  className='search_film' placeholder='Søg efter film'  value={q}
                            onChange={(e) => setQ(e.target.value)}/> 

                <span style={{position:'absolute', top:'7px',left:'10px'}}>
                  <img src={searchIcon} alt='search' height='20px'/></span>
              
                </div>
                <br />
                <br />
               
                <div className='image_container'>
                   {search(data).map((movie,i) => (
                     <div draggable onDragEnd={(e) => imgDragEnd(e)}  className='imgCont'  onDragStart=   {(e) => handleOnDrag(e,movie.name)} >
                    <img   src={`https://ebillet.dk/poster/`+movie.poster.large} id={i} name={movie.name} duration={movie.length}  alt={movie.name} width='125px' height='185px' />  
                    <span className='tooltiptext'>{movie.name}</span>                      
                    </div>
                    ))}               
                </div> 
     
            </div>
          </div>
      </main>
  )
}

export default Program