import React, {useState, useEffect} from 'react'
// import "../components/css/messages.css";
import "./incidents.css";

import downArrow from "../../images/arrow-down.svg";
import whiteArrow from '../../images/white-arrow.svg'
import { useNavigate } from "react-router-dom";


function Incidents({item}) {

  const theme = localStorage.getItem('theme')

    const [isShown, setIsShown] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const currentYear = new Date().getFullYear();
    // const navigate = useNavigate();
    const key = sessionStorage.getItem("key");
    const [messages, setMessages] = useState([]);
    // const [sendMSG, setSendMSG] = useState('');
    // const [solved, setSolved] = useState(false);
   const [incidentMsg, setIncidentMsg] = useState('');
   const [isChecked, setIsChecked] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const [solved, setSolved] = useState(false);
   const [solvedTime, setSolvedTime] = useState('');
   const [smsAdded, setSmsAdded] = useState(false);
  //  const [addMoreMsg, setAddMoreMsg] = useState(false);

// console.log(item)
    const handleClick = (event) => {
        // 👇️ toggle shown state
        setIsShown((current) => !current);
        // setIcon((current) => !current);
        setIsActive(current => !current);
        // 👇️ or simply set it to true
        // setIsShown(true);
      };

      function handleOnChange(event) {
        const isChecked = !!event.target.checked;
        setIsChecked(isChecked);
        // Fire callback
        // if (onChange) onChange(isChecked);
      }
//  console.log(isChecked)
     
    //   console.log(messages)

    function addIncientInfo(e) {
        e.preventDefault();
        const endpoint = `https://biograf.ebillet.dk/systemnative/incident.asmx/man_addincidentinfo?Key=${key}&IncidentNo=${item.No}&Information=${incidentMsg}&Solved=${isChecked}`;
        fetch(endpoint, {
          method: "GET",
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            // console.log(result);
            if (result.Result === true) {
              // setIsShown(false);
              setSmsAdded(true);
              setIncidentMsg('');
              setSolved(isChecked)
              setIsChecked(false)
              // isChecked === false
              // window.location.reload()
              // navigate('/messages')
            }
          });
      }
    
      useEffect(() => {
    
        fetch(`https://biograf.ebillet.dk/systemnative/incident.asmx/man_getincident?Key=${key}&IncidentNo=${item.No}`, {
          method: "GET",
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            setSolvedTime(result.incidentItem.Solved.slice(11,16))
            setMessages(result.Messages);
            // if (!result) {
            //   navigate("/");
            // }
          });
           
      }, [key, item.No, smsAdded, item]);

      function textinput(e) {
        setIncidentMsg(e.target.value)
        e.target.style.height = (e.target.scrollHeight) + "px";
      }

      useEffect( () => {
        if(item.Solved !== '0001-01-01T00:00:00'  )  {
          setIsChecked(true)
          setDisabled(true)
        }
      },[setIsChecked, setDisabled, solved, item.Solved, currentYear])

      
  return (
    <>
    <div className="incidents-containe" >
        <div className="box" style={{border: theme === 'dark' ? '1px solid #6c6c6c' : ''}}>
         
          <div className="shown-menu">
                <div className="shown-left">
                  <p>{item.Name}</p>
                  <p style={{ textAlign:'right', width:50}}>{item.No}</p>
                 </div>

              <div className="shown-right">
                 
                 <p>{item.Created.slice(0,10).split("-").reverse().join("-")}</p>
                
                 <p>{item.Solved === '0001-01-01T00:00:00'  ? 'Ikke Løst': `Løst kl. ${solvedTime}` }</p>
              </div>

              </div>
          { isShown &&
              <main style={{paddingBottom:'10px',}}>
              <div style={{ position:'relative',borderTop:theme === 'dark' ? '1px solid #6c6c6c':'1px solid lightgrey', display:'flex', justifyContent:'space-between', paddingBottom:15, paddingTop:15,paddingLeft:'30px' }}>

                  <section className='besked-container'>    
                       <textarea className='besked' onChange={(e) => textinput(e)} 
                       style={{backgroundColor: theme === 'dark' ? '#65696c' : '',color:theme === 'dark' ? '#fff':'#101010'}} value={incidentMsg} 
                      
                        cols="70" rows='1'  placeholder='Skriv din besked'/>
                        <button className='send-sms' onClick={addIncientInfo} style={{ width: "105px",backgroundColor: theme === 'dark' ? '#4e5052' :'#982f2f',color:'#fff',border: theme === 'dark' ? '1px solid #6c6c6c' : '',letterSpacing:'0.56px'}}>SEND SMS</button>   
                   </section>

                    <div className='solved-checkbox'>
                   <label htmlFor="" className='løst' >Hændelse løst    </label>

                   <input type="checkbox" disabled={disabled}  onChange={handleOnChange}     
                      checked={isChecked || solved === true } id='solved' /> 
                               
                   </div>
                               
              </div>
              {messages &&
                     <div className="sent-smsHeader"  style={{opacity: theme === 'dark' ? '0.6' : '',borderTop: theme === 'dark' ? '':'1px solid #dee0e3'}} >
                           <p> Sendte SMS'er</p>
                           <p> Sendt   </p>
                          </div>
                          }
              { messages &&   messages.map((msg, index) => (
                    <>
                        
                          <div key={index} className="sent-sms" >
                            <p style={{width:'70%',padding:'6px',lineHeight:'1.25'}}>"{msg.Message}."</p>
                            <p style={{paddingRight:'5%'}}>{msg.Sendt.slice(0,10).split("-").reverse().join("-").slice(0,10)}  kl. {msg.Sendt.slice(11,16)}</p>
                          </div>
                        </>
                     ) ) } 
                    
              </main>
               }
           
        <button className="img-btn" onClick={() => handleClick()}>
              <p className={isActive ? "rotated" : ""}>
                {" "}
                <img src={theme === 'dark' ? whiteArrow :downArrow} alt="" />{" "}
              </p>
            </button>
        </div>  
      </div> 
    
      </>
      
  )
}

export default Incidents