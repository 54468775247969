import React, {useEffect, useState} from "react"
import moment from "moment";

import calendar from "../images/Icon-Calendar.svg";
import whiteCalendar from '../images/whiteCalendar.svg'
import downArrow from "../images/arrow-down.svg";
import whiteArrow from '../images/white-arrow.svg'
import close from "../images/close.png";
import '../components/css/workplan.css'

import { useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


import { format , isSameMonth } from "date-fns";
import { DayPicker } from "react-day-picker";
import { addMonths , addYears , subYears} from "date-fns";

import "react-day-picker/dist/style.css";
import da from "date-fns/locale/da";
import { weekdays } from "moment/moment";
import { weekdaysMin } from "moment/moment";

function WorkPlan() {
  const navigate = useNavigate();

   let key = sessionStorage.getItem('key');
  let theme = localStorage.getItem("theme")
   const [data, setData] = useState([]);
   const [chosenBio, setChosenBio] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

const [filmId, setfilmId] = useState('');

   const [selected, setSelected] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const defaultFilmID = sessionStorage.getItem("default-filmID");

  const today = new Date();
  const nextMonth = addMonths(new Date(), 1);
  const nextYear = addYears( new Date(), 1)
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState( nextYear );
  
  const [showCalendar, setShowCalendar] = useState(false);

   const [biograf, setBiograf] = useState('');
   const [film, setFilm] = useState('');    // moment(selectedDate).format().split("T")[0];

  let footer;
  // console.log(selectedDate)
   
  if (selectedDate) {
    footer = (
      <span style={{}}>
        {moment(selectedDate).locale("da").format("dddd")},{" "}
        <span style={{ textTransform:'lowercase' }}>
          {format(selectedDate, "d. ")}{" "}
          {moment(selectedDate).locale("da").format("MMMM")}{" "}
          {format(selectedDate, "yyyy")}
        </span>
      </span>
    );
  }
  
  let header ;
  if (selectedDate) {
    header = (
      <span style={{padding:0, paddingTop:-10,}}>
        {moment(selectedDate).locale("da").format("dddd")}
        <span style={{ paddingRight: 0, paddingLeft:5, textTransform:'lowercase'}}> {" "}d.{" "}
          {format(selectedDate, "d. ")}{" "}
          {moment(selectedDate).locale("da").format("MMMM")}{" "}
          {format(selectedDate, "yyyy")}
        </span>
      </span>
    );
  }

  function handleClick() {
    setShowCalendar(true);
  }

  function closeCalendar() {
    setShowCalendar(false);
   
  }

  const getNextDate = () => {
    setSelectedDate(moment(selectedDate).add(1,'days')._d)
};


const getPreviousDate = () => {
    setSelectedDate(moment(selectedDate).subtract(1,'days')._d)
};

//the code below limits the width of the options tag
const o = document.querySelectorAll('option')
const lenMax = 35
o.forEach(e => {
  if (e.textContent.length > lenMax) {
    e.textContent = e.textContent.substring(0,lenMax) + '...';  
  }
  
})

   useEffect(() => {
    document.title = "Arbejdsplan - ebillet_admin";
  }, []);

  // console.log(moment().format("hh:mm"))

    useEffect(() => {
        const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getorganizers?Key=${key}`;
        if (key) {
          fetch(endpoint, {
            method: "GET",
            headers: {
              ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
            },
          })
            .then((res) => res.json())
            .then((result) => {
              // console.log(result, 'antal biografer')
              if (result.result === 0) {
                navigate("/");
              }
              if (result.length === 1) {
                setChosenBio(true);
                setfilmId(result[0].No)
              }
              
                setFilm(result);
              
              // if there is only bio then it will be selected by default
              if (result.length === 1) {
               
                sessionStorage.setItem("default-filmID", result[0].No);
              }
            });
        }
      }, [key]);

// console.log(filmId)

    const handleSelect = (e) => {
        let g = e.target.value;
        setBiograf(g.split(" ")[g.split(" ").length - 1]);
        setSelected(e.target.value);
    
        sessionStorage.setItem("workplanBio", e.target.value);
      };

  let movies = sessionStorage.getItem("workplanBio");
  let date = moment(selectedDate).format().split("T")[0];
  
  const org = movies && movies.split(" ")[movies.split(" ").length - 1];

  // console.log(moment(moment(new Date()).format("YYYY-MM-DD")).isAfter(date));

  const getDatas = (check,runAgain) => {
       setIsLoading(true);
       if(check && moment().format("HH")==="00" && moment().format("mm")>="00" && moment().format("mm")<="10" && moment(moment(new Date()).format("YYYY-MM-DD")).isAfter(date) === true )
       {
          date=moment().format("yy-MM-DD");
          setSelectedDate(new Date())
       }
     

        fetch( `https://biograf.ebillet.dk/systemnative/report.asmx/man_worklist?Key=${key}&strOrganizers=${defaultFilmID? defaultFilmID :org}&Date=${date}`,
        {
            method: "GET", 
            headers: {
              ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
            },
          }
        )
        .then((res) => res.json())
        .then((result) => {
          
          if(result.key === null && result.result === 0 && result.data === null){ 
          // when app pool reloades so new KEY being feched here
          let userN = sessionStorage.getItem('user');
          let kodeN = sessionStorage.getItem('password')
          const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_login?User=${userN}&PW=${encodeURIComponent(kodeN)}`;
          fetch(endpoint, {
            method: "GET",
      
            headers: {
              ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
            },
          })
          .then((res) => res.json())
          .then((result) => { 
            // console.log(result, 'result from inside')
            sessionStorage.setItem("key", result.key);
            if(runAgain) {
              getDatas(true,false);
            }
            
          })
        }

        setIsLoading(false);

          if(result.Message) {
          setNoData(true);
          }

          if(!result.length) { setNoData(true) }
          else {
            setData(result);
            setIsLoading(false);

            setNoData(false);

          }

        })

      }
   
//  every 10 minutes a new api call been made
useEffect(() => {
 
  getDatas();

}, [date,org,selectedDate,filmId,biograf,key ]);

// console.log(org,date,selectedDate,filmId)

const [added, setAdded] = useState('');
useEffect(() => {
  const total = data.reduce((acc, row) => acc + row.TicketCount, 0);
  setAdded(total)
}, [data]);

//react countdown timer
const [minut, setMinut] = useState('');

const renderTime = ({ remainingTime }) => {
setMinut(Math.round((remainingTime/600)*100)+'%')
  const minutes = Math.floor(remainingTime / 60)
  const seconds = remainingTime % 60
   
  
  return (
   
      <p className="timeRemained" style={{color: theme === 'dark' ? '#fff' : '#800409',   backgroundColor: theme === 'dark' ? '' : 'white'}}><i>
     {minutes<10?'0':''}{minutes}:{seconds<10?'0':''}{seconds}
      </i></p>
     
  );
};
const [isOpen, setIsOpen] = useState(false);
const [chosenCinema, setChosenCinema] = useState('Vælge biograf');

function handleArrrow() {
  // setIsOpen((current) => !current);
}
function handleCheck (e, item) {
  setIsOpen(false)

  setChosenCinema(e.name)
}

  return (
    <main className="rapport">
        <div style={{display:'flex', justifyContent:'space-between'}}>
      <p style={{ fontSize:'25px', color: theme === 'dark' ? '#fff' : '#000',height:'22px',fontWeight:600 }}>Arbejdsplan</p>
      <div>
      {data && data.length ?
         <>
         <div className="countdownContiner" style={{color: theme === 'dark' ? '#fff' : '#800409'}}> Opdateres om 

         <div>
         <div className="countdownDiv">
         <CountdownCircleTimer isPlaying 
         duration={600}
         size={0}
         colors={["#982f2f", "#F7B801", "#A30000", "#A30000"]}
         colorsTime={[10, 6, 3, 0]}
         onComplete={() =>                 
           { 
            getDatas(true,true);
              return {shouldRepeat: true, }
              }
             }
          >
          

           {renderTime}
          </CountdownCircleTimer> </div>
          
         
    </div>

        
         min.
          </div>
          
          <div className="progressbarContainer" style={{display:'flex', justifyContent:'flex-end'}}>
           <div class="progress"  style={{ width:'180px', height:'8px', borderRadius:'0',  backgroundColor:theme === 'dark' ?'#65696c' :'white', border:theme === 'dark' ? '':'1px solid lightgrey', justifyContent:'start'}}>
  <div class="progress-bar" style={{width:minut, backgroundColor:'#982f2f', margin:'1px'}} role="progressbar" aria-label="Basic example"   aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
</div>
</div>
</> : ''
}
      </div>
      </div>
      <br />
      <div className="filmDate" style={{position:'relative',top:'5px'}}>
         <form className="form">
         <div className="biografBox">
            <p className="biograf"  style={{color: theme === 'dark' ? '#fff' : '#46484c',opacity: theme === 'dark' ? '0.6' : '',height:'22px'}}>Biograf </p>
          
            <span className="custom-select-line"></span>

             <select
              className="text-truncate form-select"
              style={{height:'40px',backgroundColor: theme === 'dark' ? '#65696c' : '', color: theme === 'dark' ? '#fff' : '', border:  '1px solid #8d8d8d' , backgroundImage:`url(${ theme === 'dark' ? whiteArrow : downArrow})`,backgroundRepeat:'no-repeat',}}
              onChange={handleSelect}
              value={movies ? movies : selected}
            >
              {film &&
                film.map((item) => {
                  let bb = item.City + " - " + item.Name + " " + item.No;
                  return (
                    <option key={item.id} value={bb}  className="text-truncate"
                    style={{
                      maxWidth: 200,
                      marginLeft: '10px',
                      fontSize: "16px",
                      
                      
                    }}>
                      {item.City} - {item.Name}
                    </option>
                  );
                })}
            </select> 
          </div>
         </form>

         <div className="calendar-container" >
        <img src={showCalendar? '' : theme === 'dark' ? whiteArrow : downArrow} alt="" className="left-arrow arrows" onClick={getPreviousDate} />
        <p style={{color: theme === 'dark' ? '#fff' : '#46484c',marginTop:'-8px'}}>{footer}</p>
        <img
          src={ theme === 'dark' ?  whiteCalendar :calendar} className="calendar-icon"
          
          alt=""
          onClick={handleClick}
        />

        <img src={showCalendar? '' :theme === 'dark' ? whiteArrow : downArrow} alt="" className="right-arrow arrows" onClick={getNextDate}/>
     

      {showCalendar ? (
        <div
        className="Calendar"
          // className={showCalendar ? "Calendar slide-down" : "Calendar slide-up"}
        >
          <figure style={{ textAlign: "right", padding:10 }}>
            <img
              src={close}
              alt=""
              style={{ cursor: "pointer" , border:'1px solid white', backgroundColor:'white'}}
              onClick={closeCalendar}
            />
          </figure>
          <div className="weekdays" style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>
          <span className="days">Man</span>
          <span className="days">Tirs</span>
          <span  className="days">Ons</span>
          <span className="days" >Tors</span>
          <span className="days" >Fre</span>
          <span className="days" >Lør</span>
          <span className="days">Søn</span>
        </div>
          <DayPicker
            locale={da}
            mode="single"
            fromYear={2015} 
            toYear={2030} 
            selected={selectedDate}
            selectedDays 
            onSelect={setSelectedDate}
            month={month}
            year = {year}
            onYearChange = {setYear}
            onMonthChange={setMonth}
            captionLayout="dropdown" 
            weekdaysLong ={weekdaysMin}
            showWeekNumber = {weekdays}
            todayButton={'Gå til i dag'}
            showOutsideDays
            onDayClick={closeCalendar}
            fixedWeeks
             
           
          />
          <br />
          <div style={{ textAlign: "center" }}>
            <button
              disabled={isSameMonth(today, month)}
              onClick={() => {setMonth(today);setYear(today)}}
              
              style={{
                border: "none",
                backgroundColor:'white',
                // background: "transparent",
                fontSize: 12 + "px",
                fontWeight: "bold",
              }}
            >
              <u> GÅ TIL I DAG </u>
            </button>
          </div>
          <br />
        </div>
      ) : (
        ""
      )}
 </div>



      </div>

     
      <br />
     
      {isLoading && <h2>Henter...</h2>}
      {noData && <h2>Ingen forestillinger i valgte biograf.</h2>}

 {data.length && !noData ?(
<>
      <p style={{fontSize:'18px', fontWeight:'bold', color: theme === 'dark' ? '#fff' : '#46484c',opacity:theme === 'dark' ? '0.6' : '',letterSpacing:'0.36px',marginTop:'35px'}}>{header}</p>
      <div className="workplanHeader">
        <p style={{color:  '#8d8d8d'}}>Film</p>
        <p style={{color:  '#8d8d8d'}}>Indluk</p>
        <p style={{color:  '#8d8d8d'}}>Udluk</p>
        <p style={{color:  '#8d8d8d'}}>Sal</p>
        <p style={{color:  '#8d8d8d'}}>Billetter</p>
        
      </div>
     
<section style={{border:'1px ', borderBottom: theme === 'dark' ?  '1px solid #6c6c6c' : '1px solid #dee0e3'}}>
{ data.map((item, index) => {
           
      return  <>
       <div key={index} className="workplanBody" style={{}} >
             <p className=" trunc" style={{paddingTop:5}} >{item.MovieName}</p>
             <p className={item.Start === null? 'noTime' : "indluk" }>{item.Start === null ? ' –' : item.Start}</p>
             <p  className={item.End === null? 'noTime' : "udluk" }>{item.End === null ? ' –' : item.End}</p>
             <p style={{textAlign:'right', width:'15px', paddingTop:5}}>{item.LocName.slice(3,6)}</p>
             <p style={{textAlign:'right', width:'35px', paddingTop:5}}>{item.TicketCount}</p>
            
        </div>
   
        </>
      }
      ) }
    
</section>


 <div className="workplanBody" >
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p style={{marginLeft:-5, textAlign:'right',width:'40px' }}>{added/2}</p>
            
     </div> 

</> ) : ('')
}  
            <br />
    </main>
  );
}

export default WorkPlan;
