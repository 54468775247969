import React, { useState } from "react";
import arrow from '../images/small_arrow.svg';
import whiteArrow from '../images/smallWhite.svg'

function DriftSms() {
  const key = sessionStorage.getItem("key");
  const [code, setCode] = useState("45");
  const [tlfNumber, setTlfNumber] = useState("");
  const [name, setName] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");

  const [msgFrmApi, setMsgFrmApi] = useState("");
  let theme = localStorage.getItem("theme");

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  // console.log(code)
  const handleNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTlfNumber(value);
  };

  const handleNameChange = (e) => {
  //  console.log(e)
    //const nam = e.target.value
    const navn = e.target.value;
    //console.log(navn.length, 'navn length')
    setName(navn);
    //console.log(e.target.value)
    //console.log(navn.trim().length, 'name length  with trim')
    //console.log(e.target.value.replace(" ", ''), 'trimmed name')
    if(navn === ' ' || navn.trim().length <= 0) {
      setNameError('ugyldigt navn')
      //console.log('empty space not allowed')
    }
   
  };
//console.log(name.length > 1)
 function validate () {
   if (tlfNumber !== '' && code === "45" && tlfNumber.length === 8 ) {
    setPhoneError('')
   }
   if(name.trim().length > 0) { setNameError('')}
 }

  function tilmeldSMS(e) {
    e.preventDefault();
    const phone = code + tlfNumber;
    //  console.log(name.length)
    if (name.length === 0 && tlfNumber.length === 0) {
      setPhoneError("Telefon nummer skal udfykdes");
      setNameError("Navn skal udfyldes ved tilmelding");
    }
    // if (name.length < 1 ) setNameError('Navn skal udfyldes')
    else if (code === "45" && tlfNumber.length !== 8)
      setPhoneError("indtast venligst rigtigt tlf nummer");
    else if (name.trim().length <= 0) setNameError('ugyldigt navn') 
    //else if(code === '45' && tlfNumber.length === 8) setPhoneError('')
    else if (code === "45" && tlfNumber.length === 8 && name.length > 0) {
      setPhoneError("");
      setNameError("");
      fetch(
        `https://biograf.ebillet.dk/systemnative/incident.asmx/man_addphone?Key=${key}&Phone=%2B${phone}&Name=${name}`,
        {
          method: "GET",
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setName("");
          setTlfNumber("");

          if (result.Result === true) {
            alert("Du har nu tilmeldt vores drift SMS");
          }

          if (result.Result === false) {
            alert("Du er allerede tilmeldt vores drift SMS");
          }
        });
    }
  }

  function afmeldSMS(e) {
    e.preventDefault();
    const phone = code + tlfNumber;
    // console.log(phone);
    if (tlfNumber.length === 0)
      setPhoneError("indtast telefon nummer ved framelding");
    else if (code === "45" && tlfNumber.length !== 8)
      setPhoneError("indtast venligst rigtig telefon nummer");
    else if (code === "45" ? tlfNumber.length === 8 : tlfNumber.length === 6) {
      setPhoneError("");
      fetch(
        `https://biograf.ebillet.dk/systemnative/incident.asmx/man_deletephone?Key=${key}&Phone=%2B${phone}`,
        {
          method: "GET",
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          setTlfNumber("");
          setName("");

          if (result.Result === true) {
            alert("Du har nu frameldt vores drift SMS");
          }
        });
    }
  }

  return (
    <>
    <div className="messages">
      <p className="sms" style={{color: theme === 'dark' ? '' :'#000'}} > Tilmeld/afmeld Drift SMS </p> <br />
      <p
        className="sms-desc"
        style={{ color: theme === "dark" ? "#fff" : "#101010" }}
      >
        Når du er tilmeldt Drift SMS, får du en SMS, når der er ændringer i
        driften på vores systemer.
      </p>{" "}
     
      <form className="sms-form" >
        <label
          htmlFor=""
          className="sms-labels"
          style={{ color: theme === "dark" ? "#fff" : "#101010",opacity: theme === 'dark' ? '0.6' : '' }}
        >
          Landekode
        </label>
        <select  style={{backgroundColor: theme === 'dark' ? '#65696c':'',color: theme === 'dark' ? '#fff' :'' ,backgroundImage:`url(${ theme === 'dark' ? whiteArrow : arrow})`,backgroundRepeat:'no-repeat',width:'260px'}}
          className="select-country inputs form-select"
          value={code}
          onChange={handleCodeChange}
        >
          <option value="45"> Denmark(+45)</option>
          <option value="299">Grønland(+299)</option>
          <option value="298">Færøerne(+298)</option>
        </select>
        <br />
        <label
          htmlFor=""
          className="sms-labels"
          style={{ color: theme === "dark" ? "#fff" : "#101010",opacity: theme === 'dark' ? '0.6' : '' }}
        >
          Dit mobilnummer
        </label>
        <input
          type="text"
          style={{backgroundColor: theme === 'dark' ? '#65696c':'',color: theme === "dark" ? "#fff" : "#101010"}}
          // placeholder="dit mobilnummer"
          className="inputs"
          onChange={handleNumberChange}
          onKeyUp={validate}
          value={tlfNumber}
          maxLength={code === "45" ? 8 : 6}
        />{" "}
        <span
          style={{
            fontSize: 12 + "px",
            //marginTop: 10 + "px",
            //marginBottom: 25 + "px",
            color: "red",
          }}
        >
          {phoneError}
        </span>
        <br />
        <label
          htmlFor=""
          className="sms-labels"
          style={{ color: theme === "dark" ? "#fff" : "#101010",opacity: theme === 'dark' ? '0.6' : '' }}
        >
          Dit navn
        </label>
        <input
          type="text"
          className="inputs"
          style={{backgroundColor: theme === 'dark' ? '#65696c':'',border: theme === 'dark' ? '1px solid #8d8d8d' : '',color: theme === "dark" ? "#fff" : "#101010"}}
          // placeholder="dit navn.."
          value={name}
          onKeyUp={validate}

          onChange={handleNameChange}
        />
        {nameError.length ? (
          <span
            style={{
              fontSize: 12 + "px",
              marginTop: 10 + "px",
              marginBottom: 25 + "px",
              color: "red",
            }}
          >
            {nameError}
          </span>
        ) : (
          <span
            style={{
              fontSize: 12 + "px",
              marginTop: 10 + "px",
              marginBottom: 20 + "px",color: theme === 'dark' ? '#fff' : '#101010'
            }}
          >
              Navn behøves ikke indtastes ved framelding
          </span>
        )}
        <div className="buttons">
          <button className="btns" style={{backgroundColor: theme === 'dark' ? '#4e5052' : '#982f2f',border: theme === 'dark' ? '1px solid #6c6c6c' : ''}} onClick={tilmeldSMS}>
            TILMELD
          </button>
          <button className="btns" style={{backgroundColor: theme === 'dark' ? '#4e5052' : '#982f2f',border: theme === 'dark' ? '1px solid #6c6c6c' : ''}} onClick={afmeldSMS}>
            FRAMELD
          </button>
        </div>
        <p style={{ textAlign: "left" }}>{msgFrmApi} </p>
      </form>
      <br />
    </div>
    </>
  );
}

export default DriftSms;
