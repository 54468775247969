import React, { useEffect, useState}  from "react";
import "./table.css";
import  { keyContext } from './KeyContext';
import moment from "moment";
import ScrollToTop from "react-scroll-to-top";
import { useContext } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { format , isSameMonth } from "date-fns";




function FilmPerForsTable({hentData}) {

const { data } = useContext(keyContext);
const theme = localStorage.getItem('theme')
  let arr = [];
const reportChecked = localStorage.getItem('reportChecked')

 data&& data.filter((i) => arr.push(i.ArrTime.slice(0, 10)));

  //find duplicates
  function toFindDuplicates(arr) {
    const uniqueElements = new Set(arr);
  
    arr.filter((item) => {
      if (uniqueElements.has(item)) {
     
      } else {
        return item;
      }
    });
   
    return [...new Set(uniqueElements)];
  }
 
  const duplicateElements = toFindDuplicates(arr);

  // FINDING REPEATED FILM NAMES
  let filmArr = [];
 data&& data.filter((i) => filmArr.push(i.HomeTeamName));
 

  function FilmDuplicates(filmArr) {
    const uniqueElements = new Set(filmArr);
   
    filmArr.filter((item) => {
      if (uniqueElements.has(item)) {
     
      } else {
        return item;
      }
    });
    
    return [...new Set(uniqueElements)];
  }
  const duplicateFilm = FilmDuplicates(filmArr);
 

  let filmPerDag = [];

  var days = [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
  ];
  
  let chosedDays =[]
  duplicateElements.map((dates) =>
    chosedDays.push(
      days[new Date(new Date(dates).toUTCString()).getDay()].slice(0, 3)
    )
  );
  
  function FindFors(ugedag, filmname) {
    let count = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        count = count + 1;
      }
    });
    return count;
  }

  function FindBil(ugedag, filmname) {
    let count = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        count = count + item.TotalSoldCount + item.TotalResCount;
      }
    });
    return count;
  }

  function FindOms(ugedag, filmname) {
    let oms = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        oms = oms + item.TotalSoldAmount;
      }
    });
    return oms;
  }

  

  let day1_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let day2_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let day3_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let day4_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let day5_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let day6_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let day7_sum = {
    for: 0,
    bil: 0,
    oms: 0,
  };
  let totalSum = {
    for: 0,
    bil: 0,
    oms: 0,
  };

  // console.log(dato[0])

  for (let index = 0; index < duplicateFilm.length; index++) {
    filmPerDag.push({
      id: index,
      name: duplicateFilm[index],

      day1: {
        for: FindFors(chosedDays[0], duplicateFilm[index]),
        bil: FindBil(chosedDays[0], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[0], duplicateFilm[index])),
        
      },
      day2: {
        for: FindFors(chosedDays[1], duplicateFilm[index]),
        bil: FindBil(chosedDays[1], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[1], duplicateFilm[index])),
      },
      day3: {
        for: FindFors(chosedDays[2], duplicateFilm[index]),
        bil: FindBil(chosedDays[2], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[2], duplicateFilm[index])),
      },
      day4: {
        for: FindFors(chosedDays[3], duplicateFilm[index]),
        bil: FindBil(chosedDays[3], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[3], duplicateFilm[index])),
      },
      day5: {
        for: FindFors(chosedDays[4], duplicateFilm[index]),
        bil: FindBil(chosedDays[4], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[4], duplicateFilm[index])),
      },
      day6: {
        for: FindFors(chosedDays[5], duplicateFilm[index]),
        bil: FindBil(chosedDays[5], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[5], duplicateFilm[index])),
      },
      day7: {
        for: FindFors(chosedDays[6], duplicateFilm[index]),
        bil: FindBil(chosedDays[6], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[6], duplicateFilm[index])),
      },
      sumFor: 0,
      sumBil: 0,
      sumOms: 0,
      sum: {
        for: 0,
        bil: 0,
        oms: 0,
      },
    });
  }

  for (let index = 0; index < filmPerDag.length; index++) {
    day1_sum.for = day1_sum.for + filmPerDag[index].day1.for;
    day1_sum.bil = day1_sum.bil + filmPerDag[index].day1.bil;
    day1_sum.oms = day1_sum.oms + filmPerDag[index].day1.oms;

    day2_sum.for = day2_sum.for + filmPerDag[index].day2.for;
    day2_sum.bil = day2_sum.bil + filmPerDag[index].day2.bil;
    day2_sum.oms = day2_sum.oms + filmPerDag[index].day2.oms;

    day3_sum.for = day3_sum.for + filmPerDag[index].day3.for;
    day3_sum.bil = day3_sum.bil + filmPerDag[index].day3.bil;
    day3_sum.oms = day3_sum.oms + filmPerDag[index].day3.oms;

    day4_sum.for = day4_sum.for + filmPerDag[index].day4.for;
    day4_sum.bil = day4_sum.bil + filmPerDag[index].day4.bil;
    day4_sum.oms = day4_sum.oms + filmPerDag[index].day4.oms;

    day5_sum.for = day5_sum.for + filmPerDag[index].day5.for;
    day5_sum.bil = day5_sum.bil + filmPerDag[index].day5.bil;
    day5_sum.oms = day5_sum.oms + filmPerDag[index].day5.oms;

    day6_sum.for = day6_sum.for + filmPerDag[index].day6.for;
    day6_sum.bil = day6_sum.bil + filmPerDag[index].day6.bil;
    day6_sum.oms = day6_sum.oms + filmPerDag[index].day6.oms;

    day7_sum.for = day7_sum.for + filmPerDag[index].day7.for;
    day7_sum.bil = day7_sum.bil + filmPerDag[index].day7.bil;
    day7_sum.oms = day7_sum.oms + filmPerDag[index].day7.oms;

    totalSum.for =
      day1_sum.for +
      day2_sum.for +
      day3_sum.for +
      day4_sum.for +
      day5_sum.for +
      day6_sum.for +
      day7_sum.for;
    totalSum.bil =
      day1_sum.bil +
      day2_sum.bil +
      day3_sum.bil +
      day4_sum.bil +
      day5_sum.bil +
      day6_sum.bil +
      day7_sum.bil;
    totalSum.oms =
      day1_sum.oms +
      day2_sum.oms +
      day3_sum.oms +
      day4_sum.oms +
      day5_sum.oms +
      day6_sum.oms +
      day7_sum.oms;

    filmPerDag[index].sumFor =
      filmPerDag[index].day1.for +
      filmPerDag[index].day2.for +
      filmPerDag[index].day3.for +
      filmPerDag[index].day4.for +
      filmPerDag[index].day5.for +
      filmPerDag[index].day6.for +
      filmPerDag[index].day7.for;

    filmPerDag[index].sumBil =
      filmPerDag[index].day1.bil +
      filmPerDag[index].day2.bil +
      filmPerDag[index].day3.bil +
      filmPerDag[index].day4.bil +
      filmPerDag[index].day5.bil +
      filmPerDag[index].day6.bil +
      filmPerDag[index].day7.bil;

    filmPerDag[index].sumOms =
      filmPerDag[index].day1.oms +
      filmPerDag[index].day2.oms +
      filmPerDag[index].day3.oms +
      filmPerDag[index].day4.oms +
      filmPerDag[index].day5.oms +
      filmPerDag[index].day6.oms +
      filmPerDag[index].day7.oms;
  }

// console.log(filmPerDag, 'film per')
  let filmPerFors = [];
  let films = [];

 data && data.filter((item) =>
    duplicateElements.filter((elm) => {
      if (item.ArrTime.slice(0, 10) === elm) {
        films.push(item.HomeTeamName);
      }
    })
  );

  let start = [];
  let sal = [];
  let end = [];
  let res = [];
  let sold = [];
  let totl = [];
  let bel = [];
  let om = [];
  let datoer = [];
  let seatCount =[];
 data && data.map((item) => {
    seatCount.push(item.TotalSeatCount)
    datoer.push(item.ArrTime.slice(0, 10));
    om.push(Math.round(item.TotalSoldAmount));
    bel.push(
      Math.round(
        ((item.TotalResCount + item.TotalSoldCount) * 100) / item.TotalSeatCount
      )
    );
    totl.push(item.TotalResCount + item.TotalSoldCount);
    res.push(item.TotalResCount);
    sold.push(item.TotalSoldCount);
    start.push(item.ArrTime.slice(11, 16));
    sal.push(item.LocName.replace(/[^0-9]/g, ''));
    end.push(
              moment()
                .hour(item.ArrTime.slice(11, 13))
                .minute(item.ArrTime.slice(14, 16))
                .add(Math.floor(item.DurationSecs / 3600), "hours")
                .add(
                  Math.floor(
                    (item.DurationSecs - Math.floor(item.DurationSecs / 3600) * 3600) /
                      60
                  ),
                  "minutes"
                )
                .format("HH:mm")
            );
  });


  for (let index = 0; index < films.length; index++) {
    filmPerFors.push({
      id: index,
      name: films[index],
      start: start[index],
      end: end[index],
      sal:  sal[index],
      res: res[index],
      sold: sold[index],
      total: totl[index],
      belaeg: bel[index],
      oms: om[index],
      dato: datoer[index],
      seat_count:seatCount[index]
    });
  }

 

  const count = [];

  arr.forEach((element) => {
    count[element] = (count[element] || 0) + 1;
  });

  let forsCount = 0;
  let Reserveret = 0;
  let solgte = 0;
  let total = 0;
  let totalSeat = 0;
  let totalOms = 0;

  //Seach function that filters films from the table
  function searchFunction() {
    let input, filter, tr, td, i, txtValue;
    input = document.getElementById("deskInput");
    filter = input.value.toUpperCase();
    tr = document.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        // console.log(txtValue);
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  let dage = [];
  let dag = [];

  duplicateElements.map((dates) =>
    dage.push(
      days[new Date(new Date(dates).toUTCString()).getDay()].slice(0, 3)
    )
  );

  function getUnique(array) {
    var uniqueArray = [];

    // Loop through array values
    for (var value of array) {
      if (uniqueArray.indexOf(value) === -1) {
        uniqueArray.push(value);
      }
    }
    return uniqueArray;
  }
  dag.push(getUnique(dage));

  dag.filter((i) => i);

  let weeks = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
 
  let dates = [];
  duplicateElements.map((d) => dates.push(d));

  let dato = [];
  let d = dage.map(function (itm, i) {
    return [  [itm], dates[i]];
  });

  dato.push(d);

// console.log(dage, 'dagee')
// console.log(dato[0], 'dato')

  // sorting of tables with a click on table header (works on billet salg per film table)
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      sortableItems.sort((a,b) => 
        a.sumBil > b.sumBil ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort } = useSortableData(filmPerDag);
  // console.log(filmPerFors, 'fors..')

  // sorting of tables (works on billetsalg per forestilling table)
  const useSortTableData = (filmsItems, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...filmsItems];
      sortableItems.sort((a,b) => 
        a.start > b.start ? 1 : -1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          
          if( sortConfig.key === 'sal' ) {
          return   a.start > b.start ? 1 : -1
          }

          return 0;
        });
      }
      return sortableItems;
    }, [filmsItems, sortConfig]);

    const requestFilmSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    

    return { filmsItems: sortedItems, requestFilmSort, sortConfig };
  };
  const { filmsItems, requestFilmSort } = useSortTableData(filmPerFors);

  return data && data.length ? (
    <>
      <main className="table-container" >  
       {reportChecked === 'true' ? '' : 
       <>
         <div className="searchDivContainer" style={{}}>
          <input  style={{backgroundColor: theme === 'dark' ? '#65696c':'',border: theme === 'dark' ? '1px solid #8d8d8d' : '1px solid #8d8d8d'}}
            type="text"
            id="deskInput"
            className="searchInput"
            onKeyUp={searchFunction}
            placeholder="Filtrer film ..."
          />     
          </div>  
      <br></br>
      </>
    }
      <div className=" " >
        <br></br>

       
        {duplicateElements.sort((a,b) => a > b ? 1 : -1).map((dates) => (
          <>
          <p style={{fontSize:'18px',color:theme === 'dark' ? '#fff':'#46484c',opacity:theme === 'dark' ? '0.6' : '',fontWeight:'bold',letterSpacing:'0.36px'}}>
          
           {days[new Date(new Date(dates).toUTCString()).getDay()] }
           {" "}   d. {" "}{moment(new Date(dates)).format('D. ')}{moment(new Date(dates)).format('MMMM')} {moment(dates).format('yyyy')}
           </p>
                      
                      
            <table style={{marginTop:'10px',borderColor: theme === 'dark' ? '#8d8d8d' : '#dee0e3'}}
              id="exam"
              key={dates}
              className={"table myTable " + `${theme === 'dark' ? 'table-striped' : ''}`} 
            >
              <>
                <thead>
                  <tr>
                    <th className="th" style={{textAlign:'left',fontWeight:'normal',paddingLeft:0}} onClick={() => requestFilmSort("name")}>
                     Film
                    </th>
                    <th className="th" onClick={() => requestFilmSort("sal")}>
                    
                    {" "}
                    Sal
                  </th>
                    <th className="th" onClick={() => requestFilmSort("start")}>
                      {" "}
                      Start{" "}
                    </th>
                   
                    <th className="th" onClick={() => requestFilmSort("end")}>
                      Slut
                    </th>
                    <th className="th" onClick={() => requestFilmSort("res")}>
                      Reserveret
                    </th>
                    <th  className="th" onClick={() => requestFilmSort("sold")}>
                      Solgte
                    </th>
                    <th className="th" onClick={() => requestFilmSort("total")}>
                      Total
                    </th>
                    <th
                      className="th"
                      onClick={() => requestFilmSort("belaeg")}
                    >
                      Belægning
                    </th>
                    <th className="th" onClick={() => requestFilmSort("oms")}>
                      Omsætning
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filmsItems.map((item) => {
                   
                    if (item.dato === dates) {
                      forsCount = forsCount + 1;
                      Reserveret = Reserveret + item.res;
                      solgte = solgte + item.sold;
                      total = total + item.res + item.sold;
                      totalOms = totalOms + item.oms;
                      totalSeat = totalSeat + item.seat_count;

                      return (
                        <>
                          <tr  style={{height:'55px'}} className={`${theme === 'dark' ? '' : 'dagens_striped'}`}>
                            <td
                              className="text-truncate"
                              style={{ maxWidth: "250px",paddingTop:'12px',paddingLeft:'7px' }}
                            >
                              {item.name}
                            </td>
                           
                            <td className="rightAlign" style={{paddingTop:'12px',}}><p style={{margin:0,padding:0,width:'25px', marginRight:'15px'}}> {item.sal}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'55px',marginRight:'15px'}}>{item.start}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'55px',marginRight:'10px'}}>{item.end} </p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'50px',marginRight:'10px'}}>{item.res}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'40px',marginRight:'20px',border:'1px '}}>{item.sold} </p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'50px',marginRight:'20px'}}>{item.total}</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,width:'55px',marginRight:'25px'}}>{item.belaeg}%</p></td>
                            <td style={{paddingTop:'12px',}} className="rightAlign"><p style={{margin:0,padding:0,marginRight:'25px'}}>{item.oms.toLocaleString('da')}</p></td>
                          </tr>
                        </>
                      );
                    }
                  })}
                </tbody>
                <tfoot style={{borderBottom:theme === 'dark' ? '1px solid #dee0e3' : '1px solid #46484c'}}>
                   <tr style={{height:'55px',position:'relative',color:theme === 'dark' ? '#ecc590':'#46484c' }}>
                    <th style={{paddingLeft:0,color:'theme'}}> {forsCount} {' '} forestillinger</th>
                    <td ></td>
                    <td></td>
                    <td></td>
                    <td style={{textAlign:'right',fontWeight:'600'}}><p style={{margin:0,width:'52px'}}>{Reserveret}</p> </td>
                    <td style={{textAlign:'right',fontWeight:'600'}}>
                      <p style={{margin:0,width:'42px'}}>{solgte}</p></td>
                    <td style={{textAlign:'right',fontWeight:'600',paddingRight:'3px'}}><p style={{margin:0,width:'52px'}}>{total}</p></td>
                    <td style={{textAlign:'right',fontWeight:'600'}}>
                     <p  style={{margin:0,width:'55px'}}>{Math.round((total * 100) / totalSeat)}%</p> 
                    </td>
                    <td style={{fontWeight:'600',}}>   <p style={{position:'absolute',right:'33px',bottom:'-1px'}}>              
                      {Math.round(totalOms).toLocaleString('da')} </p></td>
                  </tr> 
                </tfoot>
                <p className="text-dark hide">
                  {(forsCount = 0)}
                  {(Reserveret = 0)}
                  {(solgte = 0)}
                  {(total = 0)}
                  {(totalOms = 0)}
                  {(totalSeat = 0)}
                </p>
              </>
            </table>

            <br />
            <br />
          </>
        ))}
         {data.Message? <p> ingen data </p> : ''}
         <p>{data === null? 'ingen data' : ''}</p>
         <ScrollToTop smooth color="#000" style={{bottom:'60px'}}/>
        {/* duplicates map ends above */}
      </div>
      </main>
    </>
  ) : (
    <p style={{ fontSize: "3em" }}>  </p>
  );
}

export default FilmPerForsTable;
