import React, { useEffect, useState}  from "react";
import "./table.css";
import  { keyContext } from './KeyContext';
import moment from "moment";
import ScrollToTop from "react-scroll-to-top";
import { useContext } from "react";
// import { th } from "date-fns/locale";
import { CountdownCircleTimer } from "react-countdown-circle-timer";



function FilmPerDayTable({hentData}) {

const { data } = useContext(keyContext);
const theme = localStorage.getItem('theme')
  let arr = [];

 data&& data.filter((i) => arr.push(i.ArrTime.slice(0, 10)));

  //find duplicates
  function toFindDuplicates(arr) {
    const uniqueElements = new Set(arr);
  
    arr.filter((item) => {
      if (uniqueElements.has(item)) {
     
      } else {
        return item;
      }
    });
   
    return [...new Set(uniqueElements)];
  }
 
  const duplicateElements = toFindDuplicates(arr);

  // FINDING REPEATED FILM NAMES
  let filmArr = [];
 data&& data.filter((i) => filmArr.push(i.HomeTeamName));
 

  function FilmDuplicates(filmArr) {
    const uniqueElements = new Set(filmArr);
   
    filmArr.filter((item) => {
      if (uniqueElements.has(item)) {
     
      } else {
        return item;
      }
    });
    
    return [...new Set(uniqueElements)];
  }
  const duplicateFilm = FilmDuplicates(filmArr);
 

  let filmPerDag = [];

  var days = [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
  ];
  
  let chosedDays =[]
  duplicateElements.map((dates) =>
    chosedDays.push(
      days[new Date(new Date(dates).toUTCString()).getDay()].slice(0, 3)
    )
  );
  // console.log()
//  console.log(chosedDays, 'chosed')
  function FindFors(ugedag, filmname) {
    let count = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        count = count + 1;
      }
    });
    return count;
  }

  function FindBil(ugedag, filmname) {
    let count = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        count = count + item.TotalSoldCount + item.TotalResCount;
      }
    });
    return count;
  }
  function FindBilGnm(ugedag, filmname) {
    let count = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        count = count + item.TotalSoldCount;
      }
    });
    return count;
  }

  function FindOms(ugedag, filmname) {
    let oms = 0;
    data.map((item) => {
      if (
        days[
          new Date(new Date(item.ArrTime.slice(0, 10)).toUTCString()).getDay()
        ].slice(0, 3) === ugedag &&
        item.HomeTeamName === filmname
      ) {
        oms = oms + item.TotalSoldAmount;
      }
    });
    return oms;
  }

  

  let day1_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let day2_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let day3_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let day4_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let day5_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let day6_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let day7_sum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm:0
  };
  let totalSum = {
    for: 0,
    bil: 0,
    oms: 0,
    gnm: 0,
  };

  // console.log(dato[0])

  for (let index = 0; index < duplicateFilm.length; index++) {
    filmPerDag.push({
      id: index,
      name: duplicateFilm[index],

      day1: {
        for: FindFors(chosedDays[0], duplicateFilm[index]),
        bil: FindBil(chosedDays[0], duplicateFilm[index]),
        gnm: FindBilGnm(chosedDays[0], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[0], duplicateFilm[index])),
        
      },
      day2: {
        for: FindFors(chosedDays[1], duplicateFilm[index]),
        bil: FindBil(chosedDays[1], duplicateFilm[index]),
        gnm:FindBilGnm(chosedDays[1], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[1], duplicateFilm[index])),
      },
      day3: {
        for: FindFors(chosedDays[2], duplicateFilm[index]),
        bil: FindBil(chosedDays[2], duplicateFilm[index]),
        gnm:FindBilGnm(chosedDays[2], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[2], duplicateFilm[index])),
      },
      day4: {
        for: FindFors(chosedDays[3], duplicateFilm[index]),
        bil: FindBil(chosedDays[3], duplicateFilm[index]),
        gnm:FindBilGnm(chosedDays[3], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[3], duplicateFilm[index])),
      },
      day5: {
        for: FindFors(chosedDays[4], duplicateFilm[index]),
        bil: FindBil(chosedDays[4], duplicateFilm[index]),
        gnm:FindBilGnm(chosedDays[4], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[4], duplicateFilm[index])),
      },
      day6: {
        for: FindFors(chosedDays[5], duplicateFilm[index]),
        bil: FindBil(chosedDays[5], duplicateFilm[index]),
        gnm:FindBilGnm(chosedDays[5], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[5], duplicateFilm[index])),
      },
      day7: {
        for: FindFors(chosedDays[6], duplicateFilm[index]),
        bil: FindBil(chosedDays[6], duplicateFilm[index]),
        gnm:FindBilGnm(chosedDays[6], duplicateFilm[index]),
        oms: Math.round(FindOms(chosedDays[6], duplicateFilm[index])),
      },
      sumFor: 0,
      sumBil: 0,
      sumOms: 0,
      sumGnm:0,
     
      sum: {
        for: 0,
        bil: 0,
        oms: 0,
        gnm:0
      },
    });
  }

  for (let index = 0; index < filmPerDag.length; index++) {
    day1_sum.for = day1_sum.for + filmPerDag[index].day1.for;
    day1_sum.bil = day1_sum.bil + filmPerDag[index].day1.bil;
    day1_sum.oms = day1_sum.oms + filmPerDag[index].day1.oms;
    day1_sum.gnm = day1_sum.gnm + filmPerDag[index].day1.gnm;


    day2_sum.for = day2_sum.for + filmPerDag[index].day2.for;
    day2_sum.bil = day2_sum.bil + filmPerDag[index].day2.bil;
    day2_sum.oms = day2_sum.oms + filmPerDag[index].day2.oms;
    day2_sum.gnm = day2_sum.gnm + filmPerDag[index].day2.gnm;
    

    day3_sum.for = day3_sum.for + filmPerDag[index].day3.for;
    day3_sum.bil = day3_sum.bil + filmPerDag[index].day3.bil;
    day3_sum.oms = day3_sum.oms + filmPerDag[index].day3.oms;
    day3_sum.gnm = day3_sum.gnm + filmPerDag[index].day3.gnm;


    day4_sum.for = day4_sum.for + filmPerDag[index].day4.for;
    day4_sum.bil = day4_sum.bil + filmPerDag[index].day4.bil;
    day4_sum.oms = day4_sum.oms + filmPerDag[index].day4.oms;
    day4_sum.gnm = day4_sum.gnm + filmPerDag[index].day4.gnm;


    day5_sum.for = day5_sum.for + filmPerDag[index].day5.for;
    day5_sum.bil = day5_sum.bil + filmPerDag[index].day5.bil;
    day5_sum.oms = day5_sum.oms + filmPerDag[index].day5.oms;
    day5_sum.gnm = day5_sum.gnm + filmPerDag[index].day5.gnm;


    day6_sum.for = day6_sum.for + filmPerDag[index].day6.for;
    day6_sum.bil = day6_sum.bil + filmPerDag[index].day6.bil;
    day6_sum.oms = day6_sum.oms + filmPerDag[index].day6.oms;
    day6_sum.gnm = day6_sum.gnm + filmPerDag[index].day6.gnm;


    day7_sum.for = day7_sum.for + filmPerDag[index].day7.for;
    day7_sum.bil = day7_sum.bil + filmPerDag[index].day7.bil;
    day7_sum.oms = day7_sum.oms + filmPerDag[index].day7.oms;
    day7_sum.gnm = day7_sum.gnm + filmPerDag[index].day7.gnm;


    totalSum.for =
      day1_sum.for +
      day2_sum.for +
      day3_sum.for +
      day4_sum.for +
      day5_sum.for +
      day6_sum.for +
      day7_sum.for;
    totalSum.bil =
      day1_sum.bil +
      day2_sum.bil +
      day3_sum.bil +
      day4_sum.bil +
      day5_sum.bil +
      day6_sum.bil +
      day7_sum.bil;
    totalSum.oms =
      day1_sum.oms +
      day2_sum.oms +
      day3_sum.oms +
      day4_sum.oms +
      day5_sum.oms +
      day6_sum.oms +
      day7_sum.oms;
    totalSum.gnm = 
      day1_sum.gnm +
      day2_sum.gnm +
      day3_sum.gnm +
      day4_sum.gnm +
      day5_sum.gnm +
      day6_sum.gnm +
      day7_sum.gnm 


    filmPerDag[index].sumFor =
      filmPerDag[index].day1.for +
      filmPerDag[index].day2.for +
      filmPerDag[index].day3.for +
      filmPerDag[index].day4.for +
      filmPerDag[index].day5.for +
      filmPerDag[index].day6.for +
      filmPerDag[index].day7.for;

    filmPerDag[index].sumBil =
      filmPerDag[index].day1.bil +
      filmPerDag[index].day2.bil +
      filmPerDag[index].day3.bil +
      filmPerDag[index].day4.bil +
      filmPerDag[index].day5.bil +
      filmPerDag[index].day6.bil +
      filmPerDag[index].day7.bil;

    filmPerDag[index].sumOms =
      filmPerDag[index].day1.oms +
      filmPerDag[index].day2.oms +
      filmPerDag[index].day3.oms +
      filmPerDag[index].day4.oms +
      filmPerDag[index].day5.oms +
      filmPerDag[index].day6.oms +
      filmPerDag[index].day7.oms;

      filmPerDag[index].sumGnm =
      filmPerDag[index].day1.gnm +
      filmPerDag[index].day2.gnm +
      filmPerDag[index].day3.gnm +
      filmPerDag[index].day4.gnm +
      filmPerDag[index].day5.gnm +
      filmPerDag[index].day6.gnm +
      filmPerDag[index].day7.gnm;
  }

 
  let filmPerFors = [];
  let films = [];

 data && data.filter((item) =>
    duplicateElements.filter((elm) => {
      if (item.ArrTime.slice(0, 10) === elm) {
        films.push(item.HomeTeamName);
      }
    })
  );
// console.log(films)
  let start = [];
  let sal = [];
  let end = [];
  let res = [];
  let sold = [];
  let totl = [];
  let bel = [];
  let om = [];
  let datoer = [];
  let seatCount =[];
 data && data.map((item) => {
    seatCount.push(item.TotalSeatCount)
    datoer.push(item.ArrTime.slice(0, 10));
    om.push(Math.round(item.TotalSoldAmount));
    bel.push(
      Math.round(
        ((item.TotalResCount + item.TotalSoldCount) * 100) / item.TotalSeatCount
      )
    );
    totl.push(item.TotalResCount + item.TotalSoldCount);
    res.push(item.TotalResCount);
    sold.push(item.TotalSoldCount);
    start.push(item.ArrTime.slice(11, 16));
    sal.push(item.LocName);
    end.push(
              moment()
                .hour(item.ArrTime.slice(11, 13))
                .minute(item.ArrTime.slice(14, 16))
                .add(Math.floor(item.DurationSecs / 3600), "hours")
                .add(
                  Math.floor(
                    (item.DurationSecs - Math.floor(item.DurationSecs / 3600) * 3600) /
                      60
                  ),
                  "minutes"
                )
                .format("HH:mm")
            );
  });


  for (let index = 0; index < films.length; index++) {
    filmPerFors.push({
      id: index,
      name: films[index],
      start: start[index],
      end: end[index],
      sal: sal[index],
      res: res[index],
      sold: sold[index],
      total: totl[index],
      belaeg: bel[index],
      oms: om[index],
      dato: datoer[index],
      seat_count:seatCount[index]
    });
  }

 

  const count = [];

  arr.forEach((element) => {
    count[element] = (count[element] || 0) + 1;
  });

  let forsCount = 0;
  let Reserveret = 0;
  let solgte = 0;
  let total = 0;
  let totalSeat = 0;
  let totalOms = 0;

  //Seach function that filters films from the table
  function searchFunction() {
    let input, filter, tr, td, i, txtValue;
    input = document.getElementById("deskInput");
    filter = input.value.toUpperCase();
    tr = document.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        // console.log(txtValue);
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  let dage = [];
  let dag = [];

  duplicateElements.map((dates) =>
    dage.push(
      days[new Date(new Date(dates).toUTCString()).getDay()].slice(0, 3)
    )
  );

  function getUnique(array) {
    var uniqueArray = [];

    // Loop through array values
    for (var value of array) {
      if (uniqueArray.indexOf(value) === -1) {
        uniqueArray.push(value);
      }
    }
    return uniqueArray;
  }
  dag.push(getUnique(dage));

  dag.filter((i) => i);

  let weeks = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
 
  let dates = [];
  duplicateElements.map((d) => dates.push(d));

  let dato = [];
  let d = dage.map(function (itm, i) {
    return [  [itm], dates[i]];
  });

  dato.push(d);

// console.log(dage, 'dagee')
// console.log(dato[0], 'dato')

  // sorting of tables with a click on table header (works on billet salg per film table)
  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      sortableItems.sort((a,b) => 
        a.sumBil > b.sumBil ? -1 : 1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort } = useSortableData(filmPerDag);
  // console.log(filmPerFors, 'fors..')

  // sorting of tables (works on billetsalg per forestilling table)
  const useSortTableData = (filmsItems, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...filmsItems];
      sortableItems.sort((a,b) => 
        a.start > b.start ? 1 : -1
      )
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          
          if( sortConfig.key === 'sal' ) {
          return   a.start > b.start ? 1 : -1
          }

          return 0;
        });
      }
      return sortableItems;
    }, [filmsItems, sortConfig]);

    const requestFilmSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    

    return { filmsItems: sortedItems, requestFilmSort, sortConfig };
  };
  const { filmsItems, requestFilmSort } = useSortTableData(filmPerFors);


  
  return data && data.length ? (
    <>
      <main className="table-container" >
       
      <div className=" searchDivContainer" style={{}}>
       

       <input  style={{backgroundColor: theme === 'dark' ? '#65696c':'',border: theme === 'dark' ? '1px solid #8d8d8d' : '1px solid #8d8d8d'}}
         type="text"
         id="deskInput" 
         className="searchInput"
         onKeyUp={searchFunction}
         placeholder="Filtrer film ..."
       />
     
       </div> 
       <br></br> <br></br>
         <div className="table-responsive tt">
 
          {/* desktop version table */}
        
          <table  id="example" style={{fontSize:'13px',borderColor: theme === 'dark' ? '#8d8d8d' : '#dee0e3'}} className={"table table-responsive-sm  table-bordered desktop " + `${theme === 'dark' ? 'table-striped' : ''}`}>
          
            <thead className="theader"> 
            <tr className="ttr" style={{borderTop:'none',}} >
              <th style={{height:'86px'}}>
                <div style={{ maxWidth: "170px",height:'86px'}}><div></div></div>
              </th>
              <th style={{fontWeight:'normal',width:'92px'}}>
                <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px',backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              <th style={{fontWeight:'normal',width:'92px'}}>
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              <th  style={{fontWeight:'normal',width:'92px'}}>
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px',backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              <th  style={{fontWeight:'normal',width:'92px'}}>
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              <th  style={{fontWeight:'normal',width:'92px'}}>
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              <th  style={{fontWeight:'normal',width:'92px'}}>  
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px',backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px',backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              <th  style={{fontWeight:'normal',width:'92px'}}>
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d',width:'92px'}}>
                  <p style={{rotate:'90deg',height:'25px', width:'20px',marginTop:'-4px'}}>Forestillinger </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'17px', width:'14px',marginTop:'-2px'}}>Billetter </p>
                  <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
                  <p style={{rotate:'90deg',height:'12px', width:'24px',marginTop:'6px'}}>Omsætning </p>
               </div>
              </th>
              {/* {  dates.length == 4 ? (<th colSpan={6}></th>)  : ('')} */}

              <th  style={{fontWeight:'normal',width:'124px'}}> 
              <div style={{display:'flex',height:'86px',justifyContent:'space-between',color:'#8d8d8d'}}>
              <p style={{rotate:'90deg',height:'27px', width:'22px',marginTop:'-4px'}}>Forestillinger </p>
              <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
              <p style={{rotate:'90deg',height:'16px', width:'13px',marginTop:'-2px'}}>Billetter </p>
              <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}} ></p>
              <p style={{rotate:'90deg',height:'16px', width:'13px',marginTop:'-2px'}}>  Gnmsnitspris</p>
              <p style={{height:'86px',width:'1px', backgroundColor:theme === 'dark' ? '#8d8d8d':'#dee0e3'}}></p>
              <p style={{rotate:'90deg',height:'8px', width:'25px',marginTop:'8px'}}>Omsætning </p>
              <p></p>
              <p></p>

            </div>
              </th>
              
            </tr>
              <tr style={{borderTop:theme === 'dark' ? '3px solid #8d8d8d' :'3px solid #dee0e3',borderBottom:theme === 'dark' ? '3px solid #8d8d8d' :'3px solid #dee0e3' }}>
                <th className="text-truncate th"  style={{ maxWidth: "170px",width:'170px', textAlign:'left' ,color:theme === 'dark'? '#fff3f3' :'#8d8d8d', fontWeight:'normal',paddingLeft:0,opacity:theme === 'dark' ? '0.6':''}} onClick={() => requestSort("name")}>
                  Film
                </th>
                
              
                    { dates.length <= 7 ?
            
            ( dato[0].map((week) => (
                 
                   <th key={week}  className="disable-sort" style={{color:theme === 'dark'? '#fff3f3' :'#8d8d8d', textAlign:'center', width:'93px',minWidth:'93px',maxWidth:'93px',fontWeight:'normal',opacity:theme === 'dark' ? '0.6':''}}>
                       {week[0]} {week[1].slice(8,10)}/{week[1].slice(5,7)} 
                   </th>
                 ))
                 ) : (
                  <>
                  <th  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[0]}</th>
                  <th  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[1]}</th>
                  <th  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[2]}</th>
                  <th  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[3]}</th>
                  <th  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[4]}</th>
                  <th  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[5]}</th>
                  <th  style={{width:'94px',minWidth:'94px',maxWidth:'94px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[6]}</th>
                  </>
                 )
                 }

                  
                 {  dates.length == 1 ? (<td colSpan={6}></td>)  : ('')}
                 {  dates.length == 2 ? (<th colSpan={5}></th>)  : ('')}
                 {  dates.length == 3 ? (<th colSpan={4}></th>)  : ('')}
                 {  dates.length == 4 ? (<th colSpan={3}></th>)  : ('')}
                 {  dates.length == 5 ? (<th colSpan={2}></th>)  : ('')}
                 {  dates.length === 6 ? (<td colspan={1}  ></td>)  : ('')}

            
                <th className="th"   style={{color:theme === 'dark'? '#fff3f3' :'#8d8d8d', minWidth:'153px',width:'153px',opacity:theme === 'dark' ? '0.6':''}} onClick={() => requestSort("sumFor")} >
                  Ugetotal
                </th>
                
              </tr>
            </thead>
            <tbody style={{color: theme === 'dark' ? '#fff' : '#46484c'}}>
              {items.map((item) => (
                <>
                  <tr key={item} className={`${theme === 'dark' ? '' : 'striped'}`}> 
                 
                    <td className="text-truncate" style={{ maxWidth: "170px",width:'170px',}}>{item.name}</td>
                   
                    <td className=""  style={{ display: item.day1.for === 0 && item.day1.bil === 0 && item.day1.oms === 0 ? '' : 'flex', justifyContent:'space-evenly',border:'none'}}>
                      {item.day1.for !== 0 ? item.day1.for : ""}
                      {item.day1.for === 0 && item.day1.bil === 0 && item.day1.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day1.for !== 0 ? item.day1.bil : ""}
                      {item.day1.for === 0 && item.day1.bil === 0 && item.day1.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}
                      {item.day1.for !== 0 ? item.day1.oms.toLocaleString('da') : ""}
                    </td>
                  
                                    
                  
                    <td className="" style={{ textAlign:'center',backgroundColor: theme === 'dark' ? '' :'rgba(0,0,0,0.04)',borderLeft: theme === 'dark' ? '1px solid #8d8d8d':'1px solid #dee0e3'}} >
                    <div style={{display:  'flex', justifyContent:'space-evenly',margin:0}}>
                      {item.day2.for !== 0 ? item.day2.for : ""}
                      {item.day2.for === 0 && item.day2.bil === 0 && item.day2.oms === 0 ? '':<span style={{color:'#adadad',opacity:'0.7'}}> |  </span>}                 
                      {item.day2.for !== 0 ? item.day2.bil : ""} 
                      {item.day2.for === 0 && item.day2.bil === 0 && item.day2.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day2.for !== 0 ? item.day2.oms.toLocaleString('da') : ""} </div>
                    </td>
                  
                    <td className="" style={{ display: item.day3.for === 0 && item.day3.bil === 0 && item.day3.oms === 0 ? '' : 'flex', justifyContent:'space-evenly',border:'none'}}>
                   
                      {item.day3.for !== 0 ? item.day3.for : ""} 
                      {item.day3.for === 0 && item.day3.bil === 0 && item.day3.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day3.for !== 0 ? item.day3.bil : ""}
                      {item.day3.for === 0 && item.day3.bil === 0 && item.day3.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day3.for !== 0 ? item.day3.oms.toLocaleString('da') : ""}
                    </td>
                    
                    <td className="" style={{ textAlign:'center',backgroundColor:theme === 'dark' ? '' :'rgba(0,0,0,0.04)',borderLeft:theme === 'dark' ? '1px solid #8d8d8d':'1px solid #dee0e3'}}>
                    <div style={{display:  'flex', justifyContent:'space-evenly',margin:0}}>
                      {item.day4.for !== 0 ? item.day4.for : ""} 
                      {item.day4.for === 0 && item.day4.bil === 0 && item.day4.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day4.for !== 0 ? item.day4.bil : ""}
                      {item.day4.for === 0 && item.day4.bil === 0 && item.day4.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day4.for !== 0 ? item.day4.oms.toLocaleString('da') : ""}  </div>
                    </td>
                    
                    <td className="" style={{ display: item.day5.for === 0 && item.day5.bil === 0 && item.day5.oms === 0 ? '' :  'flex', justifyContent:'space-evenly', border:'none'}}>
                      {item.day5.for !== 0 ? item.day5.for : ""}
                      {item.day5.for === 0 && item.day5.bil === 0 && item.day5.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day5.for !== 0 ? item.day5.bil : ""}
                      {item.day5.for === 0 && item.day5.bil === 0 && item.day5.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day5.for !== 0 ? item.day5.oms.toLocaleString('da') : ""}
                    </td>

                    
                    <td className="" style={{ textAlign:'center',backgroundColor:theme === 'dark' ? '' :'rgba(0,0,0,0.04)',borderLeft:theme === 'dark' ? '1px solid #8d8d8d':'1px solid #dee0e3'}}>
                    <div style={{display:  'flex', justifyContent:'space-evenly',margin:0}}>
                      {item.day6.for !== 0 ? item.day6.for : ""} 
                      {item.day6.for === 0 && item.day6.bil === 0 && item.day6.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day6.for !== 0 ? item.day6.bil : ""}
                      {item.day6.for === 0 && item.day6.bil === 0 && item.day6.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day6.for !== 0 ? item.day6.oms.toLocaleString('da') : ""} </div>
                    </td>
                    
                   
                    <td className=""  style={{ textAlign:'center',paddingLeft:0,display: item.day7.for === 0 && item.day7.bil === 0 && item.day7.oms === 0 ? '': 'flex', justifyContent:'space-evenly',border:'none'}}>
                      {item.day7.for !== 0 ? item.day7.for : ""} 
                      {item.day7.for === 0 && item.day7.bil === 0 && item.day7.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}>|</span>}                 
                      {item.day7.for !== 0 ? item.day7.bil : ""}
                      {item.day7.for === 0 && item.day7.bil === 0 && item.day7.oms === 0 ? '': <span style={{color:'#adadad',opacity:'0.7'}}> | </span>}                 
                      {item.day7.for !== 0 ? item.day7.oms.toLocaleString('da') : ""}
                     </td>
                                      {/* {  dates.length == 1 ? (<th colSpan={12}></th>)  : ('')}
                    {  dates.length == 2 ? (<th colSpan={10}></th>)  : ('')}
                    {  dates.length == 3 ? (<th colSpan={8}></th>)  : ('')}
                    {  dates.length == 4 ? (<th colSpan={6}></th>)  : ('')}
                    {  dates.length == 5 ? (<th colSpan={4}></th>)  : ('')}
                    {  dates.length == 6 ? (<th colSpan={0}></th>)  : ('')}  */}

                    {/* {  dates.length == 4 ? (<th style={{width:0}} colSpan={6}></th>)  : ('')} */}

                    <td  style={{ textAlign:'center',backgroundColor:theme === 'dark' ? '' :'rgba(0,0,0,0.04)',width:'123px',minWidth:'123px',borderLeft:theme === 'dark' ? '1px solid #8d8d8d':'',borderRight:theme === 'dark' ? '':'none'}}>
                    <div style={{display: 'flex', justifyContent:'space-evenly',margin:0}}>
                      {item.sumFor} <span style={{color:'#adadad',opacity:'0.7'}}> | </span>
                      {item.sumBil} <span style={{color:'#adadad',opacity:'0.7'}}> | </span>
                      {item.sumOms > 0  ? Math.round(item.sumOms/item.sumGnm) : 0} <span style={{color:'#adadad',opacity:'0.7'}}> | </span>
                      {item.sumOms.toLocaleString('da')} </div>
                    </td>
                 
                  </tr>
                </>
              ))}
            </tbody>
           <br/>
            <tfoot>
              <tr style={{height:'20px',borderTop:'none',borderRight: theme === 'dark'? '1px solid #6c6c6c': '1px solid #dee0e3' }}>
                <td></td>
                
                 { dates.length <= 7 ?
            
            ( dato[0].map((week) => (
                 <>
                   <td key={week} colSpan={0} className="disable-sort " style={{ textAlign:'center', color:'#8d8d8d',height:'30px',position:'relative'}}>
                   <p style={{padding:0,margin:0,position:'absolute', left:'20%',bottom:'3px'}}>  {week[0]}  {week[1].slice(8,10)}/{week[1].slice(5,7)} </p>
                   </td>
                 
                   </>
                 ))
                 ) : (
                   <>
                   <td  style={{width:'92px',fontWeight:'normal',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[0]}</td>
                  <td  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[1]}</td>
                  <td  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[2]}</td>
                  <td  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[3]}</td>
                  <td  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[4]}</td>
                  <td  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[5]}</td>
                  <td  style={{width:'92px',minWidth:'92px', color:'#8d8d8d',textAlign:'center'}}>{chosedDays[6]}</td>
                   </>
                 )
                 } 
                 {  dates.length == 1 ? (<td colSpan={6}></td>)  : ('')}
                 {  dates.length == 2 ? (<th colSpan={5}></th>)  : ('')}
                 {  dates.length == 3 ? (<th colSpan={4}></th>)  : ('')}
                 {  dates.length == 4 ? (<th colSpan={3}></th>)  : ('')}
                 {  dates.length == 5 ? (<th colSpan={2}></th>)  : ('')}
                 {  dates.length === 6 ? (<td colspan={0} ></td>)  : ('')}

                <td className="" style={{color:'#8d8d8d',height:'30px',display:'flex',justifyContent:'space-around',borderRight:'none'}}>
                  <div style={{width:'70%', display:'flex',justifyContent:'space-between',}}>
                    <p >Fo.</p>
                    <p style={{backgroundColor: theme ==='dark'? '#8d8d8d' :'#dee0e3',height:'30px',width:'1px',marginTop:'-8px',opacity:'0.7'}}> </p>
                   <p></p>
                    <p>Bi.</p>
                    <p></p>
                    <p style={{backgroundColor:theme ==='dark'? '#8d8d8d' :'#dee0e3',height:'30px',width:'1px',marginTop:'-8px',opacity:'0.7'}}> </p>
                    <p>Gp.</p>
                    <p style={{backgroundColor:theme ==='dark'? '#8d8d8d' :'#dee0e3',height:'30px',width:'1px',marginTop:'-8px',opacity:'0.7'}}> </p>

                  </div>
                  <div style={{width:'35%',textAlign:'center'}}>Oms.</div>
                 {/* <p style={{marginLeft:'-2px',width:'28px'}}> Fo.</p> 
                 <p style={{backgroundColor:'#dee0e3',height:'38px',width:'1px',marginTop:'-8px',marginLeft:'-11px'}}> </p>
                 <p style={{width:'30px',paddingLeft:'8px'}}>Bi.</p>  
                 <p style={{backgroundColor:'#dee0e3',height:'38px',width:'1px',marginTop:'-8px',}}> </p> 
                 <p style={{width:'45px',paddingLeft:'10px'}}>Oms.</p>  */}
                </td> 
                 
              </tr>

              <tr style={{borderBottom:theme === 'dark' ? '3px solid #6c6c6c':'3px solid  #dee0e3',borderTop:theme === 'dark' ? '3px solid #6c6c6c':'3px solid #dee0e3', color:theme === 'dark' ? '#ecc590':'#46484c',borderRight: theme === 'dark'? '1px solid #6c6c6c': '1px solid #dee0e3'   }}>
                <td className="" style={{textAlign:'left',}}>Total</td>

                <td style={{paddingLeft:0,paddingRight:0,textAlign:'center',display:'flex', justifyContent:'space-evenly'}}>                
                {day1_sum.for !== 0 ? day1_sum.for : ''}
                {day1_sum.for && day1_sum.bil && day1_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''}
                {day1_sum.bil !== 0 ? day1_sum.bil : ''} 
                {day1_sum.for && day1_sum.bil && day1_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''} 
                {day1_sum.oms !== 0 ? day1_sum.oms.toLocaleString('da') : ''}   
                   </td>
                
                <td style={{padding:0,backgroundColor:theme ==='dark' ? '' :'#f7f7f7'}}>
                  <div style={{display:'flex', justifyContent:'space-evenly',marginTop:'-2px'}}>
                  {day2_sum.bil !== 0 ? day2_sum.for : ''} 
                {day2_sum.for && day2_sum.bil && day2_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''}
                 {day2_sum.oms !== 0 ? day2_sum.bil : ''} 
                 {day2_sum.for && day2_sum.bil && day2_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''}
                 {day2_sum.for !== 0 ? day2_sum.oms.toLocaleString('da') : ''} </div>
                 </td>
               

                <td style={{paddingLeft:0,paddingRight:0,textAlign:'center',
                display: day3_sum.for && day3_sum.bil && day3_sum.oms !== 0 ?  'flex' : '',justifyContent:'space-evenly'}}>
                  {day3_sum.for !== 0 ? day3_sum.for : ''}
                {day3_sum.for && day3_sum.bil && day3_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''}
                {day3_sum.bil !== 0 ? day3_sum.bil : ''} 
                {day3_sum.for && day3_sum.bil && day3_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''} 
                {day3_sum.oms !== 0 ? day3_sum.oms.toLocaleString('da') : ''} 
                </td>
               

                <td style={{textAlign:'center',padding:0,backgroundColor:theme ==='dark' ? '' :'#f7f7f7'}}>
                <div style={{display:'flex', justifyContent:'space-evenly',marginTop:'-2px'}}>
                  {day4_sum.for !== 0 ? day4_sum.for : ''}
                {day4_sum.for && day4_sum.bil && day4_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''}
                {day4_sum.bil !== 0 ? day4_sum.bil : ''}
                {day4_sum.for && day4_sum.bil && day4_sum.oms !== 0 ? 
                <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span> : ''} 
                 {day4_sum.oms !== 0 ? day4_sum.oms.toLocaleString('da') : ''}</div></td>
                
                {  dates.length == 4 ? (<th colSpan={3}></th>)  : (<>
{day5_sum ? (<>
                <td style={{paddingLeft:0,paddingRight:0,textAlign:'center',
                display:day5_sum.for && day5_sum.bil && day5_sum.oms !== 0 ? 'flex' : '',justifyContent:'space-evenly'}}>{day5_sum.for !== 0 ? day5_sum.for : ''}
                 {day5_sum.for === 0 && day5_sum.bil === 0 && day5_sum.oms === 0 ? 
                 '' : <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span>}
                 {day5_sum.for !== 0 ? day5_sum.bil : ''} 
                 {day5_sum.for === 0 && day5_sum.bil === 0 && day5_sum.oms === 0 ? 
                 '' : <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span>}
                 {day5_sum.for !== 0 ? day5_sum.oms.toLocaleString('da') : ''}
                </td>
                
                </>) : ('')}
{ day6_sum ? ( <>
                <td style={{textAlign:'center',padding:0,backgroundColor:theme ==='dark' ? '' :'#f7f7f7'}}>
                <div style={{display:'flex', justifyContent:'space-evenly',marginTop:'-2px'}}>
                  {day6_sum.for !== 0 ? day6_sum.for : ""}
                {day6_sum.for === 0 && day6_sum.bil === 0 && day6_sum.oms === 0 ? 
                 '' : <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span>}
                {day6_sum.for !== 0 ?  day6_sum.bil : '' } 
                {day6_sum.for === 0 && day6_sum.bil === 0 && day6_sum.oms === 0 ? 
                 '' : <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span>}
                 {day6_sum.for !== 0 ? day6_sum.oms.toLocaleString('da') : ''} </div>
                </td>
                
                </>) : ( '')}
 {
  day7_sum && 
                <td style={{paddingLeft:0,paddingRight:0,textAlign:'center',
                display: day7_sum.for  !== 0 || day7_sum.bil  !== 0 && day7_sum.oms !== 0 ? 'flex' : '',justifyContent:'space-evenly'}}>
                  {day7_sum.for !== 0 ? day7_sum.for : ''}
                {day7_sum.for  === 0 && day7_sum.bil  === 0 && day7_sum.oms === 0 ? 
                  '' : <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span>}
                 { day7_sum.for !==0 ? day7_sum.bil :'' }
                 {day7_sum.for  === 0 && day7_sum.bil  === 0 && day7_sum.oms === 0 ? 
                  '' : <span style={{color:'#dee0e3',opacity:'0.7'}}>|</span>}
                 { day7_sum.for !==0 ? day7_sum.oms.toLocaleString('da'):''}
                </td>
             
               
              }
</>)} 

               
                 <td style={{padding:0,textAlign:'center',backgroundColor:'rgba(0,0,0,0.04)',width:'123px'}}>  <div style={{display:'flex', justifyContent:'space-around',marginTop:'-2px'}}>
                  <span>{totalSum.for}</span><span style={{color:'#adadad',opacity:'0.7'}}>|</span>
                  {totalSum.bil} <span style={{color:'#adadad',opacity:'0.7'}}> | </span>
                  {Math.round(totalSum.oms/totalSum.gnm)}  <span style={{color:'#adadad',opacity:'0.7'}}> | </span>
                  {totalSum.oms.toLocaleString('da')} </div></td>
                
                
              </tr>
            </tfoot>
           
          </table>
             <div className="desktop ticketsDesc" >
              <p > Fo. = Forestillinger  </p>
              <p> Bi. = Billetter      </p>
              <p>Gp. = Gennemsnitpris</p>
              <p> Oms. = Omsætning</p>
             </div>
          </div>
          {/* mobile version table */}
        
          <table className="table table-bordered  mobile" id="myTable">
            <thead>
              <tr>
                <th onClick={() => requestSort("name")} style={{fontWeight: '900'}}>Film</th>
                
                 { dates.length <= 7 ?
            
            ( dato[0].map((week) => (
                 
                   <th key={week}  className="disable-sort" style={{fontWeight: '900'}}>
                    
                
 
                    {week && week[0]}  {week &&  week[1].slice(8,10)}/{week[1].slice(5,7)} 
                   </th>
                 ))
                 ) : (
                  <>
                  <th  style={{fontWeight: '900'}}>{chosedDays[0]}</th>
                  <th  style={{fontWeight: '900'}}>{chosedDays[1]}</th>
                  <th  style={{fontWeight: '900'}}>{chosedDays[2]}</th>
                  <th  style={{fontWeight: '900'}}>{chosedDays[3]}</th>
                  <th  style={{fontWeight: '900'}}>{chosedDays[4]}</th>
                  <th  style={{fontWeight: '900'}}>{chosedDays[5]}</th>
                  <th  style={{fontWeight: '900'}}>{chosedDays[6]}</th>
                  </>
                 )
                 }

            {  dates.length == 1 ? (<th colSpan={6}></th>)  : ('')}
            {  dates.length == 2 ? (<th colSpan={5}></th>)  : ('')}
            {  dates.length == 3 ? (<th colSpan={4}></th>)  : ('')}
            {  dates.length == 4 ? (<th colSpan={3}></th>)  : ('')}
            {  dates.length == 5 ? (<th colSpan={2}></th>)  : ('')}
            {  dates.length == 6 ? (<th colSpan={1}></th>)  : ('')}


                <th className="th" onClick={() => requestSort("sumBil")} style={{fontWeight: '900'}}>
                  Billetter
                </th>
                <th className="th" onClick={() => requestSort("sumOms")} style={{fontWeight: '900'}}>Omsætning</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <>
                  <tr key={item}>
                    <td className="text-truncate" style={{ maxWidth: "200px" }}>
                      {item.name}
                    </td>
                    <td className="rightAlign text-truncate">
                      {" "}
                      {item.day1.bil} 
                    </td>
                    <td className="rightAlign text-truncate">
                      {item.day2.bil}
                    </td>
                    <td className="rightAlign">{item.day3.bil}</td>
                    <td className="rightAlign">{item.day4.bil}</td>
                    <td className="rightAlign">{item.day5.bil}</td>
                    <td className="rightAlign">{item.day6.bil}</td>
                  {item.day7 &&   <td className="rightAlign">{item.day7.bil}</td>}

                    <td className="rightAlign">{item.sumBil}</td>
                    <td className="rightAlign">{item.sumOms.toLocaleString('da')}</td>
                  </tr>
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
               
                 { dates.length <= 7 ?
            
            ( dato[0].map((week) => (
                 
                   <th key={week}  className="disable-sort rightAlign" style={{fontWeight: '900'}}>
                    
                  
 
                     {week[0]}  {week[1].slice(8,10)}/{week[1].slice(5,7)}
                   </th>
                 ))
                 ) : (
                  <>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[0]}</th>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[1]}</th>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[2]}</th>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[3]}</th>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[4]}</th>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[5]}</th>
                  <th  style={{fontWeight: '900'}} className="rightAlign">{chosedDays[6]}</th>
                  </>
                 )
                 }
            {  dates.length == 1 ? (<th colSpan={6}></th>)  : ('')}
            {  dates.length == 2 ? (<th colSpan={5}></th>)  : ('')}
            {  dates.length == 3 ? (<th colSpan={4}></th>)  : ('')}
            {  dates.length == 4 ? (<th colSpan={3}></th>)  : ('')}
            {  dates.length == 5 ? (<th colSpan={2}></th>)  : ('')}
            {  dates.length == 6 ? (<th colSpan={1}></th>)  : ('')}

                <th className="rightAlign" style={{fontWeight: '900'}}> Billetter </th>
                <th className="rightAlign" style={{fontWeight: '900'}}>Omsætning</th>
              </tr>
              <tr>
                <th className="rightAlign" style={{fontWeight: '900'}}>Total Bestilte og Solgte billetter</th>
                <td className="rightAlign">{day1_sum.bil}</td>
                <td className="rightAlign">{day2_sum.bil}</td>
                <td className="rightAlign">{day3_sum.bil}</td>
                <td className="rightAlign">{day4_sum.bil}</td>
                <td className="rightAlign">{day5_sum.bil}</td>
                <td className="rightAlign">{day6_sum.bil}</td>
                <td className="rightAlign">{day7_sum.bil}</td>
                <td className="rightAlign">{totalSum.bil}</td>
                <td className="rightAlign">{totalSum.oms.toLocaleString('da')}</td>
              </tr>
            </tfoot>
          </table>
        
      </main>
      <br></br>


      
    </>
  ) : (
    <p style={{ fontSize: "3em" }}>  </p>
  );
}

export default FilmPerDayTable;
