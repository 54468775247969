import React, { useContext, useEffect, useState} from 'react';
import BiografSelect from "../components/BiografSelect";
import '../components/css/fourWeeksReport.css';
import whiteArrow from '../images/white-arrow.svg'
import downArrow from '../images/arrow-down.svg'
import { keyContext } from "../components/KeyContext";
import moment from "moment";
import FourWeeksTable from '../components/FourWeeksTable';
import PulseLoader from "react-spinners/PulseLoader";


function FourWeeksReport ({ setIsShown }) {
   
  useEffect(() => {
    document.title = "Fireugersrapport - ebillet_admin";
  }, []);

  const override: CSSProperties = {
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "0 auto",
    textAlign: "center",
    borderColor: "#982f2f",
    transform: "translate(-50%, -50%)",
  };

const { containerHeight, leftSpace, chosenCinema, setChosenCinema} = useContext(keyContext)

const [weekBoxOpen, setWeekBoxOpen] = useState()

const key = sessionStorage.getItem("key");
// const theme = localStorage.getItem('theme')
const [isLoading, setIsLoading] = useState(false)
const [data, setData] = useState([])
const [selectedWeekNr, setSetselectedWeekNr] = useState('');
const [selectedYear, setSelectedYear] = useState(moment().year());

const [week1data, setWeek1data] = useState([{}])
const [week2data, setWeek2data] = useState([{}])
const [week3data, setWeek3data] = useState([{}])
const [currentweekdata, setCurrentWeekdata] = useState([])

const [nodata, setNoData] = useState(false)
const [errorText, setErrorText] = useState('')
const[week1cons,setWeek1cons] = useState([{}])
const[week2cons,setWeek2cons] = useState([{}])
const[week3cons,setWeek3cons] = useState([{}])
const[currentWeekcons,setCurrentweekcons] = useState([])

const [week1another, setWeek1another] = useState([{}]);
const [week2another, setWeek2another] = useState([{}]);
const [week3another, setWeek3another] = useState([{}]);
const [currentWeekanother, setCurrentWeekanother] = useState([{}]);

const [totalMovieSale, setTotalMovieSale] = useState([])


const [thisYearData,setThisYearData ] = useState([])
const [lastYearData, setLastYearData] = useState([])
const [twoYearsData, setTwoYearsData] = useState([])
const [currentYearsCons, setCurrentYearsCons] = useState([])
const [currentYearsAnother, setCurrentYearsAnother] = useState([])
const [lastYearsCons, setLastYearsCons] = useState([])
const [twoYearsCons, setTwoYearsCons] = useState([])
const [lastYearsAnother, setLastYearsAnother] = useState([])
const [twoYearsAnother, setTwoYearsAnother] = useState([])


// console.log(selectedYear, 'selected year')
   let selectedWeekStart = moment().week(selectedWeekNr).startOf("isoweek").format('yyyy-MM-DD')
   let selectedWeekEnd =moment().week(selectedWeekNr).endOf("isoweek").format('yyyy-MM-DD')
   let week1start = moment(selectedWeekStart).subtract(3,'week').format('yyyy-MM-DD')
   let week1end = moment(selectedWeekEnd).subtract(3,'week').format('yyyy-MM-DD')
   let week2start =moment(selectedWeekStart).subtract(2,'week').format('yyyy-MM-DD')
   let week2end = moment(selectedWeekEnd).subtract(2,'week').format('yyyy-MM-DD')
   let week3start = moment(selectedWeekStart).subtract(1,'week').format('yyyy-MM-DD')
   let week3end = moment(selectedWeekEnd).subtract(1,'week').format('yyyy-MM-DD')

  
  //  useEffect(() => {
    /**----------IF THE YEAR IS OTHER THEN CURRENT YEAR------- */
 if(weekBoxOpen === false && selectedYear !== moment().year()) {
  selectedWeekStart = moment().day('Monday').year(selectedYear).week(selectedWeekNr).startOf('isoWeek').format('yyyy-MM-DD')
  selectedWeekEnd =  moment().day('Monday').year(selectedYear).week(selectedWeekNr).endOf('isoWeek').format('yyyy-MM-DD')
  week1start =  moment(selectedWeekStart).subtract(3,'week').format('yyyy-MM-DD')
  week1end = moment(selectedWeekEnd).subtract(3,'week').format('yyyy-MM-DD')
  week2start =moment(selectedWeekStart).subtract(2,'week').format('yyyy-MM-DD')
  week2end = moment(selectedWeekEnd).subtract(2,'week').format('yyyy-MM-DD')
  week3start = moment(selectedWeekStart).subtract(1,'week').format('yyyy-MM-DD')
  week3end = moment(selectedWeekEnd).subtract(1,'week').format('yyyy-MM-DD')
 }
  //  },[selectedYear])

    let theme = localStorage.getItem('theme')
    function not() {}
    let path = window.location.pathname;
    path === "/reports" ? setIsShown(true) : not();

   
    const filmID = sessionStorage.getItem("film");
   
  const orgNr = filmID && filmID.split(" ")[filmID.split(" ").length - 1];

  useEffect(() => {
    setSetselectedWeekNr(moment().day('monday').week())
    
  },[])
  

useEffect(() => {
   if(orgNr) setChosenCinema(true)
},[orgNr])
 

  /**----------ÅR TIL DATO ---------fetches from week1start date til selectedweeks end date */
  let selectedWeekEnddate1yearago = moment(selectedWeekEnd).subtract(1, 'years').format('YYYY-MM-DD')
  let selectedWeekEnddate2yearsago = moment(selectedWeekEnd).subtract(2, 'years').format('YYYY-MM-DD')
  

let year_start_date = weekBoxOpen === false ? `${selectedYear}-01-01` : '2024-01-01'
let lastyear_start_date = moment(year_start_date ).subtract(1, 'years').format('YYYY-MM-DD')
let twoyear_start_date = moment(year_start_date ).subtract(2, 'years').format('YYYY-MM-DD')



  function fetchYearlyData() {
    /**-----This YEAR---- */
     setIsLoading(true)

    fetch(
      `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
        orgNr
      }&StartDate=${year_start_date}&EndDate=${selectedWeekEnd}&FromTime=00:00:00&ToTime=23:59:59`,
      {
        method: "GET", 
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("ingen forstillinger i valgte biograf..");
        }

        return res.json();
      })
      .then(result => { 
        setIsLoading(false)
        // console.log(result , 'this year data')
        setThisYearData(result)
      }
    )

    /**---- LAST YEAR---- */
       setIsLoading(true);
    fetch(
      `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
        orgNr
      }&StartDate=${lastyear_start_date}&EndDate=${selectedWeekEnddate1yearago}&FromTime=00:00:00&ToTime=23:59:59`,
      {
        method: "GET", 
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("ingen forstillinger i valgte biograf..");
        }

        return res.json();
      })
      .then(result => {
        setLastYearData(result)
         setIsLoading(false)
      } )

      /**----2 YEARS AGO */
     
    
    //  console.log(selectedWeekEnddate2yearsago)
     setIsLoading(true)
     fetch(
      `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
        orgNr
      }&StartDate=${twoyear_start_date}&EndDate=${selectedWeekEnddate2yearsago}&FromTime=00:00:00&ToTime=23:59:59`,
      {
        method: "GET", 
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("ingen forstillinger i valgte biograf..");
        }

        return res.json();
      })
      .then(result => { 
         setIsLoading(false)
        setTwoYearsData(result)
      })

      /**-------THIS YEARS CONCESSION-------- */
       setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${year_start_date}&DateTo=${selectedWeekEnd}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => { 
        // console.log(result, 'this year concession')
        setCurrentYearsCons(result)
         setIsLoading(false)
      } )

      /**--------THIS YEARS ANDEN OMSÆTNING*/
       setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
         orgNr
        }&DateFrom=${year_start_date}&DateTo=${selectedWeekEnd}`,
        {
          method: "GET", 
      
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result =>{
        // console.log(result, 'this year anden omsætning')
         setCurrentYearsAnother(result)
          setIsLoading(false)
      }
      
       

    )

/**-------LAST YEARS CONCESSION  */
setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${lastyear_start_date}&DateTo=${selectedWeekEnddate1yearago}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => {
         setIsLoading(false)
        setLastYearsCons(result)
      }
         
        )

      /**-------2 YEARS AGO CONCESSION  */
       setIsLoading(true)

      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${twoyear_start_date}&DateTo=${selectedWeekEnddate2yearsago}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => {
         setIsLoading(false)
        setTwoYearsCons(result)
      } )

/**----LAST YEARS ANDEN OMSÆTNING */
 setIsLoading(true)
fetch(
  `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
   orgNr
  }&DateFrom=${lastyear_start_date}&DateTo=${selectedWeekEnddate1yearago}`,
  {
    method: "GET", 

    headers: {
      ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
    },
  }
)
.then(res => res.json())
.then(result => {
  setIsLoading(false)
  setLastYearsAnother(result)
} )

/**----2 YEARS AGO ANDEN OMSÆTNING */
 setIsLoading(true)
fetch(
  `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
   orgNr
  }&DateFrom=${twoyear_start_date}&DateTo=${selectedWeekEnddate2yearsago}`,
  {
    method: "GET", 

    headers: {
      ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
    },
  }
)
.then(res => res.json())
.then(result => { 
   setIsLoading(false)
  setTwoYearsAnother(result)
})
  }

// console.log(oms_in_all, 'oms in all')



 function hentData ()  {
    // data fetch from week1start date to selected week end date
      //  setIsLoading(true)
    fetch(
      `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
        orgNr
      }&StartDate=${week1start}&EndDate=${selectedWeekEnd}&FromTime=00:00:00&ToTime=23:59:59`,
      {
        method: "GET", 
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("ingen forstillinger i valgte biograf..");
        }

        return res.json();
      })
      .then((result) => {
          // console.log(result, 'the result')
          // setIsLoading(false)
        if(result.Message) {
          setNoData(true)
          setData('')
        }
       else {
        setData(result)
        setNoData(false)
     
        // setChosenCinema(true)
       }                       
      })       

   
        // week 1 fetch
      
        // if(data) { 
            // setIsLoading(true)
     fetch(
            `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
              orgNr
            }&StartDate=${week1start}&EndDate=${week1end}&FromTime=00:00:00&ToTime=23:59:59`,
            {
              method: "GET", 
              headers: {
                ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
              },
            }
          )
            .then((res) => {
              if (!res.ok) {
                throw Error("ingen forstillinger i valgte biograf..");
              }
      
              return res.json();
            })
            .then((result) => {
              // setIsLoading(false)  
              if(result.Message) {
                setWeek1data('')  
              }
            else  setWeek1data(result)      
              //                
            })       
          // }
            // week 2 fetch 
            // if(data) {
              // setIsLoading(true)
              // setWeek2data('')
           fetch(
              `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
                orgNr
              }&StartDate=${week2start}&EndDate=${week2end}&FromTime=00:00:00&ToTime=23:59:59`,
              {
                method: "GET", 
                headers: {
                  ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
                },
              }
            )
              .then((res) => {
                if (!res.ok) {
                  throw Error("ingen forstillinger i valgte biograf..");
                }
        
                return res.json();
              })
              .then((result) => {
                  setWeek2data(result) 
                    // setIsLoading(false)                        
              })  
        // }
              // week 3 fetch
              // if(data) {
                  // setIsLoading(true)
              fetch(
                `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
                  orgNr
                }&StartDate=${week3start}&EndDate=${week3end}&FromTime=00:00:00&ToTime=23:59:59`,
                {
                  method: "GET", 
                  headers: {
                    ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
                  },
                }
              )
                .then((res) => {
                  if (!res.ok) {
                    throw Error("ingen forstillinger i valgte biograf..");
                  }
          
                  return res.json();
                })
                .then((result) => {
                    setWeek3data(result)
                    //  setIsLoading(false)
                })  
            // } 
                // selected week fetch
                // if(data) {
                    // setIsLoading(true)
                fetch(
                  `https://biograf.ebillet.dk/systemnative/report.asmx/man_arrstatlist?Key=${key}&strOrganizers=${
                    orgNr
                  }&StartDate=${selectedWeekStart}&EndDate=${selectedWeekEnd}&FromTime=00:00:00&ToTime=23:59:59`,
                  {
                    method: "GET", 
                    headers: {
                      ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
                    },
                  }
                )
                  .then((res) => {
                    if (!res.ok) {
                      throw Error("ingen forstillinger i valgte biograf..");
                    }
            
                    return res.json();
                  })
                  .then((result) => {
                      setCurrentWeekdata(result)
                      //  setIsLoading(false)
                  })    
              // }

 
             
    }
    

    function GetConcession () {
      /** below selected weeks concession */
      // setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${selectedWeekStart}&DateTo=${selectedWeekEnd}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => { 
        setCurrentweekcons(result);
          // setIsLoading(false)
      } )
      /*-----below week1 concession*/
      //  setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${week1start}&DateTo=${week1end}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => {
        setWeek1cons(result)
        //  setIsLoading(false)
      } )

      /** below week2 concession */
//  setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${week2start}&DateTo=${week2end}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => { 
        setWeek2cons(result)
        //  setIsLoading(false)
      } )

      /** below week3 concession */
      //  setIsLoading(true)
      fetch(
        `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetConcession?Key=${key}&strOrganizers=${
          orgNr
        }&DateFrom=${week3start}&DateTo=${week3end}`,
        {
          method: "GET", 
    
          headers: {
            ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
          },
        }
      )
      .then(res => res.json())
      .then(result => {
        setWeek3cons(result)
        //  setIsLoading(false)
      })
    }
function GetAnotherSale() {
  /**--------selected weeks another sale data---------- */
  //  setIsLoading(true)
  fetch(
    `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
     orgNr
    }&DateFrom=${selectedWeekStart}&DateTo=${selectedWeekEnd}`,
    {
      method: "GET", 

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    }
  )
  .then(res => res.json())
  .then(result =>  { 
   
    setCurrentWeekanother(result)
    //  setIsLoading(false)
  } )

  /**-------- week 1 another sale data---------- */
  //  setIsLoading(true)
  fetch(
    `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
     orgNr
    }&DateFrom=${week1start}&DateTo=${week1end}`,
    {
      method: "GET", 

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    }
  )
  .then(res => res.json())
  .then(result => { 
    setWeek1another(result)
      // setIsLoading(false)
  } )

  
  /**-------- week 2 another sale data---------- */
    // setIsLoading(true)
  fetch(
    `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
     orgNr
    }&DateFrom=${week2start}&DateTo=${week2end}`,
    {
      method: "GET", 

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    }
  )
  .then(res => res.json())
  .then(result => {
    setWeek2another(result)
      // setIsLoading(false)
  } )

 
  /**-------- week 3 another sale data---------- */
    // setIsLoading(true)
  fetch(
    `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetOtherSale?Key=${key}&strOrganizers=${
     orgNr
    }&DateFrom=${week3start}&DateTo=${week3end}`,
    {
      method: "GET", 

      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    }
  )
  .then(res => res.json())
  .then(result => {
    setWeek3another(result)
      // setIsLoading(false)
  })

}

 useEffect(() => {
  if(isLoading === false && nodata === true ) {
setErrorText('ingen data i valgte biograf')
  }
 },[orgNr,chosenCinema,nodata,isLoading])

useEffect(() => {
  if( chosenCinema === true || (chosenCinema === true && weekBoxOpen === false) ) { 
      hentData()  
      GetConcession()
      GetAnotherSale()
      fetchYearlyData()
  }
   
},[orgNr,chosenCinema,selectedWeekNr])

/**--------SORTED DATA calculations here */
  
let sortedData = []
let days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]
let date_arr = []
data && data.filter((i) => date_arr.push(i.ArrTime.slice(0, 10)))

//find duplicates
function toFindDuplicates(date_arr) {
const uniqueElements = new Set(date_arr);

date_arr.filter((item) => {
  if (uniqueElements.has(item)) {
 
  } else {
    return item;
  }
});

return [...new Set(uniqueElements)];
}

const duplicateElements = toFindDuplicates(date_arr);
let arr = [];
data.length && data.filter((i) => arr.push(i.HomeTeamName));

 //find duplicates
 function FilmDuplicates(arr) {
   const uniqueElements = new Set(arr);
 
   arr.filter((item) => {
     if (uniqueElements.has(item)) {
    
     } else {
       return item;
     }
   });
  
   return [...new Set(uniqueElements)];
 }

 const duplicateFilms = FilmDuplicates(arr);


 let chosedDays =[]
duplicateElements.map((dates) =>
chosedDays.push(
days[new Date(new Date(dates).toUTCString()).getDay()].slice(0, 3)
)
);
 function findBil(ugedag,filmname) {
  let count = 0;

  currentweekdata.length && currentweekdata.map((item) => {
    let d = days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()]
       if ( d?.slice(0, 3) === ugedag  && item.HomeTeamName === filmname  ) {

          count = count + item.TotalSoldCount + item.TotalResCount;
     }
     return count
   })

   return count
}
function findOms (ugedag,filmname) {
  let oms = 0;
  currentweekdata.length && currentweekdata.map((item) => {
    let d = days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()]
    if (d?.slice(0, 3) === ugedag && item.HomeTeamName === filmname
    ) {
 
      oms = oms + item.TotalSoldAmount;
    }
  });
  return oms;
}

/** finding data for the past 3 weeks referred as week1, week2, week 3 */
function findweek1tkts (film) {
        let count = 0;
    week1data.length && week1data.map((item) => {
      if(item.HomeTeamName === film) {        
        count = count + item.TotalSoldCount + item.TotalResCount;
      }
    })
    return count
}
function findweek1oms(film) {
   let oms = 0;
   week1data.length && week1data.map((item) => {
    if(item.HomeTeamName === film) {
      oms = oms + item.TotalSoldAmount;
    }
   })
   return oms
}
function findweek2tkts (film) {
let count = 0;
week2data.length && week2data.map((item) => {
if(item.HomeTeamName === film) {        
count = count + item.TotalSoldCount + item.TotalResCount;
}
})
return count
}
function findweek2oms(film) {
let oms = 0;
week2data.length && week2data.map((item) => {
if(item.HomeTeamName === film) {
oms = oms + item.TotalSoldAmount;
}
})
return oms
}function findweek3tkts (film) {
let count = 0;
week3data.length && week3data.map((item) => {
if(item.HomeTeamName === film) {        
count = count + item.TotalSoldCount + item.TotalResCount;
}
})
return count
}
function findweek3oms(film) {
let oms = 0;
week3data.length && week3data.map((item) => {
if(item.HomeTeamName === film) {
oms = oms + item.TotalSoldAmount;
}
})
return oms
}



//  console.log(totalMovieSale)
function findTicketCount (movie_no) {
let totalcount = 0
totalMovieSale.length &&  totalMovieSale.map((item) => {
          if(item.WebMovieNo === movie_no){
            totalcount= item.TicketCount
          }
   })
   return totalcount
}
function findTicketRevenue (movie_no) {
let total_rev = 0
totalMovieSale.length &&  totalMovieSale.map((item) => {
       if(item.WebMovieNo === movie_no){
         total_rev= item.TicketRevenue
       }
})
return total_rev
} 
let movienos_arr = []
data && data.filter((i) => movienos_arr.push(i.WebMovieNo))
function toFindDuplicates(movienos_arr) {
  const uniqueElements = new Set(movienos_arr);

  movienos_arr.filter((item) => {
    if (uniqueElements.has(item)) {
   
    } else {
      return item;
    }
  });
 
  return [...new Set(uniqueElements)];
}
const movies_nos = toFindDuplicates(movienos_arr)
// film dataset which is sorted accordingly shows per film data

for(let index = 0; index < duplicateFilms.length; index++){
sortedData.push({
  id: index,
  name: duplicateFilms[index],
  ticketCount :  findTicketCount(movies_nos[index]),
  ticketRevenue: findTicketRevenue(movies_nos[index]),
  week1tickets: findweek1tkts(duplicateFilms[index]),
  week1oms:     findweek1oms(duplicateFilms[index]),
  week2tickets: findweek2tkts(duplicateFilms[index]),
  week2oms:     findweek2oms(duplicateFilms[index]),
  week3tickets: findweek3tkts(duplicateFilms[index]),
  week3oms:     findweek3oms(duplicateFilms[index]),
 
  monday: {
     
    bil:   findBil (chosedDays[0],duplicateFilms[index]),
    oms:findOms(chosedDays[0], duplicateFilms[index]),
  },
  tuesday : {
    bil:findBil(chosedDays[1], duplicateFilms[index]),
    oms:findOms(chosedDays[1], duplicateFilms[index])
  },
  wednesday:{
    bil:findBil(chosedDays[2], duplicateFilms[index]),
    oms:findOms(chosedDays[2], duplicateFilms[index])
  },
  thursday:{
    bil:findBil(chosedDays[3], duplicateFilms[index]),
    oms:findOms(chosedDays[3], duplicateFilms[index])
  },
  friday:{
    bil:findBil(chosedDays[4], duplicateFilms[index]),
    oms:findOms(chosedDays[4], duplicateFilms[index])
  },
  saturday:{
    bil:findBil(chosedDays[5], duplicateFilms[index]),
    oms:findOms(chosedDays[5], duplicateFilms[index])
  },
  sunday: {
    bil:findBil(chosedDays[6], duplicateFilms[index]),
    oms:findOms(chosedDays[6], duplicateFilms[index])
  },
})
}


useEffect(() => {
  // get total movie sale fetch call 
   setTotalMovieSale('')
  if(data.length) {
 
  fetch(
    `https://biograf.ebillet.dk/systemnative/report.asmx/man_GetTotalMovieSale?Key=${key}&strOrganizers=${orgNr}&WebMovieNos=${movies_nos}&DateTo=${selectedWeekEnd}`,
    {
      method: "GET", 
      headers: {
        ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
      },
    }
  )
  .then((res) => {
    if (!res.ok) {
      throw Error("ingen forstillinger i valgte biograf..");
    }
  
    return res.json();
  })
  .then(result =>  { 
    setTotalMovieSale(result)
    // setIsLoading(false)
  })
}
},[data,selectedWeekNr])

// console.log(movies_nos)
// console.log(totalMovieSale, 'grand total')
 


  let days_arr = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ]
  let dates_arr = []
  data && data.filter((i) => dates_arr.push(i.ArrTime.slice(0, 10)))
  // console.log(date_arr)
  //find duplicates
  function toFindDuplicates(dates_arr) {
    const uniqueElements = new Set(dates_arr);
  
    dates_arr.filter((item) => {
      if (uniqueElements.has(item)) {
     
      } else {
        return item;
      }
    });
   
    return [...new Set(uniqueElements)];
  }
 
  const duplicate_dates_arr = toFindDuplicates(dates_arr);
  let chosedDaysarr =[]
  duplicate_dates_arr.map((dates) =>
    chosedDaysarr.push(
      days_arr[new Date(new Date(dates).toUTCString()).getDay()]
    )
  );



/**-------oms in all --data calculations */ 
function findSelectedWeektotal_tkts (day) {
  let count = 0;
  // console.log(day)
  currentweekdata.length && currentweekdata.map((item) => {
   // let d = days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()]
     if(days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()] === day ) {
      count = count + item.TotalSoldCount + item.TotalResCount
     }
  })
    
  return count
} 
function findSelectedWeektotal_oms(day) {
  let count = 0;
  currentweekdata.length && currentweekdata.map((item) => {
    // d = days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()]
     if(days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()] === day ) {
      count = count + item.TotalSoldAmount 
     }
  })
   return count
}
// console.log(currentweekdata)
function findSelectedWeekGnm_tkt_pris (day) {
  let count = 0;
  let total_sold_count = 0
  let total_sold_amount = 0
  currentweekdata.length && currentweekdata.map((item) => {
    //let d = days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()]
     if(days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()] === day ) {
      total_sold_count =  total_sold_count + item.TotalSoldCount 
      total_sold_amount = total_sold_amount + item.TotalSoldAmount
      count = ( total_sold_amount/total_sold_count).toFixed(2)
     }
  })
  
  return count
}

//----------KIOSK OMSÆTNING ----------
function findSelectedWeektotal_count (day) {
  let count = 0;
  // console.log(day)
  currentweekdata.length && currentweekdata.map((item) => {
    let d = days[new Date(new Date(item.ArrTime?.slice(0, 10)).toUTCString()).getDay()]
     if(d === day ) {
      count = count + item.TotalSoldCount 
     }
  })
    
  return count
}



function findSelectedweekCons(day) {
  let count = 0;
  //console.log(currentWeekcons)
 
  currentWeekcons.length && currentWeekcons.map((item) => {
    let d =days[new Date(new Date(item.Date?.slice(0, 10)).toUTCString()).getDay()]
   
  
     if(d === day ) {
       count = count + item.ConcessionRevenue
     }
  })
  
  return Math.round(count)
  
}


//-----------ANDEN OMSÆTNING -----------
// console.log(currentWeekanother)
function findSelectedWeekanother (day ) {
  let count = 0;
 currentWeekanother.length && currentWeekanother.map((item) => {
  let d =days[new Date(new Date(item.Date?.slice(0, 10)).toUTCString()).getDay()]
    if( d === day) {
      count = count + item.ConcessionRevenue
    }
    return count
  })
  
  return Math.round(count)
}



/**--------OMSÆTNING I ALT NEW DATASET-------- */
const oms_in_all = data.length && week1data.length > 1 && week2data.length > 1 && week3data.length > 1 && currentweekdata.length > 1 &&  [{
  monday: { 
     selectedWeektotaltickets:   findSelectedWeektotal_tkts(chosedDaysarr[0]),
     selectedWeektotaloms :      findSelectedWeektotal_oms (chosedDaysarr[0]),
     selectedWeekGnmtktpris:     Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[0]).toLocaleString('da')),

     selectedWeekcons:   findSelectedweekCons(chosedDaysarr[0]),
      selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[0]) > 0 ? (findSelectedweekCons(chosedDaysarr[0])/findSelectedWeektotal_count(chosedDaysarr[0])).toFixed(2) : 0 ,
    selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[0]) > 0 ? (findSelectedweekCons(chosedDaysarr[0])/findSelectedWeektotal_oms (chosedDaysarr[0])).toFixed(2) :0,

     selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[0]),
     selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[0]) > 0 ? (findSelectedWeekanother(chosedDaysarr[0])/findSelectedWeektotal_count(chosedDaysarr[0])).toFixed(2) : 0 ,
     selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[0]) > 0 ? (findSelectedWeekanother(chosedDaysarr[0])/findSelectedWeektotal_oms (chosedDaysarr[0])).toFixed(2) :0,

  },
  tuesday: { 
    selectedWeektotaltickets: findSelectedWeektotal_tkts(chosedDaysarr[1]),
    selectedWeektotaloms :    findSelectedWeektotal_oms (chosedDaysarr[1]),
    selectedWeekGnmtktpris:   Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[1]).toLocaleString('da')),

    selectedWeekcons:  findSelectedweekCons(chosedDaysarr[1]),
    selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[1]) > 0 ? (findSelectedweekCons(chosedDaysarr[1])/findSelectedWeektotal_count(chosedDaysarr[1])).toFixed(2) : 0 ,
    selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[1]) > 0 ? (findSelectedweekCons(chosedDaysarr[1])/findSelectedWeektotal_oms (chosedDaysarr[1])).toFixed(2) :0,

    selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[1]),
     selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[1]) > 0 ? (findSelectedWeekanother(chosedDaysarr[1])/findSelectedWeektotal_count(chosedDaysarr[1])).toFixed(2) : 0 ,
     selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[1]) > 0 ? (findSelectedWeekanother(chosedDaysarr[1])/findSelectedWeektotal_oms (chosedDaysarr[1])).toFixed(2) :0,

 },
 wednesday: { 
  selectedWeektotaltickets: findSelectedWeektotal_tkts(chosedDaysarr[2]),
  selectedWeektotaloms :    findSelectedWeektotal_oms (chosedDaysarr[2]),
  selectedWeekGnmtktpris:  Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[2]).toLocaleString('da')),

  selectedWeekcons:   findSelectedweekCons(chosedDaysarr[2]),

  selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[2]) > 0 ? (findSelectedweekCons(chosedDaysarr[2])/findSelectedWeektotal_count(chosedDaysarr[2])).toFixed(2) : 0 ,
  selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[2]) > 0 ? (findSelectedweekCons(chosedDaysarr[2])/findSelectedWeektotal_oms (chosedDaysarr[2])).toFixed(2) :0,

  selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[2]),
  selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[2]) > 0 ? (findSelectedWeekanother(chosedDaysarr[2])/findSelectedWeektotal_count(chosedDaysarr[2])).toFixed(2) : 0 ,
  selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[2]) > 0 ? (findSelectedWeekanother(chosedDaysarr[2])/findSelectedWeektotal_oms (chosedDaysarr[2])).toFixed(2) :0,

},
thursday: { 
  selectedWeektotaltickets: findSelectedWeektotal_tkts(chosedDaysarr[3]),
  selectedWeektotaloms :    findSelectedWeektotal_oms (chosedDaysarr[3]),
  selectedWeekGnmtktpris:  Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[3])),

  selectedWeekcons: findSelectedweekCons(chosedDaysarr[3]),
  selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[3]) > 0 ? (findSelectedweekCons(chosedDaysarr[3])/findSelectedWeektotal_count(chosedDaysarr[3])).toFixed(2) : 0 ,
    selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[3]) > 0 ? (findSelectedweekCons(chosedDaysarr[3])/findSelectedWeektotal_oms (chosedDaysarr[3])).toFixed(2) :0,

    selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[3]),
    selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[3]) > 0 ? (findSelectedWeekanother(chosedDaysarr[3])/findSelectedWeektotal_count(chosedDaysarr[3])).toFixed(2) : 0 ,
    selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[3]) > 0 ? (findSelectedWeekanother(chosedDaysarr[3])/findSelectedWeektotal_oms (chosedDaysarr[3])).toFixed(2) :0,

},
friday: { 
  selectedWeektotaltickets: findSelectedWeektotal_tkts(chosedDaysarr[4]),
  selectedWeektotaloms :    findSelectedWeektotal_oms (chosedDaysarr[4]),
  selectedWeekGnmtktpris:  Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[4])),

   selectedWeekcons:          findSelectedweekCons(chosedDaysarr[4]),
   selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[4]) > 0 ? (findSelectedweekCons(chosedDaysarr[4])/findSelectedWeektotal_count(chosedDaysarr[4])).toFixed(2) : 0 ,
   selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[4]) > 0 ? (findSelectedweekCons(chosedDaysarr[4])/findSelectedWeektotal_oms (chosedDaysarr[4])).toFixed(2) :0,

   selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[4]),
     selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[4]) > 0 ? (findSelectedWeekanother(chosedDaysarr[4])/findSelectedWeektotal_count(chosedDaysarr[4])).toFixed(2) : 0 ,
     selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[4]) > 0 ? (findSelectedWeekanother(chosedDaysarr[4])/findSelectedWeektotal_oms (chosedDaysarr[4])).toFixed(2) :0,

},
saturday: { 
  selectedWeektotaltickets: findSelectedWeektotal_tkts(chosedDaysarr[5]),
  selectedWeektotaloms :    findSelectedWeektotal_oms (chosedDaysarr[5]),
  selectedWeekGnmtktpris:  Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[5])),

  selectedWeekcons :findSelectedweekCons(chosedDaysarr[5]),
  selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[5]) > 0 ? (findSelectedweekCons(chosedDaysarr[5])/findSelectedWeektotal_count(chosedDaysarr[5])).toFixed(2) : 0 ,
  selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[5]) > 0 ? (findSelectedweekCons(chosedDaysarr[5])/findSelectedWeektotal_oms (chosedDaysarr[5])).toFixed(2) :0,

  selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[5]),
     selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[5]) > 0 ? (findSelectedWeekanother(chosedDaysarr[5])/findSelectedWeektotal_count(chosedDaysarr[5])).toFixed(2) : 0 ,
     selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[5]) > 0 ? (findSelectedWeekanother(chosedDaysarr[5])/findSelectedWeektotal_oms (chosedDaysarr[5])).toFixed(2) :0,

},
sunday: { 
  selectedWeektotaltickets: findSelectedWeektotal_tkts(chosedDaysarr[6]),
  selectedWeektotaloms :    findSelectedWeektotal_oms (chosedDaysarr[6]),
  selectedWeekGnmtktpris:  Number(findSelectedWeekGnm_tkt_pris(chosedDaysarr[6])),

  selectedWeekcons: findSelectedweekCons(chosedDaysarr[6]),
  
  selectedWeekcons_pr_tkt: findSelectedweekCons(chosedDaysarr[6]) > 0 ? (findSelectedweekCons(chosedDaysarr[6])/findSelectedWeektotal_count(chosedDaysarr[6])).toFixed(2) : 0 ,
  selectedWeekcons_tkt_oms:  findSelectedweekCons(chosedDaysarr[6]) > 0 ? (findSelectedweekCons(chosedDaysarr[6])/findSelectedWeektotal_oms (chosedDaysarr[6])).toFixed(2) :0,

  selectedWeekanother:        findSelectedWeekanother(chosedDaysarr[6]),
  selectedWeekanother_pr_tkt:   findSelectedWeekanother(chosedDaysarr[6]) > 0 ? (findSelectedWeekanother(chosedDaysarr[6])/findSelectedWeektotal_count(chosedDaysarr[6])).toFixed(2) : 0 ,
  selectedWeekanother_tkt_oms:findSelectedWeekanother(chosedDaysarr[6]) > 0 ? (findSelectedWeekanother(chosedDaysarr[6])/findSelectedWeektotal_oms (chosedDaysarr[6])).toFixed(2) :0,

},
  week1totaltickets: week1data.map(o => o.TotalResCount).reduce((a, c) => { return a + c }) + week1data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }),
  week1totaloms : week1data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) ,

  week1Gnmtktpris:  (week1data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c })/week1data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2),

  week2totaltickets: week2data.length > 1 && week2data.map(o => o.TotalResCount).reduce((a, c) => { return a + c }) + week2data.length > 1 &&  week2data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }),
  week2totaloms :week2data.length > 1 &&  week2data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) ,

  week2Gnmtktpris:  (week2data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c })/ week2data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2),

  week3totaltickets: week3data.map(o => o.TotalResCount).reduce((a, c) => { return a + c }) + week3data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }),
  week3totaloms : week3data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) ,
  week3Gnmtktpris: (week3data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) / week3data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2),
  
  week1cons: Math.round(week1cons.length > 1 && week1cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })),
  week1cons_pr_tkt :Number(( Math.round(week1cons.length > 1 && week1cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week1data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2)),
week1cons_tktoms : (Math.round(week1cons.length > 1 && week1cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week1data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) ).toFixed(2),

  week2cons: Math.round(week2cons.length > 1 && week2cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })),
  week2cons_pr_tkt :Number( ( Math.round(week2cons.length > 1 &&  week2cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week2data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2)),
week2cons_tktoms :(Math.round(week2cons.length > 1 &&  week2cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c }))/ week2data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c })).toFixed(2),

  week3cons: Math.round(week3cons.length > 1 &&  week3cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })),
  week3cons_pr_tkt :Number( ( Math.round(week3cons.length > 1 &&  week3cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week3data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2)),
week3cons_tktoms: (  Math.round(week3cons.length > 1 && week3cons.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) /week3data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) ).toFixed(2),

week1another:Math.round(week1another.length > 1 && week1another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) ,
week1another_pr_tkt: Number (( Math.round(week1another.length > 1 && week1another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week1data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2)),
week1another_tktoms: ( Math.round(week1another.length > 1 && week1another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c }))/week1data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }) ).toFixed(2),

week2another:Math.round(week2another.length > 1 && week2another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) ,
week2another_pr_tkt: Number(( Math.round(week2another.length > 1 && week2another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week2data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2)),
week2another_tktoms: ( Math.round(week2another.length > 1 && week2another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c }))/ week2data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c })).toFixed(2),

week3another:Math.round(week3another.length > 1 && week3another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) ,
week3another_pr_tkt:Number(( Math.round(week3another.length > 1 && week3another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c })) / week3data.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c })).toFixed(2)),
week3another_tktoms: ( Math.round(week3another.length > 1 && week3another.map(o => o.ConcessionRevenue).reduce((a, c) => { return a + c }))/week3data.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c })  ).toFixed(2),

 }]


/**-----NEW DATA SET (år til dato) */
// function findTotalTktsThisYear() {
//   let count = 0;
//  count = thisYearData.length > 1 && thisYearData.map(o => o.TotalResCount).reduce((a, c) => { return a + c }, 0) + thisYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)
//  return count
// //  console.log(count)
// }
// function findTotalTktsLastYear () {
//   let count = 0;
//   count = lastYearData.length > 1 ?  (lastYearData.map(o => o.TotalResCount).reduce((a, c) => { return a + c }, 0) +  lastYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)) : 0
//   return count
// }
// function findTotalTkts2yearsAgo() {
//   let count = 0;
//   count = twoYearsData.length ?  ( twoYearsData.map(o => o.TotalResCount).reduce((a, c) => { return a + c }, 0) +  twoYearsData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)) : 0
//   return count
// }

//  function findTotalSoldAmountThisYear(){
// let count = 0
// count = thisYearData.length ?  thisYearData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0) : 0
// return count
//  }

//  function findTotalSoldAmountLastYear(){
//   let count = 0
//   count = lastYearData.length ?  lastYearData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0) : 0
//   return count
//  }

//  function findTotalSoldAmount2YearsAgo(){
//   let count = 0
//   count = twoYearsData.length ? twoYearsData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0) : 0
//   return count
//  }
function findGnmTktPriceThisYear () {
  
  let count = 0
    count = thisYearData.length ? (thisYearData.length && thisYearData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0)/thisYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)).toFixed(2) : 0
    return count
}
function findGnmTktPriceLastYear () {
  let count = 0
    count = lastYearData.length ? (lastYearData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0)/lastYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)).toFixed(2) : 0
    return count
}
function findGnmTktPrice2YearsAgo () {
  let count = 0
    count   = twoYearsData.length ? ( twoYearsData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0)  /  twoYearsData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)).toFixed(2) : 0
    return count
}




const index_year_totaltkts = lastYearData.length > 1 ?  (lastYearData.map(o => o.TotalResCount).reduce((a, c) => { return a + c }, 0) +  lastYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)) : 0

const this_year_totaltkts = thisYearData.length > 1 ?  (thisYearData.map(o => o.TotalResCount).reduce((a, c) => { return a + c }, 0) +  thisYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)) : 0

const yearago_totaltks = twoYearsData.length ?  ( twoYearsData.map(o => o.TotalResCount).reduce((a, c) => { return a + c }, 0) +  twoYearsData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0)) : 0

const index_year_soldcount =  lastYearData.length ?  lastYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0) : 0

const this_year_soldcount = thisYearData.length > 1 ? thisYearData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0) : 0

const yearago_soldcount = twoYearsData.length > 1 ? twoYearsData.map(o => o.TotalSoldCount).reduce((a, c) => { return a + c }, 0) : 0

const index_year_totaloms = lastYearData.length ?  lastYearData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0) : 0

const this_year_totaloms = thisYearData.length ?  thisYearData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0) : 0

const yearago_totaloms = twoYearsData.length ?  twoYearsData.map(o => o.TotalSoldAmount).reduce((a, c) => { return a + c }, 0) : 0

const index_year_cons = lastYearsCons.length ?  Math.round(lastYearsCons.length  && lastYearsCons.map(o =>  o.ConcessionRevenue > 0 && o.ConcessionRevenue).reduce((a, c) => { return a + c }, 0) ) : 0

const this_year_cons = currentYearsCons.length ?  Math.round(currentYearsCons.length  && currentYearsCons.map(o =>  o.ConcessionRevenue > 0 && o.ConcessionRevenue).reduce((a, c) => { return a + c }, 0) ) : 0

const yearsago_cons = twoYearsCons.length  ? Math.round(twoYearsCons.length  && twoYearsCons.map(o =>  o.ConcessionRevenue > 0 && o.ConcessionRevenue).reduce((a, c) => { return a + c }, 0)) : 0

const index_year_other = lastYearsAnother.length  && lastYearsAnother.map(o =>  o.ConcessionRevenue > 0 && o.ConcessionRevenue).reduce((a, c) => { return a + c }, 0)

const this_year_other = currentYearsAnother.length && currentYearsAnother.map(o =>  o.ConcessionRevenue > 0 && o.ConcessionRevenue).reduce((a, c) => { return a + c }, 0)

const yearsago_other = twoYearsAnother && twoYearsAnother.length ?  Math.round(twoYearsAnother.length  && twoYearsAnother.map(o => o.ConcessionRevenue > 0 && o.ConcessionRevenue).reduce((a, c) => { return a + c }, 0)) : 0


function findAnotherPrTktLastYear () {
  let count = 0;
  count =(index_year_other/index_year_soldcount).toFixed(2)
  return count
}
function findAnotherPrTktThisYear() {
  let count = 0;
  count = (this_year_other/this_year_soldcount).toFixed(2)
  return count
}
function findAnotherPrTkt2YearsAgo() {
  let count = 0;
  count = (yearsago_other/yearago_soldcount).toFixed(2)
  return count
}
function findConsPrTktLastYear() {
  let count = 0;
  count = (index_year_cons/index_year_soldcount).toFixed(2)
  return count
}
function findConsPrTktThisYear() {
  let count = 0;
  count = (this_year_cons/this_year_soldcount).toFixed(2)

  return count
}

function findConsPrTkt2YearsAgo() {
  let count = 0;
  
  count = (yearsago_cons/yearago_soldcount).toFixed(2)
  return count
}

const yearly_data = 
[{
  currentYear: {
         total_tkts: this_year_totaltkts,
         index_a:this_year_soldcount > 0 && index_year_soldcount  > 0 ? Math.round((this_year_soldcount/index_year_soldcount)*100) :0,
         total_sold_amount: Math.round(this_year_totaloms),
         index_b: this_year_totaloms > 0  && index_year_totaloms > 0 ? Math.round((this_year_totaloms/index_year_totaloms)*100) : 0,
         gnmTktPris:this_year_totaloms > 0 &&  this_year_soldcount > 0 ? Number( findGnmTktPriceThisYear()) : 0,
         yearly_cons: Math.round( this_year_cons ),
       
         index_c : this_year_cons > 0 && index_year_cons > 0 ? Math.round(( this_year_cons/index_year_cons)*100) : 0,

         yearly_cons_pr_tkt:  this_year_cons > 0 && this_year_soldcount > 0 ?   Number(findConsPrTktThisYear()) : 0,

         yearly_cons_pr_tktoms: this_year_totaloms > 0 && this_year_cons > 0 ? (this_year_cons / this_year_totaloms).toFixed(2) : 0,

         yearly_another: Math.round(this_year_other),

         index_d: this_year_other > 0 && index_year_other > 0 ? Math.round(( this_year_other/index_year_other) *100) : 0,

          yearly_another_pr_tkt: this_year_other > 0 && this_year_soldcount > 0 ? Number(findAnotherPrTktThisYear()) : 0,

         yearly_another_pr_tktoms: this_year_totaloms > 0 && this_year_other > 0 ? (this_year_other / this_year_totaloms ).toFixed(2) : 0
  },
  lastYear:{
        total_tkts:  index_year_totaltkts,
        index_a: 100,
        total_sold_amount: Math.round(index_year_totaloms),
        index_b: 100,
        gnmTktPris: index_year_soldcount  > 0  && index_year_totaloms > 0 ? Number(findGnmTktPriceLastYear ()) : 0,
        yearly_cons: Math.round(index_year_cons),
        index_c : 100,
        yearly_cons_pr_tkt: index_year_soldcount  > 0  && index_year_cons > 0 ? Number(findConsPrTktLastYear()) : 0,

        yearly_cons_pr_tktoms: index_year_totaloms > 0 && index_year_cons > 0 ? (index_year_cons / index_year_totaloms).toFixed(2) : 0,

        yearly_another: Math.round(index_year_other),

        index_d: 100,
        yearly_another_pr_tkt: index_year_soldcount  > 0 && index_year_other > 0 ? Number(findAnotherPrTktLastYear()): 0,

        yearly_another_pr_tktoms: index_year_totaloms > 0 && index_year_other > 0 ? (index_year_other / index_year_totaloms ).toFixed(2) : 0

  },
  twoYearsAgo:{
       total_tkts: yearago_totaltks ,
       index_a: yearago_soldcount > 0 && index_year_soldcount > 0  ? Math.round((yearago_soldcount/index_year_soldcount)*100) : 0,
       total_sold_amount: Math.round(yearago_totaloms),
       index_b: yearago_totaloms > 0 && index_year_totaloms > 0 ? Math.round((yearago_totaloms/index_year_totaloms)*100) : 0,
       gnmTktPris:yearago_totaloms > 0 && yearago_soldcount ?   Number(findGnmTktPrice2YearsAgo ()) : 0,
       yearly_cons: Math.round(yearsago_cons),

       index_c : yearsago_cons > 0 &&  index_year_cons > 0 ?  Math.round((yearsago_cons/index_year_cons)*100) : 0,

       yearly_cons_pr_tkt: yearago_soldcount  > 0 && yearsago_cons > 0  ?  Number(findConsPrTkt2YearsAgo()) : 0,

       yearly_cons_pr_tktoms: yearsago_cons > 0 && yearago_totaloms > 0  ? ( yearsago_cons / yearago_totaloms).toFixed(2) : 0,

       yearly_another:Math.round(yearsago_other),

       index_d: yearsago_other > 0 &&  index_year_other > 0 ?   Math.round(( yearsago_other/ index_year_other)*100) : 0,

       yearly_another_pr_tkt: yearsago_other > 0 && yearago_soldcount > 0 ? Number(findAnotherPrTkt2YearsAgo()) : 0,
       
       yearly_another_pr_tktoms: yearago_totaloms > 0  && yearsago_other > 0 ? (yearsago_other/yearago_totaloms).toFixed(2) : 0

  }
}]
// console.log(currentWeekcons)
 function weekContClicked (e) {
  e.preventDefault()
    setWeekBoxOpen(true)

 }
 // week count in a year
 const rows = []
 for (let index = 1; index < 53; index++) {      
         rows.push(index)            
      }

  function handleYearDec() {
    setSelectedYear(selectedYear - 1)
  }

  function handleYearInc() {
    setSelectedYear( selectedYear + 1)
  }



  function weekNrChange (e) {
    e.preventDefault()

   setSetselectedWeekNr(e.target.innerText);
   setWeekBoxOpen(false)
   setTotalMovieSale('')
  }
  
  //  console.log(yearly_data, 'yearly data')
/**--------MAKING SURE WE HAVE ALL THE DATA  */
    useEffect ( ( ) => {

     if( chosenCinema ===  true && nodata === false)
        {
          if( !data.length  || !oms_in_all.length || !yearly_data.length || !movies_nos.length ||!totalMovieSale.length )
        
             {       
 
            setIsLoading(true)
        }
        else setIsLoading(false)
      } 
     },[chosenCinema,data.length,nodata,oms_in_all.length,yearly_data.length,movies_nos,totalMovieSale.length])

  
 if(weekBoxOpen) {
  document.body.style.overflowY='hidden'
 }  else document.body.style.overflowY= ''
let theHeight = window.document.body.clientHeight
// console.log(theHeight)
    return(
        <main className='rapport' >
          <div 
          style={{width:  weekBoxOpen ? '1438px' : '',height:`${ !nodata ?  theHeight :containerHeight}px`,position:'fixed',top: window.location.pathname === '/fourweeksreport' ? '50px':'70px',left:`${leftSpace}px`,backgroundColor: weekBoxOpen && theme === 'light' ? 'rgba(0,0,0,0.2)' : weekBoxOpen && theme === 'dark' ? 'rgba(0,0,0,0.5)' : '',zIndex:10}}
          ></div>
            <p style={{ fontSize: "25px", color:theme === 'dark' ? '#fff': "#000",letterSpacing:'0.5px',fontWeight:600 }}>Fireugersrapport</p>

           <section className='fourWeeksCalcontainer'>     
             <BiografSelect/>
             <div className='weeknumberCont movieListBox'>
             <p className="movie"  style={{color: theme === 'dark' ? '#fff' : '#46484c',opacity:theme === 'dark' ? '0.6' : '',marginTop:'5px'}} >Uge</p>
           
             <div className='weeknumber_select' onClick={(e) => weekContClicked(e)} style={{backgroundColor: theme === 'dark' ? '#65696c':'white'}}>
              <p style={{padding:'6px',letterSpacing:'0.32px'}}>Uge {selectedWeekNr}, {selectedYear}</p>
              <span className="selectbox-line" style={{backgroundColor:  '#8d8d8d' }}></span>
              <img className='weeknumber_arrow' src={theme === 'dark' ? whiteArrow :  downArrow} alt='down arrow' />
             </div>
             { weekBoxOpen && <div className='week_box' style={{backgroundColor: theme === 'dark' ? '#65696c':'#fff',color: theme === 'dark' ? '#fff' : ''}}>
                 
                <div style={{textAlign:'center', marginTop:'20px'}}>
                   <button onClick={handleYearDec}> <img style={{rotate:'90deg',height:'15px',marginRight:'5px',marginBottom:'2px'}} src=   {theme === 'dark' ? whiteArrow :  downArrow} alt='down arrow'/></button>
                   
                   <span style={{padding:'5px 0px'}}>{selectedYear}</span> 
                   <button onClick={handleYearInc}><img style={{rotate:'269deg',height:'15px',marginLeft:'5px',marginBottom:'2px'}} src={theme === 'dark' ? whiteArrow :  downArrow} alt='down arrow'/> </button>
                   
                </div>
                 <div className='chooseWeekText' style={{color:theme === 'dark' ? '#fff' : ''}}> Vælg ugenummer</div>
                 <div style={{display:'flex', flexWrap:'wrap', gap:'6px',padding:'10px'}}>
                   { rows.map((row) => {
                      return <button onClick={(e) => weekNrChange(e)} style={{border: selectedWeekNr === row ? '1px' :'1px solid #8d8d8d',height:'28px', letterSpacing:'0.32px',
                        width:'28px', borderRadius:'3px',textAlign:'center',
                        backgroundColor: selectedWeekNr == row ? '#982f2f' : '',
                      color: selectedWeekNr == row || theme === 'dark' ? '#fff' : '',paddingLeft:'4px', paddingTop:'0px'}}>
                     <span>   {row === 1  || row === 2 || row === 3 || row === 4 || row === 5 || row === 6 || row === 7 || row === 8 || row === 9 ? '0' : ''}
                      {row} </span>   </button>
                   }) }
                </div>

                  </div>}
             <p className='fourweeksreportdesc smallText' style={{color:theme === 'dark' ?  'white' : ''}}> ... samt de tre foregående uger</p>
             </div>
             {/* <button className='print-btn'>Udskriv</button> */}

           </section>



    <div
            style={{ height: isLoading === true && 200, position: "relative" }}
          >
            {isLoading ? (
              <PulseLoader
                color="#982f2f"
                loading={isLoading}
                cssOverride={override}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              " "
            )}
          </div>

          {chosenCinema === true  ? <div>
            {nodata === true ?
            <>
    <br/> <br/> <h3>{errorText && errorText}</h3> </> : 
 data.length && totalMovieSale.length &&  oms_in_all.length && yearly_data.length && movies_nos.length && isLoading === false ? <FourWeeksTable 
 selectedWeekNr= {selectedWeekStart && selectedWeekNr}                 
 selectedWeekStart= {selectedWeekStart && selectedWeekStart } 
 selectedWeekEnd= {selectedWeekEnd && selectedWeekEnd}  
 data={data && data}
 currentweekdata = {currentweekdata && currentweekdata}
  week1data = {week1data && week1data}
  week2data = {week2data && week2data}
  week3data = {week3data && week3data}
  data_per_year={yearly_data && yearly_data}
 oms_in_all={oms_in_all && oms_in_all} 
 week1start = {week1start}
 week2start = {week2start}
 week3start = {week3start}
 selectedYear={selectedYear} 
 movies_nos = {movies_nos}
 totalMovieSale={totalMovieSale}
 orgNr={orgNr && orgNr}
 sortedData={sortedData && sortedData}
 /> 
 : ''
  }
  </div> : ''
    }

    

        </main>
    )
}

export default FourWeeksReport