import React, { useState, useContext, useEffect } from "react";
// import { format } from 'date-fns';
// import { DayPicker } from 'react-day-picker';
// import 'react-day-picker/dist/style.css';
// import Calendar from "./Calendar";
// import "./calendar.css";
// import "./datepicker.css";
import { format, isSameMonth } from "date-fns";
import { DayPicker } from "react-day-picker";
import da from "date-fns/locale/da";
import moment from "moment";
import { addMonths } from "date-fns";
import { CaptionProps, useNavigation } from "react-day-picker";

import { keyContext } from "../KeyContext";

import calendar from "../../images/Icon-Calendar.png";
import downArrow from "../../images/arrow-down.svg";
import close from "../../images/close.png";
import icon from "../../images/icon-black.png";


function DatePicker() {
  const { selectedDate, setSelectedDate } = useContext(keyContext)
  // const [selected, setSelected] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
 
  moment.updateLocale("da", {
    months:
      "Januar_Februar_Marts_April_Maj_Juni_Juli_August_September_Oktober_November_December".split(
        "_"
      ),
  });

const disabledDays = [  { from: new Date(2022, 0, 1), to:  moment().subtract(1, 'day').toDate() }  ] ;

    let date = format(selectedDate, "d. ") + moment(selectedDate).locale("da").format("MMMM") + "  " + format(selectedDate, "yyyy")


  function handleClick() {
    setShowCalendar((current) => !current);
  }
  function closeCalendar() {
    setShowCalendar(false);
    // toggleCalendar();
  }
  

const getNextDate = () => {

  setSelectedDate(moment(selectedDate).add(1,'days')._d)

 
};
const getPreviousDate = () => {
  
  setSelectedDate(moment(selectedDate).subtract(1,'days')._d)

};

function checkIsBefore(date1, date2) {
  return moment(date1).isSameOrBefore(date2); 
}

let  bool = checkIsBefore(moment(selectedDate).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD'));
// console.log(moment(selectedDate).format('DD-MM-YYYY'), moment(new Date()).format('DD-MM-YYYY'))
// console.log(bool);


  return (
    <>
      <div style={{position:'relative', top:'10px', width:'241px',display:'flex',justifyContent:'space-between',border:'1px'}}>
     {bool === true ?  <button style={{marginTop:'-30px',opacity:0.4}} >
          <svg style={{transform:'rotate(90deg)',}} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 9">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="Gray" strokeWidth="2">
            <g>
                <path d="M934 19.25L940.5 26.25 947 19.25" transform="translate(-1053 -509) translate(120 491)"/>
            </g>
        </g>
    </g>
</svg> </button> :
        <button style={{marginTop:'-30px'}}     onClick={getPreviousDate}>
          <svg style={{transform:'rotate(90deg)',}} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 9">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="Gray" strokeWidth="2">
            <g>
                <path d="M934 19.25L940.5 26.25 947 19.25" transform="translate(-1053 -509) translate(120 491)"/>
            </g>
        </g>
    </g>
</svg> </button>
}
       <span style={{marginLeft:'0px',fontSize:'18px'}}>{date}</span> 
    
<button   onClick={handleClick} style={{padding:0,marginTop:'-30px', marginLeft:'10px'}}>
     <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 20" fill="none">
    <path d="M4 .5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4zM9 .5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4zM14 .5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4z" fill="#46484C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17 3H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h15a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" fill="#46484C"/>
    <path d="M3 7h3v2H3V7zM3 11h3v2H3v-2zM3 15h3v2H3v-2zM8 7h3v2H8V7zM8 11h3v2H8v-2zM8 15h3v2H8v-2zM13 7h3v2h-3V7zM13 11h3v2h-3v-2zM13 15h3v2h-3v-2z" fill="#46484C"/>
     </svg>
</button>
     
        <button style={{marginTop:'-30px'}} onClick={() => getNextDate()}>
      <svg style={{transform:'rotate(-90deg)',}} xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 9">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="grey" strokeWidth="2">
            <g>
                <path d="M934 19.25L940.5 26.25 947 19.25" transform="translate(-1053 -509) translate(120 491)"/>
            </g>
        </g>
    </g>
</svg> </button>
     
     

      {showCalendar ? (
        <div
          className={showCalendar ? "programCalendar slide-down" : "Calendar slide-up"}
        >
          <figure style={{ textAlign: "right" }}>
            <img
              src={close}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={closeCalendar}
            />
          </figure>
          <div className="weekdays"  >
                            <span className="days">Man</span>
                            <span className="days">Tirs</span>
                            <span className="days">Ons</span>
                            <span className="days">Tors</span>
                            <span className="days">Fre</span>
                            <span className="days">Lør</span>
                            <span className="days">Søn</span>
                          </div>
          <DayPicker
            locale={da} ISOWeek
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            onDayClick={closeCalendar}
            disabled={disabledDays}
            showWeekNumber 
            fromYear={2022} toYear={2030}
            captionLayout='dropdown'
            showOutsideDays
            
          />
          
        </div>
      ) : (
        ""
      )}
 </div>
    </>
  );
}

export default DatePicker;


