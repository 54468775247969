import "./darkmode.css";
// import { ChangeEventHandler } from "react";

const setDark = () => {

  document.documentElement.setAttribute("data-theme", "dark");
  localStorage.setItem("theme", "dark");
};

const setLight = () => {
  
  document.documentElement.setAttribute("data-theme", "light");
  localStorage.setItem("theme", "light");
};

const storedTheme = localStorage.getItem("theme");

const prefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark =
  storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
    setDark();
}

const toggleTheme = (e) => {
  if (e.target.checked) {
    setDark();
  } else {
    setLight();
  }
  //window.location.reload()
};

const DarkMode = () => {
  return (
    <div className="toggle-theme-wrapper" onClick={(e) => toggleTheme(e)} style={{margin: '3px 10px 10px 10px'}}>
      {/* <span>☀️ 🌒  tema</span> */}
    
      <label className="toggle-theme" htmlFor="checkbox"> 
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          defaultChecked={defaultDark}
        />
        <div className="slider round"></div>
      </label>
      {/* <span>🌒</span> */}
    </div>
  );
};

export default DarkMode;
