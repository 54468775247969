import React, { useState, useEffect, useContext } from 'react'
import Header from '../components/header/Header'
import Dagperiode from './DayPeriode';
import UgesRapport from './WeekReport';
import PeriodeSam from './PeriodeSam';
import WorkPlan from './WorkPlan';
import '../components/css/mobilReports.css'
// import ReportsMenus from '../components/ReportsMenus'
import Dagens from './Dagens';
// import Dagperiode from './Dagperiode'
import { useNavigate } from "react-router-dom";
import { keyContext } from "../components/KeyContext";
import smallArrow from '../images/small_arrow.svg';
import smallWhite from '../images/smallWhite.svg'

function DagensMobil() {
    const { open, isChecked } = useContext(keyContext)

    let key = sessionStorage.getItem("key");
  let orgID = sessionStorage.getItem("orgID");
  const navigate = useNavigate();
  const theme = localStorage.getItem('theme')
  const [rights, setRights] = useState([{}]);

  const [isShown, setIsShown] = useState(false);
  function openReports() {
    setIsShown( current => !current)
  }

  useEffect(() => {
    const endpoint = `https://biograf.ebillet.dk/systemnative/report.asmx/man_getaccessrights?Key=${key}&LoginNo=${orgID}`;
    if (key && orgID) {
      fetch(endpoint, {
        method: "GET",
        headers: {
          ebilletToken: "RGV0IGVyIGVuIGthdCBvZyBpa2tlIGVuIGdyaXM=",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result)
          if (result.result === 0) {
            navigate("/");
          }
          setRights(result);
        });
    }
  }, [key, orgID]);

  const [pageKey, setPageKey] = React.useState("e");

  const Menu = (props) => {
    return (
      <div id="meu" className="has-scrollbar">
        <button
          style={{
            border: "none",
            background: "transparent",
            color: theme === 'dark' ? '#fff' : '#000',
            marginBottom: 6 + 'px',
          }}
          onClick={() => {
            props.onClick("a");
          }}
        >
          Ugestatestik{" "}
        </button>
      </div>
    );
  };

  let page;
  switch (pageKey) {
    case "a":
      page = <UgesRapport />;
      break;

    case "c":
      page = <Dagperiode />;
      break;

    case "e":
      page = <Dagens />;
      break;

      case "f":
        page = <PeriodeSam />;
        break;

         case "x":
            page= <WorkPlan />
            break;
    default:
      page = "";
      break;
  }
  const PeriodeMenu = (props) => {
    return (
      <button
        style={{
          borderRadius: "4px",
          marginBottom: 6 + "px",
          textAlign: "left",
          backgroundColor: theme === 'dark' ? '':"white",
          color: pageKey === "f" && theme !== 'dark'? "#982f2f" : (pageKey === 'f' && theme === 'dark') ? '#ecc590' : theme === 'dark' ? '#fff':"black",width:'248px',padding:'5px',border: theme === 'dark' ? '1px solid #6c6c6c' : ''
        }}
        onClick={() => {
          props.onClick("f");
        }}
      >
        Periodesammenligninger
      </button>
    );
  };
  const WorkplanMenu = (props) => {
    return (
      <button
        style={{
          borderRadius: "4px",
          marginBottom: 6 + "px",
          textAlign: "left",
          backgroundColor: theme === 'dark' ? ' ': "white",
          color: pageKey === "x" && theme !== 'dark'  ? "#982f2f" : (pageKey === 'x' && theme === 'dark') ? '#ecc590' :  theme === 'dark' ? '#fff': "black",width:'248px',padding:'5px',border: theme === 'dark' ? '1px solid #6c6c6c' : ''
        }}
        onClick={() => {
          props.onClick("x");
        }}
      >
        Arbejdsplan
      </button>
    );
  };
  const DagsMenu = (props) => {
    return (
      <div>
        <button
          style={{
            borderRadius: "4px",
            marginBottom: 6 + "px",
            backgroundColor: theme === 'dark' ? '': "white",
            color: pageKey === "c" && theme !== 'dark' ? "#982f2f" : pageKey === 'c' && theme === 'dark' ? '#ecc590' : theme === 'dark'? '#fff' : "black",width:'248px',padding:'5px',textAlign:'left',border: theme === 'dark' ? '1px solid #6c6c6c' : ''
          }}
          onClick={() => {
            props.onClick("c");
          }}
        >
          {" "}
          Dagsperioder
        </button>

       
      </div>
    );
  };
  const DagensMenu = (props) => {
    return (
      <button
        style={{
          borderRadius: "4px",
              marginBottom: 6 + "px",

          textAlign: "left",
          backgroundColor: theme === 'dark' ? '': "white",
          color: (pageKey === "e" && theme !== 'dark') ? "#982f2f" : pageKey === 'e' && theme === 'dark' ? '#ecc590' : theme === 'dark' ? '#fff': "black",
          width:'248px',padding:'5px',border: theme === 'dark' ? '1px solid #6c6c6c' : ''
        }}
        onClick={() => {
          props.onClick("e");
        }}
      >
        Dagens omsætning
      </button>
    );
  };
  return (
    
<div className='containers'>
    <Header isActive="active"/>
    <main className='rapport-mobil' style={{minHeight:'954px',display: isChecked === true ? 'block' : '', backgroundColor: open && isChecked === true ? 'rgba(0,0,0,0.5'  : '',opacity: theme === 'dark' && open ? '0.1':''}}>
            <aside className='aside ugeaside' style={{display: isChecked === true ? 'none' : ''}}>

            {rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_COMPAREPERIODS")
              return (
                <div className="men" key={index}>
                  <PeriodeMenu onClick={setPageKey} />
                </div>
              );
          })}   

        {rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_DAYPERIODS")
              return (
                <div className="men" key={index}>
                  <DagsMenu onClick={setPageKey} />
                </div>
              );
          })}
        {rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_DAYREVENUE")
              return (
                <div className="men" key={index}>
                  <DagensMenu onClick={setPageKey} />
                </div>
              );
          })}
 
          {/* <div className="men">
            <PeriodeMenu onClick={setPageKey} />
          </div> */}

{rights &&
                  rights.map((right, index) => {
                    if (right.name === "ACC_WEEKSTAT")
                      return (
                    <>
                        <div className="reportsTitles" style={{
                          margin:'0px 4px 8px 0',paddingLeft:'5px',
                          color: 
                          ((pageKey === "z" || pageKey === "b") && theme === 'dark') ? '#ecc590'  : ((pageKey === "z" || pageKey === "b") && theme !== 'dark') ?  "#982f2f"  : (pageKey !== 'z' && theme === 'dark') ? 'white' : '#101010'

                          ,fontWeight: (pageKey === 'z' || pageKey === 'b')  && 'bold', border: theme === 'dark'  ?  '1px solid #6c6c6c' : '',backgroundColor:theme !== 'dark' ? 'white' : isShown && theme !== 'dark' ? '#982f2f' : '',width:'248px',marginBottom:'6px'}}>Ugestatistikrapporter
                        <img src={ theme === 'dark' ? smallWhite : smallArrow} onClick={openReports} style={{rotate :isShown ?  '180deg' : '', marginRight:'10px'}} alt="arrow"  />
                        </div>
                       { isShown && 
                       <div className="side-submenu">
                         <Menu onClick={setPageKey} />
                       </div>
                  }
                       </>
                        )
                      
                  })} 

{rights &&
          rights.map((right, index) => {
            if (right.name === "ACC_WORKPLAN")
              return (
                <div className="men" key={index}>
               
       <WorkplanMenu onClick={setPageKey} />
       </div>
      
       );
   })}
            </aside>

            <div className='rapporter' style={{backgroundColor: open && isChecked === false ? 'rgba(0,0,0,0.5'  : ''}}>
             {
              page ? page : <Dagens propspageKey = "a"/>
             }
              

            </div>
             
        </main>
</div>
  )
}

export default DagensMobil