import React, { useEffect, useContext, useState } from "react";

import NavMenu from "../components/NavMenu";
import "../components/css/configuration.css";
import { keyContext } from "../components/KeyContext";

import "../App.css";
import DarkMode from "../components/DarkMode";
import Header from "../components/header/Header";


function Configuration() {
  const {
    open,
    setIsChecked,
    isChecked,
    isSoldChecked,
    setIsSoldChecked,
    isTicketChecked,
    setIsTicketChecked,
    isKioskChecked,
    setIsKioskChecked,
    isKioskOmsChecked,
    setIsKioskOmsChecked,
    isSamletChecked,
    setIsSamletChecked,
    isAndenChecked,
    setIsAndenChecked,
  } = useContext(keyContext);

  // console.log(isSoldChecked)
  sessionStorage.setItem("antalSoldChecked", isSoldChecked);
  sessionStorage.setItem("kioskChecked", isKioskChecked);
  sessionStorage.setItem("andenChecked", isAndenChecked);
  sessionStorage.setItem("kioskomsChecked", isKioskOmsChecked);
  sessionStorage.setItem("samletChecked", isSamletChecked);
  sessionStorage.setItem("ticketChecked", isTicketChecked);

  const [tema, setTema] = useState("");
  let theme = localStorage.getItem("theme");
  const [reportChecked, setReportChecked] = useState(localStorage.getItem("reportChecked") === "true" ? true : false );

reportChecked === true ?  localStorage.setItem('reportChecked', reportChecked) :
localStorage.removeItem('reportChecked', reportChecked)

  useEffect(() => {
    document.title = "Opsætning - ebillet_admin";
  }, []);

  useEffect(() => {
    setTema(theme);
  }, [theme]);

  function handleReportCheck () {
    setReportChecked((current) => !current)
  }

  function handleOnChange() {
    setIsChecked((current) => !current);
  }

  function handleKioskCheck() {
    setIsKioskChecked((current) => !current);
  }
  function handleAndenCheck() {
    setIsAndenChecked((current) => !current);
  }
  function handleKioskOmsCheck() {
    setIsKioskOmsChecked((current) => !current);
  }
  function handleSamletCheck() {
    setIsSamletChecked((current) => !current);
  }

  function handleSoldCheck() {
    setIsSoldChecked((current) => !current);
  }

  function handleTicketCheck() {
    setIsTicketChecked((current) => !current);
  }
  
  return (
    <main className="containers ">
      <Header setsActive="active" />
      <div
        style={{
          backgroundColor: open ? "rgba(0,0,0,0.7" : "",
          opacity: theme === 'dark' && open ? '0.1':'',
          display: isChecked === true ? "block" : "",
        }}
        className="config-container"
      >
        <aside
          className="aside"
          style={{ display: isChecked === true ? "none" : "", }}
        ></aside>
        <section className="config-section">
          <p className="setting_title text_color" >Opsætning</p>
          <br />
          <div className="themBox">
            <p className=" text_color" style={{fontSize: '18px', fontWeight:'bold',letterSpacing:'0.36px' }}>Farvetema</p> 
          <div style={{border:'1px ',display:'flex'}}> 
           <span className="opacity">Lyst </span>    <DarkMode /> <span className="opacity">Mørkt</span> </div>
          </div>
          <br />   
         
          <div style={{marginTop:'10px'}}>
           <p className="text_color" style={{fontSize: '18px', fontWeight:'bold',letterSpacing:'0.36px' }}> Generelle indstillinger</p>
            
            <div style={{ position: "relative",top:'5px' }}>
              <input
                type="checkbox"
                checked={isChecked}
                style={{ width: 20, height: 20 }}
                onChange={handleOnChange}
              />
              <span className="opacity"
                style={{
                  position: "absolute",
                  left: "40px",
                  top: "-2px",
                  letterSpacing: "0.32px",
                }}
              >
                Burgermenu altid aktiv
              </span>
            </div>
          </div>
            
          <br />
          <br />
          <div>
            <p className="text_color"  style={{fontSize: '18px', fontWeight:'bold',letterSpacing:'0.36px' }}> Periodesammenligninger</p>
          
            <p className="opacity" style={{fontWeight:'bold',paddingTop:'7px'}}>Visning af data:</p>
            <section className="pillarsConfig">
              <div className="configs">
                <div style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    checked={isSoldChecked}
                    onChange={handleSoldCheck}
                    style={{ width: 20, height: 20 }}
                  />
                  <span className="opacity"
                    style={{
                      position: "absolute",
                      left: "40px",
                      top: "-2px",
                      letterSpacing: "0.32px",
                      // color: "#46484c",
                    }}
                  >
                    Antal Solgte billetter
                  </span>
                </div>
                <br />
                <div style={{ position: "relative", width: "200px" }}>
                  <input
                    type="checkbox"
                    checked={isTicketChecked}
                    style={{ width: 20, height: 20 }}
                    onChange={handleTicketCheck}
                  />
                  <span className="opacity"
                    style={{
                      position: "absolute",
                      left: "40px",
                      top: "-2px",
                      letterSpacing: "0.32px",
                      // color: "#46484c",
                    }}
                  >
                    Billetomsætning
                  </span>
                </div>
              </div>

              <div className="configs">
                <div style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    checked={isKioskChecked}
                    style={{ width: 20, height: 20 }}
                    onChange={handleKioskCheck}
                  />
                  <span className="opacity"
                    style={{
                      position: "absolute",
                      left: "40px",
                      top: "-2px",
                      letterSpacing: "0.32px",
                      // color: "#46484c",
                    }}
                  >
                    Kioskomsætning
                  </span>
                </div>
                <br />
                <div style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    checked={isKioskOmsChecked}
                    style={{ width: 20, height: 20 }}
                    onChange={handleKioskOmsCheck}
                  />
                  <span className="opacity"
                    style={{
                      position: "absolute",
                      left: "40px",
                      top: "-2px",
                      letterSpacing: "0.32px",
                      
                      lineHeight: "1.25",
                    }}
                  >
                    Kioskomsætning pr. solgt billet
                  </span>
                </div>
              </div>

              <div className="configs">
                <div style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    checked={isAndenChecked}
                    style={{ width: 20, height: 20 }}
                    onChange={handleAndenCheck}
                  />
                  <span className="opacity" 
                    style={{
                      position: "absolute",
                      left: "40px",
                      top: "-2px",
                      letterSpacing: "0.32px",
                      // color: "#46484c",
                    }}
                  >
                    Anden omsætning
                  </span>
                </div>
                <br />
                <div style={{ position: "relative" }}>
                  <input
                    type="checkbox"
                    checked={isSamletChecked}
                    onChange={handleSamletCheck}
                    style={{ width: 20, height: 20 }}
                  />
                  <span className="opacity"
                    style={{
                      position: "absolute",
                      left: "40px",
                      top: "-2px",
                      letterSpacing: "0.32px",
                      // color: "#46484c",
                    }}
                  >
                    Samlet omsætning
                  </span>
                </div>
              </div>
            </section>
          </div>
          <br />
          <br />
          <div>
          <p className="text_color" style={{fontSize: '18px', fontWeight:'bold',    letterSpacing:'0.36px' }}> Ugestatistikrapporter</p>
             
          <div style={{ position: "relative",top:'5px' }}>
          <input
                type="checkbox"
                checked={reportChecked}
                style={{ width: 20, height: 20 }}
                onChange={handleReportCheck}
              /> 
              <span className="opacity"
                style={{
                  position: "absolute",
                  left: "40px",
                  top: "-2px",
                  letterSpacing: "0.32px",
                }}
              >
                Billetsalg pr. uge og Billetsalg pr. forestilling på én side
              </span>
          </div>
          </div>
        </section>
      </div>

      
    </main>
  );
}

export default Configuration;
