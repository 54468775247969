import React from 'react'
import search from '../images/search-26233.png'
import cross from '../images/cross.png'
import eye from '../images/Icon-Eye-2023.png'

function Modal({ children, shown, close, movie_name, sal_nr,dato,start_time,end_time }) {
    // function Modal({ children, shown, close }) {
        return shown ? (
          <div
            className="modal-backdrop"
            onClick={() => {
              // close modal when outside of modal is clicked
              close();
            }}
          >
            <div
              className="modal-content"
              onClick={e => {
                // do not close modal if anything inside modal content is clicked
                e.stopPropagation();
              }}
            >
              {/* <button onClick={close}>Close</button> */}
              {children}
            <section className='modalContainer'>
              <div className='modal_title'>
                <span style={{marginRight:'40px'}}>{movie_name}</span>
                <span style={{marginRight:'40px'}}>{sal_nr}</span>
                <span style={{marginRight:'40px'}}>dato</span>
                <span>kl. {start_time} - {end_time}</span>
              </div>
              <div className='modal_desc'>
                <div style={{display:'flex',justifyContent:'space-between',marginRight:'30px'}}>
            <div>
                 <div style={{paddingLeft:'30px',paddingTop:'20px'}}>
                    <p style={{fontWeight:'bold',height:'15px'}}>Prisgrupper</p>
                    <div style={{width:'187px',height:'120px',display:'flex',justifyContent:'space-between',fontSize:'12px',margin:0,padding:0}}>
                      <div style={{}}>
                         <p style={{height:'5px',fontWeight:'bold'}}>Navn</p>
                         <p style={{margin:0,padding:0}}>Prisgruppe 1</p>
                         <p style={{margin:0,padding:0}}>Prisgruppe 2</p>
                         <p style={{margin:0,padding:0}}>Prisgruppe 3</p>
                         <p style={{margin:0,padding:0}}>Prisgruppe 4</p>
                       </div>
                      <div>
                        <p style={{height:'8px',fontWeight:'bold'}}>Pris kr.</p>
                        <input type="text" name="" id="" style={{width:'73px',height:'14px',display:'block',marginBottom:'3px'}}/>
                        <input type="text" name="" id="" style={{width:'73px',height:'14px',display:'block',marginBottom:'3px'}}/>
                        <input type="text" name="" id="" style={{width:'73px',height:'14px',display:'block',marginBottom:'3px'}}/>
                        <input type="text" name="" id="" style={{width:'73px',height:'14px',display:'block',marginBottom:'3px'}}/>
                      </div>

                      
                         
                    </div>
                 </div>

                 <div style={{left:'30px ',position:'relative'}}>
                  <p style={{fontWeight:'bold',height:'18px'}}>Billettyper</p>
                  <input type="search" name="" id="" style={{width:'500px',paddingLeft:'35px'}} placeholder='Søg billettype'/>
                  <span>
                <span style={{position:'absolute', top:'36px',left:'8px'}}><img src={search} alt='search' height='20px'/></span>

                  </span>
                 </div>

                 <div style={{display:'grid',marginLeft:'30px',width:'500px',fontSize:'12px',fontWeight:'bold',marginTop:'20px',gridTemplateColumns:'1fr .3fr .3fr .1fr .3fr',height:'20px'}}>
                  <p style={{fontWeight:'bold'}}>Valgte</p>
                  <p style={{fontWeight:'bold'}}>Website</p>
                  <p style={{fontWeight:'bold'}}>Bundlesalg</p>
                  <p><img src={eye} alt="" /></p>
                  <p style={{fontWeight:'bold'}}>Pris kr.</p>
                 </div>
                 <div style={{display:'grid',gridTemplateColumns:'1fr .3fr .3fr .1fr .3fr',border:'1px solid',marginLeft:'30px',width:'500px',height:'600px',marginBottom:'30px',backgroundColor:'white'}}></div>
                 </div>
               
               <div>
                 <div style={{paddingTop:'20px',position:'relative'}}>
                  <p style={{fontWeight:'bold'}}>Forestillingtype</p>
                  <input type="search" name="" id="" style={{width:'500px',paddingLeft:'40px'}} width='500px'/>
                
                <span style={{position:'absolute', top:'62px',left:'8px'}}>
                  <img src={search} alt='search' height='20px'/>
                  </span>
                <span style={{position:'absolute',right:'8px',top:'62px'}}>
                  <img src={cross} alt="" />
                </span>             
                 </div>
                <br /> <br /> <br /> <br /> <br /> <br /> 
                 <div style={{display:'grid',width:'500px',fontSize:'12px',fontWeight:'bold',gridTemplateColumns:'1fr .3fr .3fr .1fr .3fr',height:'20px',marginTop:'20px'}}>
                  <p style={{fontWeight:'bold'}}>Ikke valgte</p>
                  <p style={{fontWeight:'bold'}}>Website</p>
                  <p style={{fontWeight:'bold'}}>Bundlesalg</p>
                  <p><img src={eye} alt="" /></p>
                  <p style={{fontWeight:'bold'}}>Pris kr.</p>
                 </div>
                 <div style={{display:'grid',gridTemplateColumns:'1fr .3fr .3fr .1fr .3fr',border:'1px solid',width:'500px',height:'600px',marginBottom:'30px',backgroundColor:'white'}}></div>
                 </div>
                 </div>
              </div>
              </section>
             
              
            </div>
          </div>
        ) : null;
      }

  

export default Modal